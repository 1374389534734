import * as types from './types'

export default {
  /**
   * Задаем данные юнитам поставщика
   * @param state
   * @param dataList
   */
  [types.DISTRIBUTOR_UNITS_SET]: (state, dataList) => {
    state.distributor_units = dataList
  },

  /**
   * Флаг получения юнитов поставщика
   * @param state
   * @param value
   */
  [types.DISTRIBUTOR_UNITS_FETCHING]: (state, value) => {
    state.distributor_units_fetching = value
  },

  /**
   * Меняем данные юнита по id
   * @param state
   * @param id
   * @param data
   */
  [types.DISTRIBUTOR_UNITS_UPDATE]: (state, { id, data }) => {
    state.distributor_units = state.distributor_units.map(oldItem =>
      id === oldItem.id ? { ...oldItem, ...data } : oldItem
    )
  },

  /**
   * Флаг обновления юнитов поставщика
   * @param state
   * @param fetching
   */
  [types.DISTRIBUTOR_UNITS_UPDATING]: (state, value) => {
    state.distributor_units_updating = value
  }
}
