import api from './api'
import common from './common'

export default {
    /**
     * Поиск товаров
     * @returns {Promise<any>}
     */
    searchStockProducts(q, items_per_page, distributor) {
        return api
            .get('/stock/products/', {
                params: {
                    name_or_article_filter: q,
                    items_per_page,
                    use_category_filtering_rules_of_distributor: distributor
                }
            })
            .then(({data}) => data.results)
    },

    /**
     * Получение товара по id
     * @param id
     * @returns {Promise<any>}
     */
    getStockProductById(id) {
        return api.get(`/stock/products/${id}/`).then(({data}) => data)
    },
    /**
     * Получение товара по id
     * @param id
     * @returns {Promise<any>}
     */
    getStockProductModerationById(id) {
        return api.get(`/stock/products-moderation/${id}/`).then(({data}) => data)
    },

    /**
     * Получение товара по id 1с
     * @param id
     * @returns {Promise<any>}
     */
    getStockProductById1C(id) {
        return api.get(`/stock/products/by_1c_id/${id}/`).then(({data}) => data)
    },

    /**
     * Получение списка товаров
     * @param params
     * @returns {Promise<any>}
     */
    getStockProducts(params) {
        return api
            .get('/stock/products/', {params})
            .then(({data}) => ({count: data.count, dataList: data.results}))
    },

    /**
     * Обновление товара
     * @param id
     * @param data
     * @returns {Promise<any>}
     */
    patchStockProduct(id, data) {
        return api.patch(`/stock/products/${id}/`, data).then(({data}) => data)
    },

    /**
     * Загрузка фото
     * @param id
     * @param file
     * @returns {Promise<any>}
     */
    uploadStockProductPhoto(id, file) {
        return common
            .uploadImage(`/stock/products/${id}/`, file)
            .then(({data}) => data)
    },

    /**
     * Дубликат товара
     * @param id
     * @param target_id
     * @returns {Promise<void>}
     */
    duplicateStockProduct(id, target_id) {
        return api
            .delete(`/stock/products/${id}/is_duplicate_of/${target_id}/`)
            .then(({data}) => data)
    },

    /**
     * Экспорт товаров в 1с
     * @param ids
     * @returns {Promise<void>}
     */
    exportStockProducts(ids) {
        return api
            .post(`/export/1c_importable_mark_products`, {ids})
            .then(({data}) => data)
    },

    /**
     * Создаёт товар в номенклатуре агрегатора
     * @param data Содержимое товара
     * @return {Promise<any>}
     */
    createStockProduct(data) {
        return api
            .post('/stock/products/', data)
            .then(({data}) => data)
    },

    /**
     *
     * @param product Number
     * @param data {{unit: Number, coefficient: Number}[]}
     */
    setStockProductCoefficients(product, data) {
        return api
            .post(`/stock/products/${product}/set_coefficients/`, data)
            .then(({ data }) => data)
    },

    /**
     * Удаляет изображение товара по идентификатору
     * @param image_id
     * @return {*}
     */
    deleteStockProductImage(image_id) {
        return api.delete(`/stock/product-image/${image_id}/`)
    },

    /**
     * Добавляет изображение к указанному товару, возвращает данные изображения
     */
    addStockProductImage(product, image) {
        return fetch(image)
            .then(r => r.blob())
            .then(blob_image => {
                let fd = new FormData;
                fd.append('product', product)
                fd.append('image', blob_image, 'image.jpg')

                return api
                    .post(`/stock/product-image/`, fd)
                    .then(({ data }) => data)
            })
    },

    /**
     * Делает изображение главным у своего товара
     * @param image_id Идентификатор изображения
     * @return Promise<void>
     */
    setStockPrimaryProductImage(image_id) {
        return api.post(`/stock/product-image/${image_id}/set_primary/`)
    }
}
