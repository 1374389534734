import * as types from './types'

export default {
  /**
   * Задаем данные по товарам
   * @param state
   * @param dataList
   */
  [types.STOCK_PRODUCTS_SET]: (state, dataList) => {
    state.stock_products = dataList.reduce((accum, item) => {
      accum[item.id] = item
      return accum
    }, {})
  },

  /**
   * Добавляем данные по товарам
   * @param state
   * @param dataList
   */
  [types.STOCK_PRODUCTS_ADD]: (state, dataList) => {
    state.stock_products = {
      ...state.stock_products,
      ...dataList.reduce((accum, item) => {
        accum[item.id] = item
        return accum
      }, {})
    }
  },

  /**
   * Флаг получения данных
   * @param state
   * @param value
   */
  [types.STOCK_PRODUCTS_FETCHING]: (state, value) => {
    state.stock_products_fetching = value
  },

  /**
   * Задаем результаты поиска
   * @param state
   * @param idList
   */
  [types.STOCK_PRODUCTS_SEARCH_SET]: (state, idList) => {
    state.stock_products_search = idList
  },

  /**
   * Флаг поиска
   * @param state
   * @param value
   */
  [types.STOCK_PRODUCTS_SEARCH_FETCHING]: (state, value) => {
    state.stock_products_search_fetching = value
  },

  /**
   * Задаем кандидатов на привязку
   * @param state
   * @param dataList
   */
  [types.STOCK_PRODUCTS_SUGGEST_SET]: (state, { id, idList }) => {
    state.stock_products_suggest[id] = idList
  },

  /**
   * Флаг получения кандидатов для привязки
   * @param state
   * @param value
   */
  [types.STOCK_PRODUCTS_SUGGEST_FETCHING]: (state, value) => {
    state.stock_products_suggest_fetching = value
  },

  /**
   * Флаг создания товара
   * @param state
   * @param value
   */
  [types.STOCK_PRODUCTS_CREATING]: (state, value) => {
    state.stock_products_creating = value
  },

  /**
   * Флаг обновления товара
   * @param state
   * @param value
   */
  [types.STOCK_PRODUCTS_UPDATING]: (state, value) => {
    state.stock_products_updating = value
  }
}
