import api from '@/services/authApi'
import * as mutations from './mutations/types'

export default {
  /**
   * Получение текущего пользователя
   * @param commit
   */
  actionFetchCurrentUser: ({ commit }) => {
    commit(mutations.AUTH_CURRENT_USER_FETCHING, true)
    return api
      .getCurrentUser()
      .then(data => {
        commit(mutations.AUTH_CURRENT_USER_SET, data)
      })
      .finally(() => {
        commit(mutations.AUTH_CURRENT_USER_FETCHING, false)
        commit(mutations.AUTH_CHECKED, true)
      })
  },

  /**
   * Авторизация
   * @param commit
   * @param username
   * @param password
   */
  actionLogin: ({ commit }, { username, password }) => {
    commit(mutations.AUTH_LOGIN_IN_PROGRESS, true)
    return api
      .login(username, password)
      .then(data => data)
      .finally(() => {
        commit(mutations.AUTH_LOGIN_IN_PROGRESS, false)
      })
  },

  /**
   * Логоут
   * @param commit
   * @returns {Q.Promise<any>}
   */
  actionLogout: ({ commit }) => {
    return api.logout().then(() => {
      commit(mutations.AUTH_CURRENT_USER_SET, null)
    })
  },

  /**
   * Восстановление пароля
   * @param commit
   * @param who
   * @returns {Q.Promise<any>}
   */
  actionPasswordRecovery: ({ commit }, who) => {
    commit(mutations.AUTH_PASSWORD_RECOVERY_IN_PROGRESS, true)
    return api
      .passwordRecovery(who)
      .then(data => data)
      .finally(() => {
        commit(mutations.AUTH_PASSWORD_RECOVERY_IN_PROGRESS, false)
      })
  },

  /**
   * Список пользователей системы
   * @param commit
   * @returns {Q.Promise<any>}
   */
  actionFetchUsers: ({ commit }) => {
    commit(mutations.AUTH_USERS_FETCHING, true)
    return api
      .getUsers()
      .then(dataList => {
        commit(mutations.AUTH_USERS_SET, dataList)
        return dataList
      })
      .finally(() => {
        commit(mutations.AUTH_USERS_FETCHING, false)
      })
  }
}
