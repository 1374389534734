import api from './api'
import common from './common'

export default {
  /**
   * Получение списка категорий
   * @returns {Promise<any>}
   */
  getStockCategories() {
    return api.get(`/stock/structure/`).then(({ data }) => data)
  },

  /**
   * Получение массива идентифиваторов категорий, в которых находятся товары, импортированные
   * из внешних сайтов
   */
  getStockCategoriesIdsWithImportedGoods() {
    return api.get('/stock/structure/with_imported_goods/').then(({ data }) => data)
  },

  /**
   * Получение категории по id
   * @param id
   * @returns {*}
   */
  getStockCategoryById(id) {
    return api.get(`/stock/structure/${id}/`).then(({ data }) => data)
  },

  /**
   * Создание категории
   * @param name
   * @param parent
   * @returns {Promise<T>}
   */
  addStockCategory(name, parent) {
    return api
      .post(`/stock/structure/`, { name, parent, enabled: true })
      .then(({ data }) => data)
  },

  /**
   * Обновление категории
   * @param id
   * @param data
   * @returns {Promise<T>}
   */
  patchStockCategory(id, data) {
    return api.patch(`/stock/structure/${id}/`, data).then(({ data }) => data)
  },

  /**
   * Загрузка фото
   * @param id
   * @param file
   * @returns {Promise<any>}
   */
  uploadStockCategoryPhoto(id, file) {
    return common
      .uploadImage(`/stock/structure/${id}/`, file)
      .then(({ data }) => data)
  },

  /**
   * Объединение категории (id) с другой категорией (target_id)
   * @param id
   * @param target_id
   * @returns {Promise<any>}
   */
  duplicateStockCategory(id, target_id) {
    return api
      .delete(`/stock/structure/${id}/is_duplicate_of/${target_id}/`)
      .then(({ data }) => data)
  },

  /**
   * Получить список выбранных элементов для поставщика
   * @param distributor_id
   * @returns {Promise<Array>}
   */
  getSelectedStockCategories(distributor_id) {
    return api
      .get(`/parser/distributors/${distributor_id}/filtering_structures/`)
      .then(({ data }) => data)
  },

  /**
   * Обновление списка выбранных элементов для постащика
   * @param distributor_id
   * @param toggle_on
   * @param toggle_off
   * @returns {Promise<Array>}
   */
  updateSelectedStockCategories(distributor_id, toggle_on, toggle_off) {
    return api
        .post(
            `/parser/distributors/${distributor_id}/mass_toggle_filtering_categories/structures/`, {
              toggle_on,
              toggle_off
            }
        ).then(({data}) => data)
  }
}
