import api from './api'

export default {
  /**
   * Получение одного бренда по идентификатору
   */
  getStockBrand(id) {
    return api.get(`/stock/brands/${id}/`).then(({ data }) => data)
  },

  /**
   * Получение всех stock брендов
   * @returns {Promise<any>}
   */
  getStockBrands() {
    return api.get(`/stock/brands/`).then(({ data }) => data)
  },

  /**
   * Получение категорий брендов
   * @returns {Promise<any>}
   */
  getStockBrandsCategories() {
    return api.get(`/stock/brands-categories/`).then(({ data }) => data)
  },

  /**
   * Обновляет указанный бренд
   * @returns {*}
   */
  patchStockBrand(id, data) {
    let fd = new FormData;

    for (let k in data) {
      if (k === 'image' && data[k] === null) {
        data[k] = ''
      }

      fd.append(k, data[k])
    }

    return api.patch(`/stock/brands/${id}/`, fd).then(({ data }) => data)
  },

  /**
   * Создаёт бренд
   * @param name
   * @param category
   * @param priority
   * @param image
   * @param english_name
   */
  createStockBrand(name, english_name, category, priority, image) {
    let fd = new FormData;

    fd.append('name', name)
    fd.append('english_name', english_name)
    fd.append('category', category)
    fd.append('priority', priority)

    if (image) {
      fd.append('image', image)
    }

    return api.post('/stock/brands/', fd).then(({ data }) => data)
  }
}
