import api from './api'

export default {
  /**
   * Получение списка товаров
   * @param params
   * @returns {Promise<any>}
   */
  getDistributorProducts(params) {
    return api.get(`/parser/products/`, { params }).then(({ data }) => data)
  },

  /**
   * Привязка товара
   * @param id
   * @param bind
   * @returns {Promise<any>}
   */
  bindDistributorProduct(id, bind) {
    return api
      .patch(
        `/parser/products/${id}/`,
        { bind },
        {},
        { successMessage: 'Привязка товара успешно изменена' }
      )
      .then(({ data }) => data)
  },

  /**
   * Вкл/Выкл
   * @param id
   * @param enabled
   * @returns {Promise<any>}
   */
  switchDistributorProduct(id, enabled) {
    return api
      .patch(`/parser/products/${id}/`, { enabled })
      .then(({ data }) => data)
  },

  /**
   * Получение списка кандидатов для привязки
   * @param id
   * @returns {Promise<any>}
   */
  getStockProductsSuggest(id) {
    return api
      .get(`/parser/products/${id}/suggest/`)
      .then(response => (response && response.data) || [])
  },

  /**
   * Запрос создания stock товара по id
   * @param id
   * @returns {Promise<any>}
   */
  createStockProduct(id) {
    return api
      .get(`/parser/products/${id}/create_in_stock/`)
      .then(({ data }) => data)
  },

  /**
   * Запрос статистики по количеству товаров
   * @param id
   * @param enabled
   * @returns {Promise<any>}
   */
  getDistributorProductsStats(id, enabled) {
    return api
      .get(`/parser/distributors/${id}/count_totals_products/`, {
        params: { filter_enabled: enabled }
      })
      .then(({ data }) => data)
  },

  /**
   * Запрос автоматической привязки товаров
   * @param id
   * @returns {Promise<any>}
   */
  autobindDistributorProducts(id) {
    return api
      .get(`/parser/distributors/${id}/autobind/`, {
        params: { do_bind: true },
        timeout: 600000
      })
      .then(({ data }) => data)
  }
}
