/**
 * Дерево брендов
 * @param brands
 * @param brandsCategories
 * @returns {*}
 */
export const buildTree = (brands, brandsCategories) => {
  const categories = brandsCategories.map(item => ({
    ...item,
    children: []
  }))
  const categoriesObj = categories.reduce((accumulator, item) => {
    accumulator[item.id] = item
    return accumulator
  }, {})
  brands.forEach(item => {
    if (categoriesObj[item.category]) {
      categoriesObj[item.category].children.push(item)
    }
  })
  return categories.filter(item => item.children.length > 0)
}

export default {
  /**
   * Дерево брендов
   * @param stock_brands
   * @param stock_brands_categories
   * @returns {*}
   */
  getStockBrandsTree({ stock_brands, stock_brands_categories }) {
    return buildTree(stock_brands, stock_brands_categories)
  },

  /**
   * Флаг получения брендов
   * @param stock_brands_fetching
   * @returns {*}
   */
  getStockBrandsFetching({ stock_brands_fetching }) {
    return stock_brands_fetching
  },

  /**
   * Получение бренда по id
   * @param stock_brands
   * @returns {function(*): *}
   */
  getStockBrandById({ stock_brands }) {
    return id => id ? stock_brands.find(item => id === item.id && item.is_brand) : null
  },

  /**
   * Получение категории бренда по идентификатору
   * @param stock_brands_categories
   * @returns {function(*): *}
   */
  getStockBrandCategoryById({ stock_brands_categories }) {
    return id => id ? stock_brands_categories.find(item => id === item.id) : null
  },

  /**
   * Получение списка брендов по списку id
   * @param stock_brands
   * @returns {function(*): Array}
   */
  getStockBrandsByIdList({ stock_brands }) {
    return idList => idList.length ? stock_brands.filter(item => idList.includes(item.id)) : []
  },

  /**
   * Дерево брендов по списку id
   * @param stock_brands_categories
   * @param getters
   * @returns {function(*=): *}
   */
  getStockBrandsTreeByIdList({ stock_brands_categories }, getters) {
    return idList => buildTree(getters.getStockBrandsByIdList(idList), stock_brands_categories)
  }
}
