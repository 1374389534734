import api from '@/services/distributorBrandsApi'
import * as mutations from './mutations/types'

export default {
  /**
   * Очистить данные по брендам поставщика
   * @param commit
   */
  actionClearDistributorBrands: ({ commit }) => {
    commit(mutations.DISTRIBUTOR_BRANDS_SET, [])
  },

  /**
   * Запрос всех брендов поставщика
   * @param commit
   * @param distributorId
   */
  actionFetchDistributorBrands: ({ commit }, distributorId) => {
    commit(mutations.DISTRIBUTOR_BRANDS_FETCHING, true)
    api
      .getDistributorBrands(distributorId)
      .then(dataList => {
        commit(mutations.DISTRIBUTOR_BRANDS_SET, [...dataList])
      })
      .finally(() => {
        commit(mutations.DISTRIBUTOR_BRANDS_FETCHING, false)
      })
  },

  /**
   * Вкл/Выкл бренда поставщика
   * @param commit
   * @param dispatch
   * @param brandId
   * @param enabled
   */
  actionSwitchDistributorBrand: ({ commit, dispatch }, { brandId, enabled }) => {
    commit(mutations.DISTRIBUTOR_BRANDS_UPDATING, true)
    commit(mutations.DISTRIBUTOR_BRANDS_UPDATE, {
      id: brandId,
      data: { loading: true }
    })
    api
      .switchDistributorBrand(brandId, enabled)
      .then(({ enabled }) => {
        commit(mutations.DISTRIBUTOR_BRANDS_UPDATE, {
          id: brandId,
          data: { enabled, loading: false }
        })
      })
      .finally(() => {
        commit(mutations.DISTRIBUTOR_BRANDS_UPDATING, false)
        dispatch('app_aggregator/actionRefreshDistributorUnbindedCounters', null, {root: true})
      })
  },

  /**
   * Привязка бренда
   * @param commit
   * @param brandId
   * @param bindId
   */
  actionBindDistributorBrand: ({ commit, dispatch }, { brandId, bindId }) => {
    commit(mutations.DISTRIBUTOR_BRANDS_UPDATING, true)
    commit(mutations.DISTRIBUTOR_BRANDS_UPDATE, {
      id: brandId,
      data: { loading: true }
    })
    api
      .bindDistributorBrand(brandId, bindId)
      .then(({ bind, enabled }) => {
        commit(mutations.DISTRIBUTOR_BRANDS_UPDATE, {
          id: brandId,
          data: { bind, enabled, loading: false }
        })
      })
      .finally(() => {
        commit(mutations.DISTRIBUTOR_BRANDS_UPDATING, false)
        dispatch('app_aggregator/actionRefreshDistributorUnbindedCounters', null, {root: true})
      })
  },

  /**
   * Получение кандидатов на привязку
   * @param commit
   * @param brandId
   * @returns {*}
   */
  actionFetchBrandBindings: ({ commit }, brandId) => {
    commit(mutations.DISTRIBUTOR_BRANDS_BINDINGS_FETCHING, true)
    return api
      .getBrandBindings(brandId)
      .then(dataList => {
        commit(mutations.DISTRIBUTOR_BRANDS_UPDATE, {
          id: brandId,
          data: { binding_items: dataList }
        })
      })
      .finally(() => {
        commit(mutations.DISTRIBUTOR_BRANDS_BINDINGS_FETCHING, false)
      })
  }
}
