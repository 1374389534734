<template>
  <div>
    <div v-if="loading" class="navigation-skeleton text-center">
      <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
      <v-divider></v-divider>
      <v-skeleton-loader
        type="list-item, list-item, list-item, list-item"
        class="mt-3"
      ></v-skeleton-loader>
    </div>
    <div v-else>
      <v-list dense>
        <v-list-item two-line>
          <v-list-item-avatar class="mr-2">
            <v-avatar size="36" color="indigo">
              <span class="avatar-initials white--text">
                {{ currentUserInitials }}
              </span>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="current-user-fullname">
              {{ currentUserFullname }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action v-if="currentUser">
            <v-btn @click.prevent="logout" icon>
              <v-icon color="grey lighten-1">mdi-logout</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list class="navigation-menu" dense>
        <v-list-item
          v-for="item in getMainMenuItems"
          :to="{ name: item.link }"
          :key="item.key"
          class="menu-item"
          exact
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import permissions from '@/common/permissions'
import { routes } from '@/router'

export default {
  props: {
    loading: Boolean
  },
  computed: {
    ...mapGetters('auth', [
      'currentUser',
      'getCurrentUserHasPermission',
    ]),
    ...mapGetters([
      'getMainMenuItems',
    ]),
    currentUserFullname: function() {
      if (this.currentUser) {
        const { name, surname } = this.currentUser
        let displayName = `${surname} ${name}`
        return displayName.trim() ? displayName : 'Имя не указано'
      }
      return 'Неавторизованный пользователь'
    },
    currentUserInitials: function() {
      if (this.currentUser) {
        const { name, surname } = this.currentUser
        let initials = `${surname.substring(0, 1)}${name.substring(0, 1)}`
        return initials || 'NA'
      }
      return 'NA'
    }
  },
  methods: {
    ...mapActions('auth', ['actionLogout', 'actionFetchCurrentUser']),
    logout: function() {
      this.actionLogout().then(() => {
        this.$router.push({ name: routes.dashboard }).catch(() => {})
      })
    }
  }
}
</script>
