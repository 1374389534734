export default {
  appAggregator: 'app.aggregator',
  appAggregatorAdmin: 'app.aggregator.admin',
  appCatalog: 'app.catalog',
  appCatalogAdmin: 'app.catalog.admin',

  appImages: 'app.images',
  appImagesAdmin: 'app.images.admin',
  appImagesModerate: 'api.images.moderate',
  apiImagesUpload: 'api.images.upload',

  api1cDirections: 'api.1c.directions',
  api1cDistributors: 'api.1c.distributors',

  apiAuthGetUsers: 'api.auth.get_users',

  apiParserBrands: 'api.parser.brands',
  apiParserBrandsUpdate: 'api.parser.brands.update',
  apiParserCategories: 'api.parser.categories',
  apiParserCategoriesUpdate: 'api.parser.categories.update',
  apiParserDistributors: 'api.parser.distributors',
  apiParserDistributorsUpdate: 'api.parser.distributors.update',
  apiParserFeatures: 'api.parser.features',
  apiParserFeaturesUpdate: 'api.parser.features.update',
  apiParserModules: 'api.parser.modules',
  apiParserProducts: 'api.parser.products',
  apiParserProductsUpdate: 'api.parser.products.update',

  apiStockBrandsCategories: 'api.stock.brands-categories',
  apiStockBrands: 'api.stock.brands',
  apiStockBrandsUpdate: 'api.stock.brands.update',
  apiStockCategories: 'api.stock.categories',
  apiStockCategoriesUpdate: 'api.stock.categories.update',
  apiStockFeatures: 'api.stock.features',
  apiStockFeaturesUpdate: 'api.stock.features.update',
  apiStockProducts: 'api.stock.products',
  apiStockProductsUpdate: 'api.stock.products.update',
  apiStockStructure: 'api.stock.structure',
  apiStockStructureUpdate: 'api.stock.structure.update',
  apiModerationPayment: 'api.moderation.payment',
}
