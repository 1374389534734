export const DISTRIBUTOR_LIST_SEARCH_SET = 'DISTRIBUTOR_LIST_SEARCH_SET'
export const DISTRIBUTOR_LIST_SEARCH_FETCHING =
  'DISTRIBUTOR_LIST_SEARCH_FETCHING'

export const DISTRIBUTOR_LIST_SET = 'DISTRIBUTOR_LIST_SET'
export const DISTRIBUTOR_LIST_ADD = 'DISTRIBUTOR_LIST_ADD'
export const DISTRIBUTOR_LIST_UPDATE = 'DISTRIBUTOR_LIST_UPDATE'
export const DISTRIBUTOR_LIST_FETCHING = 'DISTRIBUTOR_LIST_FETCHING'
export const DISTRIBUTOR_ADDING = 'DISTRIBUTOR_ADDING'
export const DISTRIBUTOR_BRANDS = 'DISTRIBUTOR_BRANDS'

export const PARSER_LIST_SET = 'PARSER_LIST_SET'
export const PARSER_LIST_FETCHING = 'PARSER_LIST_FETCHING'
