import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  // Список товаров
  distributor_products: [],
  distributor_products_count: 0,
  // Количество привязанных
  distributor_products_stats_binded: 0,
  // Количество непривязанных
  distributor_products_stats_unbinded: 0,
  // Количество выключенных
  distributor_products_stats_disabled: 0,
  // Количество всех товаров
  distributor_products_stats_total: 0,
  // Флаг запроса получения товаров
  distributor_products_fetching: false,
  // Флаг запроса обновления
  distributor_products_updating: false
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
