import api from '@/services/distributorUnitsApi'
import * as mutations from './mutations/types'

export default {
  /**
   * Очистить список юнитов поставщика
   * @param commit
   */
  actionClearDistributorUnits: ({ commit }) => {
    commit(mutations.DISTRIBUTOR_UNITS_SET, [])
  },

  /**
   * Запрос юнитов поставщика
   * @param commit
   * @param distributorId
   */
  actionFetchDistributorUnits: ({ commit }, distributorId) => {
    commit(mutations.DISTRIBUTOR_UNITS_FETCHING, true)
    api
      .getUnits(distributorId)
      .then(dataList => {
        commit(mutations.DISTRIBUTOR_UNITS_SET, [...dataList])
      })
      .finally(() => {
        commit(mutations.DISTRIBUTOR_UNITS_FETCHING, false)
      })
  },

  /**
   * Привязка юнита
   * @param commit
   * @param id
   * @param bindId
   */
  actionBindDistributorUnit: ({ commit, dispatch }, { id, bindId }) => {
    commit(mutations.DISTRIBUTOR_UNITS_UPDATING, true)
    commit(mutations.DISTRIBUTOR_UNITS_UPDATE, {
      id,
      data: { loading: true }
    })
    api
      .bindUnit(id, bindId)
      .then(({ bind, enabled }) => {
        commit(mutations.DISTRIBUTOR_UNITS_UPDATE, {
          id,
          data: { bind, enabled, loading: false }
        })
      })
      .finally(() => {
        commit(mutations.DISTRIBUTOR_UNITS_UPDATING, false)
        dispatch('app_aggregator/actionRefreshDistributorUnbindedCounters', null, {root: true})
      })
  }
}
