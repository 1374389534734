import api from '@/services/distributorFeaturesApi'
import * as mutations from './mutations/types'

export default {
  /**
   * Очистить данные параметров поставщика
   * @param commit
   */
  actionClearDistributorFeatures: ({ commit }) => {
    commit(mutations.DISTRIBUTOR_FEATURES_SET, [])
  },

  /**
   * Обновляет с сервера одно свойство по идентификатору
   */
  actionUpdateFeatureById({commit}, id) {
    api
      .getFeatureById(id)
      .then(data => {
        commit(mutations.DISTRIBUTOR_FEATURE_UPDATE, data)
      })
  },

  /**
   * Запрос всех параметров поставщика
   * @param commit
   * @param distributorId
   */
  actionFetchDistributorFeatures: ({ commit }, distributorId) => {
    commit(mutations.DISTRIBUTOR_FEATURES_FETCHING, true)
    api
      .getDistributorFeatures(distributorId)
      .then(dataList => {
        commit(mutations.DISTRIBUTOR_FEATURES_SET, [...dataList])
      })
      .finally(() => {
        commit(mutations.DISTRIBUTOR_FEATURES_FETCHING, false)
      })
  },

  /**
   * Вкл/Выкл параметра
   * @param commit
   * @param id
   * @param enabled
   */
  actionSwitchDistributorFeature: ({ commit, dispatch }, { id, enabled }) => {
    commit(mutations.DISTRIBUTOR_FEATURES_UPDATING, true)
    commit(mutations.DISTRIBUTOR_FEATURES_UPDATE, {
      id,
      data: { loading: true }
    })
    api
      .switchDistributorFeature(id, enabled)
      .then(({ enabled }) => {
        commit(mutations.DISTRIBUTOR_FEATURES_UPDATE, {
          id,
          data: { enabled, loading: false }
        })
      })
      .finally(() => {
        commit(mutations.DISTRIBUTOR_FEATURES_UPDATING, false)
        dispatch('app_aggregator/actionRefreshDistributorUnbindedCounters', null, {root: true})
      })
  },

  /**
   * Привязка параметра
   * @param commit
   * @param id
   * @param bindId
   */
  actionBindDistributorFeature: ({ commit, dispatch }, { id, bindId }) => {
    commit(mutations.DISTRIBUTOR_FEATURES_UPDATING, true)
    commit(mutations.DISTRIBUTOR_FEATURES_UPDATE, {
      id: id,
      data: { loading: true }
    })
    api
      .bindDistributorFeature(id, bindId)
      .then(({ bind }) => {
        commit(mutations.DISTRIBUTOR_FEATURES_UPDATE, {
          id: id,
          data: { bind, loading: false }
        })
      })
      .finally(() => {
        commit(mutations.DISTRIBUTOR_FEATURES_UPDATING, false)
        dispatch('app_aggregator/actionRefreshDistributorUnbindedCounters', null, {root: true})
      })
  },

  /**
   * Получение кандидатов на привязку
   * @param commit
   * @param featureId
   * @returns {Promise<T>}
   */
  actionFetchFeatureBindings: ({ commit }, featureId) => {
    commit(mutations.DISTRIBUTOR_FEATURES_BINDINGS_FETCHING, true)
    return api
      .getDistributorFeaturesSuggest(featureId)
      .then(dataList => {
        commit(mutations.DISTRIBUTOR_FEATURES_UPDATE, {
          id: featureId,
          data: { binding_items: dataList }
        })
      })
      .finally(() => {
        commit(mutations.DISTRIBUTOR_FEATURES_BINDINGS_FETCHING, false)
      })
  },
}
