import * as types from './types'

export default {
  /**
   * Задаем данные по веткам ассортимента
   * @param state
   * @param dataList
   */
  [types.STOCK_TREE_SET]: (state, dataList) => {
    state.stock_tree = dataList
  },

  /**
   * Флаг получения веток ассортимента
   * @param state
   * @param value
   */
  [types.STOCK_TREE_FETCHING]: (state, value) => {
    state.stock_tree_fetching = value
  },

  /**
   * Добавление веток ассортимента
   * @param state
   * @param dataItem
   */
  [types.STOCK_TREE_ADD]: (state, dataItem) => {
    state.stock_tree = [...state.stock_tree, dataItem]
  },

  /**
   * Флаг добавления веток ассортимента
   * @param state
   * @param value
   */
  [types.STOCK_TREE_ADDING]: (state, value) => {
    state.stock_tree_adding = value
  },

  /**
   * Обновление элемента
   * @param state
   * @param id
   * @param data
   */
  [types.STOCK_TREE_UPDATE]: (state, { id, data }) => {
    state.stock_tree = state.stock_tree.map(item =>
      item.id === id ? { ...item, ...data } : item
    )
  },

  /**
   * Флаг обновления
   * @param state
   * @param value
   */
  [types.STOCK_TREE_UPDATING]: (state, value) => {
    state.stock_tree_updating = value
  },

  /**
   * Задаем выбранные элементы
   * @param state
   * @param dataList
   */
  [types.STOCK_TREE_SELECTED_SET]: (state, data) => {
    state.stock_tree_selected = {
      ...state.stock_tree_selected,
      ...data
    }
  },

  /**
   * Флаг запроса выбранных элементов
   * @param state
   * @param value
   */
  [types.STOCK_TREE_SELECTED_FETCHING]: (state, value) => {
    state.stock_tree_selected_fetching = value
  },

  /**
   * Флаг обновления выбранных элементов
   * @param state
   * @param value
   */
  [types.STOCK_TREE_SELECTED_UPDATING]: (state, value) => {
    state.stock_tree_selected_updating = value
  }
}
