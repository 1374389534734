export default {
  /**
   * Получение всех брендов контрагента
   * @param distributor_categories
   * @returns {*}
   */
  getDistributorBrands({ distributor_brands }) {
    return distributor_brands
  },

  /**
   * Флаг получения брендов поставщика
   * @param distributor_categories_fetching
   * @returns boolean
   */
  getDistributorBrandsFetching({ distributor_brands_fetching }) {
    return distributor_brands_fetching
  },

  /**
   * Флаг обновления брендов
   * @param distributor_brands_updating
   * @returns {*}
   */
  getDistributorBrandsUpdating({ distributor_brands_updating }) {
    return distributor_brands_updating
  },

  /**
   * Флаг получения кандидатов на привязку
   * @param distributor_brands_bindings_fetching
   * @returns {*}
   */
  getDistributorBrandsBindingsFetching({
    distributor_brands_bindings_fetching
  }) {
    return distributor_brands_bindings_fetching
  },

  /**
   * Получение бренда контрагента по id
   * @param distributor_brands
   * @returns {function(*): *}
   */
  getDistributorBrandById({ distributor_brands }) {
    return id => distributor_brands.find(item => item.id == id)
  }
}
