import api from './api'

export default {
  /**
   * Список текущих фоновых задач в агрегаторе
   * @returns {*}
   */
  getBackgroundTasks(last_state = null) {
    return api
      .get('/core/task/', null, {muteErrorHandler: true})
      .then(({data}) => data)
  },

  /**
   * Удаляет указанную задачу
   *
   * @param id Идентификатор фоновой задачи
   * @returns {*}
   */
  removeBackgroundTask(id) {
    return api
      .post(`/core/task/${id}/remove/`)
      .then(({data}) => data)
  }
}
