import api from './api'

export default {
  /**
   * Получение текущего пользователя
   * @returns {Promise<any>}
   */
  getCurrentUser() {
    return api.get(`/auth/current_user`).then(({ data }) => data)
  },

  /**
   * Авторизация
   * @param username
   * @param password
   * @returns {Promise<AxiosResponse<T> | never>}
   */
  login(username, password) {
    return api
      .post(`/auth/login`, {
        username,
        password
      })
      .then(({ data }) => data)
  },

  /**
   * Логоут
   * @returns {Promise<any>}
   */
  logout() {
    return api.post(`/auth/logout/`).then(({ data }) => data)
  },

  /**
   * Востсановление пароля
   * @param who
   * @returns {Q.Promise<any>}
   */
  passwordRecovery(who) {
    return api.post(`/auth/reset_password/`, { who }).then(({ data }) => data)
  },

  /**
   * Список пользователей системы
   * @returns {*}
   */
  getUsers() {
    return api.get(`/auth/get_users`).then(({ data }) => data)
  }
}
