import { buildTree } from '../../common'

export default {
  /**
   * Получение всех параметров
   * @param stock_features
   * @returns {*}
   */
  getStockFeatures({ stock_features }) {
    return buildTree(stock_features, ['name'])
  },

  /**
   * Получение корневых элементов
   * @param state
   * @param getters
   * @returns {*}
   */
  getStockRootFeatures(state, getters) {
    return getters.getStockFeatures.filter(item => !item.parent)
  },

  /**
   * Флаг получения параметров
   * @param stock_features_fetching
   * @returns {*}
   */
  getStockFeaturesFetching({ stock_features_fetching }) {
    return stock_features_fetching
  },

  /**
   * Флаг создания элемента
   * @param stock_features_adding
   * @returns {*}
   */
  getStockFeaturesAdding({ stock_features_adding }) {
    return stock_features_adding
  },

  /**
   * Получение параметра по id
   * @param stock_features
   * @returns {function(*): null}
   */
  getStockFeatureById({ stock_features }) {
    return id => (id ? stock_features.find(item => id === item.id) : null)
  },

  /**
   * Получение списка предков
   * @returns {function(*): Array}
   */
  getStockFeatureAncestorsById(state, getters) {
    return id => {
      let nextAncestorId = id
      const ancestorsList = []
      while (nextAncestorId) {
        let item = getters.getStockFeatureById(nextAncestorId)
        ancestorsList.push(item)
        nextAncestorId = item.parent
      }
      return ancestorsList
    }
  },

  /**
   * Получение списка параметров по списку id
   * @param stock_features
   * @returns {function(*): Array}
   */
  getStockFeaturesByIdList({ stock_features }) {
    return idList =>
      idList.length
        ? stock_features.filter(item => idList.includes(item.id))
        : []
  }
}
