import * as types from './types'

export default {
  /**
   * Обновляет одно свойство в списке
   * @param state
   * @param feature
   */
  [types.DISTRIBUTOR_FEATURE_UPDATE]: (state, feature) => {
    state.distributor_features = state.distributor_features.map(item => item.id === feature.id ? feature : item)
  },

  /**
   * Задаем список параметров поставщика
   * @param state
   * @param dataList
   */
  [types.DISTRIBUTOR_FEATURES_SET]: (state, dataList) => {
    state.distributor_features = dataList
  },

  /**
   * Флаг получения параметров поставщика
   * @param state
   * @param value
   */
  [types.DISTRIBUTOR_FEATURES_FETCHING]: (state, value) => {
    state.distributor_features_fetching = value
  },

  /**
   * Обновляем параметр
   * @param state
   * @param id
   * @param data
   */
  [types.DISTRIBUTOR_FEATURES_UPDATE]: (state, { id, data }) => {
    state.distributor_features = state.distributor_features.map(oldItem =>
      id === oldItem.id ? { ...oldItem, ...data } : oldItem
    )
  },

  /**
   * Флаг обновления параметров поставщика
   * @param state
   * @param value
   */
  [types.DISTRIBUTOR_FEATURES_UPDATING]: (state, value) => {
    state.distributor_features_updating = value
  },

  /**
   * Флаг получения кандидатов для привязки
   * @param state
   * @param value
   */
  [types.DISTRIBUTOR_FEATURES_BINDINGS_FETCHING]: (state, value) => {
    state.distributor_features_bindings_fetching = value
  }
}
