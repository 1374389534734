import api from './api'

export default {
  /**
   * Список брендов поставщика
   * @param distributor
   * @returns {Promise<any>}
   */
  getDistributorBrands(distributor) {
    return api
      .get(`/parser/brands/`, { params: { distributor } })
      .then(({ data }) =>
        data.map(item => {
          return { ...item, loading: false }
        })
      )
  },

  /**
   * Вкл/Выкл бренда поставщика
   * @param id
   * @param enabled
   * @returns {Promise<any>}
   */
  switchDistributorBrand(id, enabled) {
    return api
      .patch(`/parser/brands/${id}/`, { enabled })
      .then(({ data }) => data)
  },

  /**
   * Привязка брнеда
   * @param id
   * @param bind
   * @returns {Promise<any>}
   */
  bindDistributorBrand(id, bind) {
    return api
      .patch(
        `/parser/brands/${id}/`,
        { bind, enabled: true },
        {},
        { successMessage: 'Настройки бренда успешно сохранены' }
      )
      .then(({ data }) => data)
  },

  /**
   * Получение списка кандидатов для привязки
   * @param id
   * @returns {Promise<any>}
   */
  getBrandBindings(id) {
    return api
      .get(`/parser/brands/${id}/suggest/`)
      .then(response => response ? response.data.map(item => item.id) : [])
  },

  /**
   * Автоматическая привязка брендов
   * @param distributor_id
   */
  autobindDistributorBrands(distributor_id) {
    return api
      .post(`/parser/distributors/${distributor_id}/autobind_brands/`)
      .then(({data}) => data)
  }
}
