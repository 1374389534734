import _ from 'lodash'

export default {
  /**
   * Получение всех юнитов контрагента
   * @param distributor_units
   * @returns {*}
   */
  getDistributorUnits({ distributor_units }) {
    return _.sortBy(distributor_units, ['name'])
  },

  /**
   * Флаг получения юнитов поставщика
   * @param distributor_units_fetching
   * @returns {*}
   */
  getDistributorUnitsFetching({ distributor_units_fetching }) {
    return distributor_units_fetching
  },

  /**
   * Флаг обновления юнитов
   * @param distributor_units_updating
   * @returns {*}
   */
  getDistributorUnitsUpdating({ distributor_units_updating }) {
    return distributor_units_updating
  },

  /**
   * Получение юнита контрагента по id
   * @param distributor_units
   * @returns {function(*): *}
   */
  getDistributorUnitById({ distributor_units }) {
    return id => distributor_units.find(item => item.id == id)
  }
}
