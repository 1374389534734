export default {
  /**
   * Получение выбранного типа каталога
   * @param active_catalog_type
   * @returns {*}
   */
  activeCatalogType({ active_catalog_type }) {
    return active_catalog_type
  },

  /**
   * Метка расширения левой панели
   * @param left_panel_expanded
   * @returns {*}
   */
  getLeftPanelExpanded({ left_panel_expanded }) {
    return left_panel_expanded
  },

  /**
   * Фильтр товаров - выборка только импортированных из внешних источников
   * @param filter_imported_goods
   * @return boolean
   */
  getFilterImportedGoods({ filter_imported_goods}) {
    return filter_imported_goods
  }
}
