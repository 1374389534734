import api from './api'
import common from './common'

export default {
  /**
   * Загрузка изображения
   * @param type
   * @param id
   * @param file
   * @returns {Promise<T | never>}
   */
  uploadImage(type, id, file) {
    return common
      .uploadImage(`/moderation/upload_for_${type}/${id}/`, file)
      .then(({data}) => data)
  },

  /**
   * Одобрение изображения
   * @param type
   * @param id
   * @returns {Promise<any>}
   */
  acceptImage(type, id) {
    return api
      .post(`/moderation/accept_${type}_preview/`, {id})
      .then(({data}) => data)
  },

  /**
   * Отклонение изображения
   * @param type
   * @param id
   * @param message
   * @returns {Promise<any>}
   */
  rejectImage(type, id, message) {
    return api
      .post(`/moderation/reject_${type}_preview/`, {
        id,
        reject_reason: message
      })
      .then(({data}) => data)
  },

  /**
   * Одобрение плохого изображения
   * @param type
   * @param id
   * @returns {Promise<T | never>}
   */
  approvalBadImage(type, id) {
    return api
      .patch(`/moderation/mute_quality_check/${type}/${id}`)
      .then(({data}) => data)
  },

  /**
   * Список элементов на модерации
   * @param type
   * @param options
   * @returns {*|Promise<any>}
   */
  getModerate(
    type,
    {
      filterUnverified,
      filterRejected,
      filterAccepted,
      filterByUploader,
      filterByTarget,
      page,
      itemsPerPage
    }
  ) {
    const defaultOptions = {
      page: 1,
      items_per_page: 10
    }
    return api
      .get(`/moderation/list_candidates/${type}/`, {
        params: {
          ...defaultOptions,
          filter_unverified: filterUnverified,
          filter_rejected: filterRejected,
          filter_accepted: filterAccepted,
          filter_by_uploader: filterByUploader,
          filter_by_target: filterByTarget,
          items_per_page: itemsPerPage,
          page
        }
      })
      .then(({data}) => data)
  },

  /**
   * Список элементов без изображений
   * @param type
   * @param page
   * @param itemsPerPage
   * @param brands
   * @returns {Promise<any>}
   */
  getEmpty(type, {page, itemsPerPage, ...filters}) {
    const defaultOptions = {
      page: 1,
      items_per_page: 10
    }
    return api
      .post(`/moderation/list_empty_entities/${type}/`,
        {
          ...defaultOptions,
          ...filters
        },
        {
          params: {
            items_per_page: itemsPerPage,
            page
          }
        }
      )
      .then(({data}) => data)
  },

  /**
   * Список некачественных изображений
   * @param type
   * @param page
   * @param itemsPerPage
   * @returns {Promise<any>}
   */
  getBad(type, {page, itemsPerPage}) {
    const defaultOptions = {
      page: 1,
      items_per_page: 10
    }
    return api
      .get(`/moderation/with_bad_images/${type}/`, {
        params: {
          ...defaultOptions,
          items_per_page: itemsPerPage,
          page
        }
      })
      .then(({data}) => data)
  },

  /**
   * Удаление фото элемента каталога
   * @param type
   * @param id
   * @returns {Promise<any>}
   */
  deleteImage(type, id) {
    return api
      .delete(`/moderation/clear_photos/${type}/${id}/`)
      .then(({data}) => data)
  },

  /**
   * Статистика загрузок
   * @returns {Promise<any>}
   */
  getUploadStats() {
    return api.get('/moderation/statistics/').then(({data}) => data)
  },

  /**
   * Статистика проблемных изображений
   * @param fromDate
   * @param toDate
   * @returns {Promise<AxiosResponse<T> | never>}
   */
  getProblemsStats(fromDate, toDate) {
    return api
      .get('/moderation/statistics/daily/', {
        params: {date__gte: fromDate, date__lte: toDate}
      })
      .then(({data}) => data)
  },
  /**
   * Сохранение оплаты загруженных картинок
   * @param userForId
   * @param moderatedById
   * @param countModerated
   * @returns {Promise<AxiosResponse<T> | never>}
   */
  savePaymentStatistic(userForId, moderatedById, countModerated) {
    // Get ISO string and split T, then get only date chart
    const now = new Date().toISOString().split('T')[0];
    return api
      .post('/moderation/payment/', {
        payment_for: userForId,
        moderated_by: moderatedById,
        moderated_images_count: countModerated,
        created: now
      })
      .then(({data}) => data)
  },
  /**
   * Получение статистики выплаты пользователю
   * @param userId
   * @returns {Promise<AxiosResponse<T> | never>}
   */
  fetchPaymentStatistic(userId) {
    return api
      .get(`/moderation/payment/${userId}/`)
      .then(({data}) => data)
  },
  /**
   * Получение отчета
   * @returns {Promise<AxiosResponse<T> | never>}
   */
  getReportModerated() {
    return api.get('/moderation/reports/moderated/').then(({data}) => data)
  },
}
