import * as mutations from './mutations/types'
import api from '@/services/distributorApi'
import coreApi from "@/services/coreApi";

export default {
  /**
   * Установка выбранного контрагента
   * @param commit
   * @param activeDistributor
   */
  actionSelectDistributor: ({commit, dispatch}, activeDistributor) => {
    commit(mutations.SET_ACTIVE_DISTRIBUTOR, activeDistributor)
    dispatch('actionRefreshDistributorUnbindedCounters')
  },

  /**
   * Обновляет список счётчиков непривязанного
   * @param commit
   * @param state
   */
  actionRefreshDistributorUnbindedCounters: ({commit, state}) => {
    commit(mutations.SET_ACTIVE_DISTRIBUTOR_UNBINDED_COUNTERS)

    if (state.active_distributor) {
      return api
        .getDistributorUnbindedCounters({
          distributor_id: state.active_distributor
        })
        .then(data => {
          commit(mutations.SET_ACTIVE_DISTRIBUTOR_UNBINDED_COUNTERS, data)
        })
    } else {
      return Promise()
    }
  },

  /**
   * Обновление списка фоновых задач
   */
  actionRefreshBackgroundTasksList({commit, state}) {
    commit(mutations.SET_BACKGROUND_TASKS_FETCHING)

    return coreApi.getBackgroundTasks().then(r => {
      commit(mutations.SET_BACKGROUND_TASKS, r)
    }).finally(() => {
      commit(mutations.SET_BACKGROUND_TASKS_FETCHING, false)
    })
  }
}
