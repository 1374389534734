<template>
  <router-view></router-view>
</template>

<script>
import permissions from "@/common/permissions";
import {routes} from "@/router";
import {mapActions, mapGetters} from "vuex";

export default {
  mounted() {
    if (
      !this.getCurrentUserHasPermission(permissions.apiStockBrands)
      || !this.getCurrentUserHasPermission(permissions.apiStockBrandsCategories)
    ) {
      this.$router.push({ name: routes.dashboard })
    }
  },

  computed: {
    ...mapGetters('auth', ['getCurrentUserHasPermission']),
  }
}
</script>

<style scoped>

</style>