import * as types from './types'

export default {
  /**
   * Задаем данные по категориям
   * @param state
   * @param dataList
   */
  [types.STOCK_CATEGORIES_SET]: (state, dataList) => {
    state.stock_categories = dataList
  },

  /**
   * Флаг получения категорий
   * @param state
   * @param value
   */
  [types.STOCK_CATEGORIES_FETCHING]: (state, value) => {
    state.stock_categories_fetching = value
  },

  /**
   * Добавление категории
   * @param state
   * @param dataItem
   */
  [types.STOCK_CATEGORIES_ADD]: (state, dataItem) => {
    state.stock_categories = [...state.stock_categories, dataItem]
  },

  /**
   * Флаг добавления категории
   * @param state
   * @param value
   */
  [types.STOCK_CATEGORIES_ADDING]: (state, value) => {
    state.stock_categories_adding = value
  },

  /**
   * Обновление элемента
   * @param state
   * @param id
   * @param data
   */
  [types.STOCK_CATEGORIES_UPDATE]: (state, { id, data }) => {
    state.stock_categories = state.stock_categories.map(item =>
      item.id === id ? { ...item, ...data } : item
    )
  },

  /**
   * Флаг обновления
   * @param state
   * @param value
   */
  [types.STOCK_CATEGORIES_UPDATING]: (state, value) => {
    state.stock_categories_updating = value
  },

  /**
   * Задаем выбранные элементы
   * @param state
   * @param dataList
   */
  [types.STOCK_CATEGORIES_SELECTED_SET]: (state, data) => {
    state.stock_categories_selected = {
      ...state.stock_categories_selected,
      ...data
    }
  },

  /**
   * Флаг запроса выбранных элементов
   * @param state
   * @param value
   */
  [types.STOCK_CATEGORIES_SELECTED_FETCHING]: (state, value) => {
    state.stock_categories_selected_fetching = value
  },

  /**
   * Флаг обновления выбранных элементов
   * @param state
   * @param value
   */
  [types.STOCK_CATEGORIES_SELECTED_UPDATING]: (state, value) => {
    state.stock_categories_selected_updating = value
  }
}
