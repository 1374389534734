<template>
  <v-alert prominent :color="color" :type="type" dense>
    <slot>{{ text }}</slot>
    <template #append>
      <v-btn class="ml-3" icon small @click.stop="$emit('close')">
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </template>
  </v-alert>
</template>

<script>
export default {
  name: 'NotifyItem',
  props: {
    color: String,
    type: String,
    text: String,
    timeout: {
      type: Number,
      default: 5000
    }
  },
  created() {
    this.timeoutId = setTimeout(() => {
      this.$emit('close')
    }, this.timeout)
  },
  beforeDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }
}
</script>
