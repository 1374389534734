import api from '@/services/stockDirectionsApi'
import * as mutations from './mutations/types'

export default {
  /**
   * Очистить направления
   * @param commit
   */
  actionClearStockDirections: ({ commit }) => {
    commit(mutations.STOCK_DIRECTIONS_SET, [])
  },

  /**
   * Запрос списка направлений
   * @param commit
   * @param state
   */
  actionFetchStockDirections: ({ commit, state }) => {
    if (!state.stock_directions.length) {
      commit(mutations.STOCK_DIRECTIONS_FETCHING, true)
      api
        .getStockDirections()
        .then(dataList => {
          commit(mutations.STOCK_DIRECTIONS_SET, [...dataList])
        })
        .finally(() => {
          commit(mutations.STOCK_DIRECTIONS_FETCHING, false)
        })
    }
  }
}
