import * as types from './types'
import {INCREMENT_APPLY_FILTER, SET_BRAND_FOR_FILTER} from "./types";

export default {
  /**
   * Флаг загрузки изображения
   * @param state
   * @param value
   */
  [types.UPLOADING]: (state, value) => {
    state.uploading = value
  },

  /**
   * Установка списка элементов на модерацию
   * @param state
   * @param data
   */
  [types.IMAGES_MODERATION_SET]: (state, dataList) => {
    state.images_moderation = dataList
  },

  /**
   * Общее количество элементов
   * @param state
   * @param value
   */
  [types.IMAGES_MODERATION_COUNT]: (state, value) => {
    state.images_moderation_count = value
  },

  /**
   * Флаг получения данных
   * @param state
   * @param value
   */
  [types.IMAGES_MODERATION_FETCHING]: (state, value) => {
    state.images_moderation_fetching = value
  },

  /**
   * Установка списка элементов
   * @param state
   * @param dataList
   */
  [types.IMAGES_EMPTY_SET]: (state, dataList) => {
    state.images_empty = dataList
  },

  /**
   * Общее количество элементов
   * @param state
   * @param value
   */
  [types.IMAGES_EMPTY_COUNT]: (state, value) => {
    state.images_empty_count = value
  },

  /**
   * Флаг получения данных
   * @param state
   * @param value
   */
  [types.IMAGES_EMPTY_FETCHING]: (state, value) => {
    state.images_empty_fetching = value
  },

  /**
   * Установка списка элементов
   * @param state
   * @param dataList
   */
  [types.IMAGES_BAD_SET]: (state, dataList) => {
    state.images_bad = dataList
  },

  /**
   * Общее количество элементов
   * @param state
   * @param value
   */
  [types.IMAGES_BAD_COUNT]: (state, value) => {
    state.images_bad_count = value
  },

  /**
   * Флаг получения данных
   * @param state
   * @param value
   */
  [types.IMAGES_BAD_FETCHING]: (state, value) => {
    state.images_bad_fetching = value
  },

  /**
   * Статистка загрузок
   * @param state
   * @param dataList
   */
  [types.UPLOAD_STATS]: (state, dataList) => {
    state.upload_stats = dataList
  },

  /**
   * Флаг получения данных
   * @param state
   * @param value
   */
  [types.UPLOAD_STATS_FETCHING]: (state, value) => {
    state.upload_stats_fetching = value
  },

  /**
   * Статистика проблемных изображений
   * @param state
   * @param data
   */
  [types.PROBLEMS_STATS]: (state, data) => {
    state.problems_stats = data
  },

  /**
   * Флаг получения данных
   * @param state
   * @param value
   */
  [types.PROBLEMS_STATS_FETCHING]: (state, value) => {
    state.problems_stats_fetching = value
  },
  /**
   * Флаг получения данных
   * @param state
   * @param value
   */
  [types.USER_PAYMENTS_FETCHING]: (state, value) => {
    state.user_payments_fetching = value
  },
  /**
   * Сохранение
   * @param state
   * @param data
   */
  [types.USER_PAYMENTS]: (state, {data, userId}) => {
    state.user_payments[userId] = data
  },
  /**
   * Сохранение
   * @param state
   * @param data
   */
  [types.SET_NO_IMAGES_FILTER]: (state, filters) => {
    state.no_images_filter = filters
  },
  [types.INCREMENT_APPLY_FILTER]: (state) => {
    state.increment_apply_filter++;
  }
}
