<template>
  <div :style="`height: ${height}; overflow-y: scroll;`">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'VScrollContainer',
  props: {
    height: {
      type: String,
      default: '50vh'
    }
  }
}
</script>
