<template>
  <v-menu right v-model="show" :close-on-content-click="false">
    <template #activator="{ attrs, on }">
      <div>
        <v-badge
            left
            color="red"
            v-if="areBackgroundTasksFound"
            :content="$store.state.app_aggregator.background_tasks.length"
        >
          <v-icon v-on="on" v-bind="attrs">mdi-cogs</v-icon>
        </v-badge>
        <v-icon v-on="on" v-bind="attrs" v-else>mdi-cogs</v-icon>
      </div>
    </template>
    <template #default>
      <v-card>
        <v-card-title>Фоновые задачи</v-card-title>
        <v-card-text>
          <v-data-table
              v-if="areBackgroundTasksFound"
              max-height="300"
              :headers="headers"
              :items="$store.state.app_aggregator.background_tasks"
              disable-sort
              show-expand
              :items-per-page="5"
              single-expand
              :page.sync="page"
              hide-default-footer
              @page-count="pageCount = $event"
          >
            <template #no-data>
              <div>
                Задачи отсутствуют
              </div>
            </template>
            <template #expanded-item="{item: {execution_report}}">
              <td colspan="4" style="white-space: pre-wrap" v-if="execution_report">
                <v-card-text>{{ execution_report }}</v-card-text>
              </td>
            </template>
            <template #item.status="{item: {status}}">
              <v-btn v-if="status === 'failed'" small :ripple="false" color="red" rounded>Ошибка</v-btn>
              <v-btn v-else-if="status === 'queued'" small :ripple="false" rounded color="green">В очереди</v-btn>
              <v-btn v-else-if="status === 'processing'" small :ripple="false" rounded color="orange">Обработка</v-btn>
            </template>
            <template #item.created_at="{item: {created_at}}">
              <span>{{ created_at | datetime }}</span>
            </template>
            <template #item.data-table-expand="{item, on, attrs, isExpanded, expand}">
              <div v-if="item.execution_report">
                <v-icon @click="expand(false)" v-if="isExpanded">mdi-chevron-up</v-icon>
                <v-icon @click="expand(true)" v-else>mdi-chevron-down</v-icon>
              </div>
            </template>
            <template #item.delete="{item, on, attrs}">
              <v-menu min-width="300" max-width="300" v-on="on" v-bind="attrs">
                <template #activator="{on, attrs}">
                  <v-icon color="red" v-on="on" v-bind="attrs">mdi-close</v-icon>
                </template>
                <template #default>
                  <v-card>
                    <v-card-title>Удалить задачу</v-card-title>
                    <v-card-text>
                      <h3 v-text="item.description"/>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-space-around">
                      <v-btn text outlined color="grey">Нет</v-btn>
                      <v-btn text outlined @click="removeTask(item.id)" color="red">Да</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-menu>
            </template>
            <template #footer>
              <v-pagination
                  v-if="pageCount > 1"
                  light
                  v-model="page"
                  :length="pageCount"
                  :total-visible="10"
              />
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </template>
  </v-menu>
</template>

<script>
import coreApi from "@/services/coreApi";
import {mapActions} from "vuex";

export default {
  name: "BackgroundTasks",
  data: () => ({
    show: false,
    page: 1,
    pageCount: 0,
    headers: [
      {text: 'Имя', value: 'description', width: 350},
      {text: 'Статус', value: 'status', align: 'center', width: 150},
      {text: 'Создана', value: 'created_at', align: 'center', width: 150},
      {text: 'Удалить', value: 'delete', align: 'center', width: 100},
    ]
  }),
  methods: {
    ...mapActions(['actionAddInfoMessage']),

    removeTask(id) {
      coreApi.removeBackgroundTask(id).then(() => {
        this.actionAddInfoMessage('Задача удалена')
      })
    }
  },
  computed: {
    /**
     * Есть ли фоновые задачи
     * @returns {boolean}
     */
    areBackgroundTasksFound() {
      return (
          this.$store.state.app_aggregator.background_tasks
          && this.$store.state.app_aggregator.background_tasks.length
      )
    }
  }
}
</script>

<style scoped>

</style>