<template>
  <v-app id="app-main">
    <!-- Left menu -->
    <v-navigation-drawer
        v-model="drawer"
        app
        fixed
        temporary
        disable-resize-watcher
    >
      <AppNavigation :loading="getCurrentUserFetching"/>
    </v-navigation-drawer>

    <!-- Top bar -->
    <v-app-bar app color="indigo" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <v-icon v-if="pageIcon" small>{{ pageIcon }}</v-icon>
        {{ pageTitle }}
      </v-toolbar-title>

      <v-spacer/>

      <BackgroundTasks/>
    </v-app-bar>

    <!-- Content -->
    <v-main>
      <v-row
          v-if="!getAuthChecked"
          class="fill-height"
          align-content="center"
          justify="center"
      >
        <v-col class="subtitle-1 text-center" cols="12">
          Авторизация...
        </v-col>
        <v-col cols="3">
          <v-progress-linear
              color="indigo"
              indeterminate
              rounded
              height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <router-view v-else/>
      <NotifyList
          :items="errorMessages"
          :onClose="actionRemoveErrorMessage"
      ></NotifyList>
      <v-dialog
          max-width="500"
          v-model="show_error_dialog"
      >
        <v-card>
          <v-card-title>{{ $store.state.common.detailed_error.title }}</v-card-title>
          <v-card-text>
            <p v-for="e in $store.state.common.detailed_error.errors">{{ e }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
                class="grey--text d-block ml-auto"
                @click="$store.commit('common/CLOSE_ERROR_DIALOG', null, {root: true})"
                outlined
            >Закрыть
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>

    <!-- Footer -->
    <v-footer color="indigo" app v-if="$route.path === '/'">
      <span class="white--text">&copy; 2019-2021</span>
    </v-footer>
  </v-app>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import AppNavigation from './AppNavigation'
import NotifyList from '@/components/notify/NotifyList'
import BackgroundTasks from "@/components/app/BackgroundTasks";
import * as routes from '@/router/routes'


export default {
  components: {
    BackgroundTasks,
    AppNavigation,
    NotifyList
  },
  mounted() {
    this
        .actionFetchCurrentUser()
        .finally(this.actionRefreshBackgroundTasksList)

    setInterval(() => {
      if (!this.$store.state.app_aggregator.background_tasks_fetching)
        this.actionRefreshBackgroundTasksList()
    }, 5000)
  },
  watch: {
    $route: function (to) {
      to.matched.forEach(item => {
        if (item.meta && typeof item.meta.title !== 'undefined') {
          this.pageTitle = item.meta.title
          this.pageIcon = item.meta.titleIcon
        }
      })
    }
  },
  data: () => ({
    pageTitle: '',
    pageIcon: '',
    drawer: false
  }),
  computed: {
    ...mapGetters('common', ['errorMessages']),
    ...mapGetters('auth', ['getCurrentUserFetching', 'getAuthChecked']),
    show_error_dialog: {
      get() {
        return this.$store.state.common.detailed_error.show
      },
      set() {
        this.$store.commit('common/CLOSE_ERROR_DIALOG', null, {root: true})
      }
    }
  },
  methods: {
    ...mapActions('auth', ['actionFetchCurrentUser']),
    ...mapActions('common', ['actionRemoveErrorMessage']),
    ...mapActions('app_aggregator', ['actionRefreshBackgroundTasksList'])
  }
}
</script>

<style lang="scss">
.dashed {
  border-bottom: 1px dashed;
}

.thin-table {
  td {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}

.v-navigation-drawer {
  .navigation-skeleton {
    .v-skeleton-loader__list-item {
      height: 34px;
    }
  }

  .avatar-initials {
    font-size: 1.2rem;
  }
}
</style>
