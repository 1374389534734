<template>
  <v-container>
    <v-row v-if="currentUser" class="text-center" justify="center">
      <v-col cols="12">
        <h1 class="display-1 font-weight-light my-12 pt-6">
          Доступные приложения
        </h1>
      </v-col>
      <v-col
        v-for="item in getMainMenuItems"
        :key="item.key"
        cols="6"
        sm="3"
        lg="2"
        class="px-6"
      >
        <router-link :to="{ name: item.link }" class="dashboard-item">
          <v-avatar size="100" class="mb-0">
            <v-icon size="64" class="item-icon">{{ item.icon }}</v-icon>
          </v-avatar>
          <div class="item-title font-weight-medium">{{ item.title }}</div>
        </router-link>
      </v-col>
      <v-col
        v-if="!getMainMenuItems.length"
        class="font-italic font-weight-light"
      >
        Нет доступных элементов
      </v-col>
    </v-row>
    <v-row v-else>
      <!-- Авторизация -->
      <v-col v-if="!showPasswordRecovery" class="pt-12">
        <v-card class="mx-auto" width="400px">
          <v-card-title>
            <span class="headline">Авторизация</span>
          </v-card-title>
          <v-card-text class="text-center">
            <v-form ref="login_form" @submit.prevent="loginSubmit">
              <v-text-field
                v-model="login.username"
                type="text"
                label="Логин"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="login.password"
                :append-icon="login.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                :type="login.showPassword ? 'text' : 'password'"
                label="Пароль"
                outlined
                dense
                @click:append="login.showPassword = !login.showPassword"
              ></v-text-field>
              <div class="pt-3">
                <v-btn color="blue darken-1" type="submit" text>
                  Войти
                </v-btn>
              </div>
              <v-overlay color="white" :value="getLoginInProgress" absolute>
                <v-progress-circular
                  :size="36"
                  color="indigo lighten-4"
                  indeterminate
                ></v-progress-circular>
              </v-overlay>
            </v-form>
          </v-card-text>
        </v-card>
        <div class="mx-auto caption pt-4" style="width: 400px">
          <a @click="showPasswordRecovery = true">Восстановить пароль</a>
        </div>
      </v-col>

      <!-- Восстановление пароля -->
      <v-col v-else class="pt-12">
        <v-card class="mx-auto" width="400px">
          <v-card-title class="text-center">
            <span class="headline">Востановление пароля</span>
          </v-card-title>
          <v-card-text class="text-center">
            <v-form
              v-if="!passwordRecovery.formSended"
              ref="password_recovery_form"
              @submit.prevent="passwordRecoverySubmit"
            >
              <v-text-field
                v-model="passwordRecovery.username"
                :rules="[rules.required]"
                type="text"
                label="Логин / Email"
                outlined
                dense
              ></v-text-field>
              <div class="pt-3">
                <v-btn color="blue darken-1" type="submit" text>
                  Отправить
                </v-btn>
              </div>
              <v-overlay
                color="white"
                :value="getRecoveryPasswordInProgress"
                absolute
              >
                <v-progress-circular
                  :size="36"
                  color="indigo lighten-4"
                  indeterminate
                ></v-progress-circular>
              </v-overlay>
            </v-form>
            <div v-else>
              Сообщение отправлено на почту.
              <div class="pt-3">
                <v-btn
                  @click="passwordRecovery.formSended = false"
                  color="blue darken-1"
                  type="submit"
                  text
                >
                  Отправить ещё раз
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <div class="mx-auto caption pt-4" style="width: 400px">
          <a @click="showPasswordRecovery = false">Авторизация</a>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  data: () => ({
    items: [],
    login: {
      username: '',
      password: '',
      showPassword: false
    },
    passwordRecovery: {
      username: '',
      formSended: false
    },
    showPasswordRecovery: false,
    rules: {
      required: value => !!value || 'Обязательно к заполнению'
    }
  }),
  computed: {
    ...mapGetters('auth', [
      'getCurrentUserHasPermission',
      'getLoginInProgress',
      'getRecoveryPasswordInProgress',
      'currentUser'
    ]),
    ...mapGetters([
      'getMainMenuItems',
    ]),
  },
  methods: {
    ...mapActions('auth', [
      'actionLogin',
      'actionFetchCurrentUser',
      'actionPasswordRecovery'
    ]),
    loginSubmit: function () {
      if (this.$refs.login_form.validate()) {
        this.actionLogin({
          username: this.login.username,
          password: this.login.password
        }).then(data => {
          if (data && data.ok) {
            this.actionFetchCurrentUser()
          }
        })
      }
    },
    passwordRecoverySubmit: function () {
      if (this.$refs.password_recovery_form.validate()) {
        this.actionPasswordRecovery(this.passwordRecovery.username).then(() => {
          this.passwordRecovery.formSended = true
          this.passwordRecovery.login = ''
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dashboard-item {
  color: #424242;
  text-decoration: none;

  &:hover {
    .item-title,
    .item-icon {
      color: #01579b;
    }
  }
}
</style>
