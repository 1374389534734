export const dashboard = 'dashboard'

export const brandsIndex = 'brands-index'
export const brandsDetail = 'brands-detail'

export const imagesIndex = 'images-index'
export const imagesStatsUploads = 'images-stats-uploads'
export const imagesModeration = 'images-moderation'
export const imagesEmpty = 'images-empty'
export const imagesBad = 'images-bad'
export const imagesStatsMy = 'images-stats-my'
export const imagesSearch = 'images-search'

export const catalogIndex = 'catalog-index'
export const catalogType = 'catalog-type'
export const catalogDetail = 'catalog-detail'
export const catalogProduct = 'catalog-product'

export const aggregatorSelectDistributor = 'aggregator-select-distributor'
export const aggregatorStepSelectCatalogs = 'aggregator-step-select-catalogs'
export const aggregatorStepBindBrands = 'aggregator-step-bind-brands'
export const aggregatorStepBindFeatures = 'aggregator-step-bind-features'
export const aggregatorStepBindUnits = 'aggregator-step-bind-units'
export const aggregatorStepBindCatalogs = 'aggregator-step-bind-catalogs'
export const aggregatorStepBindProducts = 'aggregator-step-bind-products'

export const exportIndex = 'export-index'

export const notfound = 'notfound'

export const logsIndex = 'logs-index'
export const logs1CExport = 'logs-1c-export'

export const settingsPage = 'settings'


export const imagesUploadReport = 'images-upload-report'
export const imagesReportModerated = 'images-report-moderated'