import * as types from './types'

export default {
  /**
   * Задаем список найденных контрагентов
   * @param state
   * @param dataList
   */
  [types.DISTRIBUTOR_LIST_SEARCH_SET]: (state, { dataList }) => {
    state.distributor_list_search = dataList
  },

  /**
   * Флаг поиска контрагентов
   * @param state
   * @param fetching
   */
  [types.DISTRIBUTOR_LIST_SEARCH_FETCHING]: (state, value) => {
    state.distributor_list_search_fetching = value
  },

  /**
   * Задаем список контрагентов связанных с парсерами
   * @param state
   * @param dataList
   */
  [types.DISTRIBUTOR_LIST_SET]: (state, { dataList }) => {
    state.distributor_list = dataList
  },

  /**
   * Добавляем элемент в список связанных контрагентов
   * @param state
   * @param dataItem
   */
  [types.DISTRIBUTOR_LIST_ADD]: (state, { dataItem }) => {
    state.distributor_list = [...state.distributor_list, dataItem]
  },

  /**
   * Обновление элемента в списке связанных контрагентов
   * @param state
   * @param dataItem
   */
  [types.DISTRIBUTOR_LIST_UPDATE]: (state, { id, data }) => {
    state.distributor_list = state.distributor_list.map(oldItem =>
      id === oldItem.id ? { ...oldItem, ...data } : oldItem
    )
  },

  /**
   * Флаг получения контрагентов связанных с парсерами
   * @param state
   * @param fetching
   */
  [types.DISTRIBUTOR_LIST_FETCHING]: (state, value) => {
    state.distributor_list_fetching = value
  },

  /**
   * Флаг привязки контрагента и парсера
   * @param state
   * @param value
   */
  [types.DISTRIBUTOR_ADDING]: (state, value) => {
    state.distributor_adding = value
  },

  /**
   * Задаем список парсеров
   * @param state
   * @param dataList
   */
  [types.PARSER_LIST_SET]: (state, { dataList }) => {
    state.parser_list = dataList
  },

  /**
   * Флаг получения списка парсеров
   * @param state
   * @param fetching
   */
  [types.PARSER_LIST_FETCHING]: (state, value) => {
    state.parser_list_fetching = value
  },

  /**
   * Бренды поставщика
   * @param state
   * @param idList
   */
  [types.DISTRIBUTOR_BRANDS]: (state, idList) => {
    state.distributor_brands = idList
  }
}
