import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  // Список найденных контрагентов
  distributor_list_search: [],
  // Флаг поиска поставщиков
  distributor_list_search_fetching: false,
  // Список поставщиков привязанных к парсерам
  distributor_list: [],
  // Флаг получения поставщиков привязанных к парсерам
  distributor_list_fetching: false,
  // Флаг привязки контрагента
  distributor_adding: false,
  // список id брендов
  distributor_brands: [],
  // Список парсеров
  parser_list: [],
  // Флаг получения списка парсеров
  parser_list_fetching: false
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
