import * as types from './types'

export default {
  /**
   * Задаем данные юнитам стока
   * @param state
   * @param dataList
   */
  [types.STOCK_UNITS_SET]: (state, dataList) => {
    state.stock_units = dataList
  },

  /**
   * Флаг получения юнитов стока
   * @param state
   * @param value
   */
  [types.STOCK_UNITS_FETCHING]: (state, value) => {
    state.stock_units_fetching = value
  }
}
