const buildTree = dataList => {
  const dataObject = dataList.reduce((accum, item) => {
    const id = item.id
    const itemNodes = item.name.split('.')
    itemNodes.forEach((nameNode, index) => {
      const idNode = itemNodes.slice(0, index + 1).join('.')
      const idParentNode = index ? itemNodes.slice(0, index).join('.') : null
      const idSurrogate = `${id}_${index}`
      const isSelectable = itemNodes.length - 1 === index
      const parentNode = idParentNode ? accum[idParentNode] : null
      const itemNode = {
        id: isSelectable ? id : idSurrogate,
        surrogate_id: idSurrogate,
        node_id: idNode,
        name: nameNode,
        parent: parentNode ? parentNode.id : null,
        selectable: isSelectable,
        children: []
      }
      if (!accum[idNode]) {
        accum[idNode] = itemNode
      }
      if (parentNode) {
        if (!parentNode.children.map(child => child.node_id).includes(idNode)) {
          parentNode.children.push(itemNode)
        }
      }
    })
    return accum
  }, {})
  return Object.values(dataObject).map(item => Object.freeze(item))
}

export default {
  /**
   * Дерево направлений
   * @param stock_directions
   */
  getStockDirectionsTree({ stock_directions }) {
    return buildTree(stock_directions)
  },

  /**
   * Получение корневых веток направлений
   * @param state
   * @param getters
   * @returns {*}
   */
  getStockDirectionsTreeRoot(state, getters) {
    return getters.getStockDirectionsTree.filter(item => !item.parent)
  },

  /**
   * Флаг получения направлений
   * @param stock_directions_fetching
   * @returns boolean
   */
  getStockDirectionsFetching({ stock_directions_fetching }) {
    return stock_directions_fetching
  },

  /**
   * Получение элемента по id
   * @param state
   * @param getters
   * @returns {function(*): *}
   */
  getStockDirectionsById(state, getters) {
    return id => getters.getStockDirectionsTree.find(item => item.id === id)
  },

  /**
   * Получение списка элементов по id
   * @param state
   * @param getters
   * @returns {function(*): Array}
   */
  getStockDirectionsByIdList(state, getters) {
    return idList =>
      idList.length
        ? getters.getStockDirectionsTree.filter(item =>
            idList.includes(item.id)
          )
        : []
  },

  /**
   * Получение списка предков
   * @param state
   * @param getters
   * @returns {function(*): []}
   */
  getStockDirectionsAncestorsById(state, getters) {
    return id => {
      let nextAncestorId = id
      const ancestorsList = []
      while (nextAncestorId) {
        let item = getters.getStockDirectionsById(nextAncestorId)
        if (item) {
          ancestorsList.push(item)
          nextAncestorId = item.parent
        } else {
          nextAncestorId = null
        }
      }
      return ancestorsList
    }
  }
}
