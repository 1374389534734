export default {
  /**
   * Получение всех параметров поставщика
   * @param distributor_features
   * @returns {*}
   */
  getDistributorFeatures({ distributor_features }) {
    return distributor_features
  },

  /**
   * Флаг получения параметров поставщика
   * @param distributor_features_fetching
   * @returns {*}
   */
  getDistributorFeaturesFetching({ distributor_features_fetching }) {
    return distributor_features_fetching
  },

  /**
   * Флаг обновления параметров
   * @param distributor_features_updating
   * @returns {*}
   */
  getDistributorFeaturesUpdating({ distributor_features_updating }) {
    return distributor_features_updating
  },

  /**
   * Флаг получения кандидатов на привязку
   * @param distributor_features_bindings_fetching
   * @returns {*}
   */
  getDistributorFeaturesBindingsFetching({
    distributor_features_bindings_fetching
  }) {
    return distributor_features_bindings_fetching
  },

  /**
   * Получение параметра поставщика по id
   * @param distributor_features
   * @returns {function(*): *}
   */
  getDistributorFeatureById({ distributor_features }) {
    return id => distributor_features.find(item => item.id == id)
  }
}
