import api from './api'

export default {
  /**
   * Получение списка категорий поставщика
   * @param distributor
   * @returns {Promise<any>}
   */
  getAllDistributorCategories(distributor) {
    return api
      .get(`/parser/categories/`, { params: { distributor } })
      .then(({ data }) => data)
  },

  /**
   * Получение списка выбранных категорий поставщика
   * @param distributor
   * @returns {Promise<any>}
   */
  getSelectedDistributorCategories(distributor) {
    return api
      .get(`/parser/categories/`, { params: { distributor, enabled: true } })
      .then(({ data }) => data)
  },

  /**
   * Вкл/Выкл категорий поставщика
   * @param toggleOn
   * @param toggleOff
   * @returns {Promise<any>}
   */
  switchDistributorCategories(toggleOn, toggleOff) {
    return api
      .post(
        `/parser/categories/mass_toggle/`,
        {
          toggle_on: toggleOn,
          toggle_off: toggleOff
        },
        {},
        { successMessage: 'Настройки успешно сохранены' }
      )
      .then(({ data }) => data)
  },

  /**
   * Привязка категории
   * @param id
   * @param bind_structure
   * @param bind_category
   * @param direction
   * @param merge_method
   * @returns {Promise<any>}
   */
  bindDistributorCategories(
    id,
    { bind_structure, bind_category, direction, merge_method }
  ) {
    return api
      .patch(
        `/parser/categories/${id}/`,
        {
          bind_structure,
          bind_category,
          direction,
          merge_method
        },
        {},
        { successMessage: 'Привязка раздела успешно изменена' }
      )
      .then(({ data }) => data)
  },

  /**
   * Получение списка кандидатов для привязки (структура номенклатуры)
   * @param id
   * @returns {Promise<any>}
   */
  getCategoriesStructureSuggest(id) {
    return api
      .get(`/parser/categories/${id}/suggest_structure/`)
      .then(response => response && response.data.map(item => item.id))
  },

  /**
   * Получение списка кандидатов для привязки (ветки ассортимента)
   * @param id
   * @returns {Promise<any>}
   */
  getCategoriesTreeSuggest(id) {
    return api
      .get(`/parser/categories/${id}/suggest_category/`)
      .then(response => response && response.data.map(item => item.id))
  },

  /**
   * Получить варианты привязки дочерних категорий данной категории к номенклатуре
   */
  suggestStockCategoriesForDistributorCategory(id) {
    return api
      .get(`/parser/categories/${id}/suggest_childs/`)
      .then(({data}) => data)
  },

  /**
   * Применяет параметры слияния на несколько категорий
   */
  massApplyMergeBindings(rules) {
    return api
      .patch(`/parser/categories/apply_mass_merge_bindings/`, rules)
      .then(({data}) => data)
  }
}
