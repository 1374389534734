export default {
  /**
   * Список результатов поиска
   * @param stock_products
   * @param stock_products_search
   * @returns {*}
   */
  getStockProductsSearch({ stock_products, stock_products_search }) {
    return stock_products_search.reduce((accum, id) => {
      accum.push(stock_products[id])
      return accum
    }, [])
  },

  /**
   * Флаг получения списка предложений
   * @param stock_products_search_fetching
   * @returns {*}
   */
  getStockProductsSearchFetching({ stock_products_search_fetching }) {
    return stock_products_search_fetching
  },

  /**
   * Получение товара по id
   * @param stock_products
   * @returns {function(*): (*|null)}
   */
  getStockProductById({ stock_products }) {
    return id => stock_products[id] || null
  },

  /**
   * Получение товаров по списку id
   * @param stock_products
   * @returns {function(*): *}
   */
  getStockProductsByIdList({ stock_products }) {
    return idList => {
      return idList.reduce((accum, id) => {
        if (stock_products[id]) {
          accum.push(stock_products[id])
        }
        return accum
      }, [])
    }
  },

  /**
   * Фильтрация товаров по набору параметров
   * @param stock_products
   * @returns {function(*): *}
   */
  getStockProductsByParams({ stock_products }) {
    return params => {
      return Object.values(stock_products).filter(item => {
        return Object.keys(params).every(key => item[key] === params[key])
      })
    }
  },

  /**
   * Список id предложений
   * @param distributor_products_suggest
   * @returns {*}
   */
  getStockProductsSuggest({ stock_products_suggest }) {
    return id => stock_products_suggest[id] || null
  },

  /**
   * Флаг получения кандидатов на привязку
   * @param stock_products_suggest_fetching
   * @returns {*}
   */
  getStockProductsSuggestFetching({ stock_products_suggest_fetching }) {
    return stock_products_suggest_fetching
  },

  /**
   * Флаг получения товаров
   * @param stock_products_fetching
   * @returns {*}
   */
  getStockProductsFetching({ stock_products_fetching }) {
    return stock_products_fetching
  },

  /**
   * Флаг создания товара
   * @param stock_products_creating
   * @returns {*}
   */
  getStockProductsCreating({ stock_products_creating }) {
    return stock_products_creating
  },

  /**
   * Флаг обновления товара
   * @param stock_products_updating
   * @returns {*}
   */
  getStockProductsUpdating({ stock_products_updating }) {
    return stock_products_updating
  }
}
