import api from '@/services/stockUnitsApi'
import * as mutations from './mutations/types'

export default {
  /**
   * Очистить список юнитов стока
   * @param commit
   */
  actionClearStockUnits: ({ commit }) => {
    commit(mutations.STOCK_UNITS_SET, [])
  },

  /**
   * Запрос юнитов стока
   * @param commit
   */
  actionFetchStockUnits: ({ commit }) => {
    commit(mutations.STOCK_UNITS_FETCHING, true)
    return api
      .getUnits()
      .then(dataList => {
        commit(mutations.STOCK_UNITS_SET, [...dataList])
      })
      .finally(() => {
        commit(mutations.STOCK_UNITS_FETCHING, false)
      })
  }
}
