import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  // Данные по брендам контрагента
  distributor_brands: [],
  // Флаг запроса всех брендов поставщика
  distributor_brands_fetching: false,
  // Флаг обновления бренда поставщика
  distributor_brands_updating: false,
  // Флаг получения кандидатов для привязки
  distributor_brands_bindings_fetching: false
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
