import api from '@/services/distributorProductsApi'
import * as mutations from './types'

export default {
  /**
   * Очистить данные по товарам поставщика
   * @param commit
   */
  actionClearDistributorProducts: ({ commit }) => {
    commit(mutations.DISTRIBUTOR_PRODUCTS_SET, { items: [], count: 0 })
  },

  /**
   * Запрос всех товаров поставщика
   * @param commit
   * @param params
   */
  actionFetchDistributorProducts: ({ commit }, params) => {
    commit(mutations.DISTRIBUTOR_PRODUCTS_FETCHING, true)
    api
      .getDistributorProducts(params)
      .then(data => {
        commit(mutations.DISTRIBUTOR_PRODUCTS_SET, {
          items: (data && data.results) || [],
          count: (data && data.count) || 0
        })
      })
      .finally(() => {
        commit(mutations.DISTRIBUTOR_PRODUCTS_FETCHING, false)
      })
  },

  /**
   * Привязка товара
   * @param commit
   * @param id
   * @param bindId
   * @returns {Promise<T>}
   */
  actionBindDistributorProduct: ({ commit, dispatch }, { id, bindId }) => {
    commit(mutations.DISTRIBUTOR_PRODUCTS_UPDATING, true)
    return api
      .bindDistributorProduct(id, bindId)
      .then(data => {
        commit(mutations.DISTRIBUTOR_PRODUCTS_UPDATE, {
          id: id,
          data: { ...data }
        })
      })
      .finally(() => {
        commit(mutations.DISTRIBUTOR_PRODUCTS_UPDATING, false)
        dispatch('app_aggregator/actionRefreshDistributorUnbindedCounters', null, {root: true})
      })
  },

  /**
   * Вкл/Выкл товара
   * @param commit
   * @param id
   * @param enabled
   * @returns {Promise<T>}
   */
  actionSwitchDistributorProduct: ({ commit, dispatch }, { id, enabled }) => {
    commit(mutations.DISTRIBUTOR_PRODUCTS_UPDATING, true)
    return api
      .switchDistributorProduct(id, enabled)
      .then(data => {
        commit(mutations.DISTRIBUTOR_PRODUCTS_UPDATE, {
          id: id,
          data: { ...data }
        })
      })
      .finally(() => {
        commit(mutations.DISTRIBUTOR_PRODUCTS_UPDATING, false)
        dispatch('app_aggregator/actionRefreshDistributorUnbindedCounters', null, {root: true})
      })
  },

  /**
   * Обновление данных по товару
   * @param commit
   * @param id
   * @param data
   */
  actionPatchDistributorProduct: ({ commit }, { id, data }) => {
    commit(mutations.DISTRIBUTOR_PRODUCTS_UPDATE, {
      id: id,
      data: { ...data }
    })
    return Promise.resolve()
  },

  /**
   * Статистика товаров контрагента
   * @param commit
   * @param id
   * @param enabled
   */
  actionFetchDistributorProductsStats: ({ commit }, { id, enabled }) => {
    api
      .getDistributorProductsStats(id, enabled)
      .then(({ total, binded, unbinded, disabled }) => {
        commit(mutations.DISTRIBUTOR_PRODUCTS_STATS_SET, {
          total,
          binded,
          unbinded,
          disabled
        })
      })
  }
}
