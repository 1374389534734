export const DISTRIBUTOR_CATEGORIES_SET = 'DISTRIBUTOR_CATEGORIES_SET'
export const DISTRIBUTOR_CATEGORIES_UPDATE = 'DISTRIBUTOR_CATEGORIES_UPDATE'
export const DISTRIBUTOR_CATEGORIES_FETCHING = 'DISTRIBUTOR_CATEGORIES_FETCHING'
export const DISTRIBUTOR_CATEGORIES_UPDATING = 'DISTRIBUTOR_CATEGORIES_UPDATING'
export const DISTRIBUTOR_CATEGORIES_BINDINGS_FETCHING =
  'DISTRIBUTOR_CATEGORIES_BINDINGS_FETCHING'
export const DISTRIBUTOR_CATEGORIES_SELECTED_SET =
  'DISTRIBUTOR_CATEGORIES_SELECTED_SET'
export const DISTRIBUTOR_CATEGORIES_SUGGEST_SET =
  'DISTRIBUTOR_CATEGORIES_SUGGEST_SET'
