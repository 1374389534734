export default {
  /**
   * Список товаров
   * @param distributor_products
   * @returns {*}
   */
  getDistributorProducts({ distributor_products }) {
    return distributor_products
  },

  /**
   * Количестов товаров
   * @param distributor_products_count
   * @returns {*}
   */
  getDistributorProductsCount({ distributor_products_count }) {
    return distributor_products_count
  },

  /**
   * Количество привязанных товаров
   * @param distributor_products_stats_binded
   * @returns {*}
   */
  getDistributorCountBindedProducts({ distributor_products_stats_binded }) {
    return distributor_products_stats_binded
  },

  /**
   * Количество непривязанных товаров
   * @param distributor_products_stats_unbinded
   * @returns {*}
   */
  getDistributorCountUnbindedProducts({ distributor_products_stats_unbinded }) {
    return distributor_products_stats_unbinded
  },

  /**
   * Количество всех товаров
   * @param distributor_products_stats_total
   * @returns {*}
   */
  getDistributorCountTotalProducts({ distributor_products_stats_total }) {
    return distributor_products_stats_total
  },

  /**
   * Флаг получения товаров поставщика
   * @param distributor_products_fetching
   * @returns {*}
   */
  getDistributorProductsFetching({ distributor_products_fetching }) {
    return distributor_products_fetching
  },

  /**
   * Флаг обновления
   * @param distributor_products_updating
   * @returns {*}
   */
  getDistributorProductsUpdating({ distributor_products_updating }) {
    return distributor_products_updating
  },

  /**
   * Получение товара по id
   * @param distributor_products
   * @returns {function(*): (*|null)}
   */
  getDistributorProductById({ distributor_products }) {
    return id => distributor_products.find(item => item.id === id) || null
  },

  /**
   * Получение товаров по списку id
   * @param distributor_products
   * @returns {function(*): *}
   */
  getDistributorProductsByIdList({ distributor_products }) {
    return idList =>
      distributor_products.filter(item => idList.includes(item.id))
  }
}
