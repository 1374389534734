import api from './api'

export default {
  /**
   * Список юнитов стока
   * @returns {Promise<any>}
   */
  getUnits() {
    return api.get(`/stock/units/`).then(({ data }) => data)
  }
}
