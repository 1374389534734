import _ from 'lodash'

export default {
  /**
   * Флаг загрузки изображений
   * @param uploading
   * @returns {*}
   */
  getUploading({uploading}) {
    return uploading
  },

  /**
   * Изображения на модерацию
   * @param images_moderation
   * @returns {*}
   */
  getImagesModeration({images_moderation}) {
    return images_moderation
  },

  /**
   * Количество изображений на модерацию
   * @param images_moderation_count
   * @returns {*}
   */
  getImagesModerationCount({images_moderation_count}) {
    return images_moderation_count
  },

  /**
   * Флаг получения
   * @param images_moderation_fetching
   * @returns {*}
   */
  getImagesModerationFetching({images_moderation_fetching}) {
    return images_moderation_fetching
  },

  /**
   * Список элементов без изображений
   * @param images_empty
   * @returns {*}
   */
  getImagesEmpty({images_empty}) {
    return images_empty
  },

  /**
   * Количество элементов без изображений
   * @param images_empty_count
   * @returns {*}
   */
  getImagesEmptyCount({images_empty_count}) {
    return images_empty_count
  },

  /**
   * Флаг получения
   * @param images_empty_fetching
   * @returns {*}
   */
  getImagesEmptyFetching({images_empty_fetching}) {
    return images_empty_fetching
  },

  /**
   * Список элементов с плохими изображениями
   * @param images_bad
   * @returns {*}
   */
  getImagesBad({images_bad}) {
    return images_bad
  },

  /**
   * Количество элементов с плохими изображениями
   * @param images_bad_count
   * @returns {*}
   */
  getImagesBadCount({images_bad_count}) {
    return images_bad_count
  },

  /**
   * Флаг получения плохих изображений
   * @param images_bad_fetching
   * @returns {*}
   */
  getImagesBadFetching({images_bad_fetching}) {
    return images_bad_fetching
  },

  /**
   * Статистика загрузок
   * @param upload_stats
   * @returns {*}
   */
  getUploadStats({upload_stats}) {
    return _.orderBy(
      upload_stats.map(({id, fio, total_uploads, last_payment}) => ({
        name: fio,
        count_uploads: total_uploads,
        last_payment,
        id
      })),
      ['count_uploads', 'name'],
      ['desc', 'asc']
    )
  },

  /**
   * Флаг получения статистики загрузок
   * @param upload_stats_fetching
   * @returns {*}
   */
  getUploadStatsFetching({upload_stats_fetching}) {
    return upload_stats_fetching
  },

  /**
   * Статистика проблемных изображений
   * @param problems_stats
   * @returns {*}
   */
  getProblemsStats({problems_stats}) {
    return _.orderBy(problems_stats, ['name'], ['asc'])
  },

  /**
   * Флаг получения статистики проблемных фото
   * @param problems_stats_fetching
   * @returns {*}
   */
  getProblemsStatsFetching({problems_stats_fetching}) {
    return problems_stats_fetching
  },
  /**
   * Флаг получения статистики проблемных фото
   * @param problems_stats_fetching
   * @returns {*}
   */
  getUserPayments: ({user_payments}) => (id) => {
    return user_payments[id]?.map(userPayment => {
      return {
        id: userPayment.id,
        paymentFor: userPayment.payment_for,
        moderatedBy: userPayment.moderated_by,
        moderatedImagesCount: userPayment.moderated_images_count,
        created: userPayment.created
      }
    })
  },
  /**
   * Получение фильтов
   * @param no_images_filter
   * @returns {*}
   */
  getNoImagesFilter({no_images_filter}) {
    return no_images_filter
  },
  /**
   * Флаг получения статистики проблемных фото
   * @param brand_for_filter
   * @returns {*}
   */
  getIncrementApplyFilter({increment_apply_filter}) {
    return increment_apply_filter
  },
}
