import api from '@/services/stockBrandsApi'
import * as mutations from './mutations/types'

/**
 * @deprecated Уйти от использования излишних преобразований
 * @param item
 * @returns {{image, surrogate_category_id: string, _1c_id: (string|*), image_url, name, id, priority: *, category, is_brand: boolean, surrogate_id: string}}
 */
function preprocessBrand(item) {
    return {
        id: item.id,
        _1c_id: item._1c_id,
        image: item.image,
        image_url: item.image_url,
        priority: item.priority,
        surrogate_id: `brand_${item.id}`,
        enabled: item.enabled,
        category: item.category,
        is_show_main_page: item.is_show_main_page,
        link: item.link,
        surrogate_category_id: `category_${item.category}`,
        name: item.name,
        english_name: item.english_name,
        description: item.description,
        is_brand: true
    }
}

export default {
    /**
     * Очистить данные по брендам
     * @param commit
     */
    actionClearStockBrands: ({commit}) => {
        commit(mutations.STOCK_BRANDS_SET, [])
    },

    /**
     * Запрос брендов и категорий
     * @param commit
     */
    actionFetchStockBrands: ({commit}) => {
        commit(mutations.STOCK_BRANDS_FETCHING, true)
        return Promise.all([api.getStockBrands(), api.getStockBrandsCategories()])
            .then(([brands, categories]) => {
                commit(
                    mutations.STOCK_BRANDS_SET,
                    brands.map(preprocessBrand)
                )
                commit(
                    mutations.STOCK_BRANDS_CATEGORIES_SET,
                    categories.map(item => {
                        return {
                            id: item.id,
                            surrogate_id: `category_${item.id}`,
                            name: item.name
                        }
                    })
                )
            })
            .finally(() => {
                commit(mutations.STOCK_BRANDS_FETCHING, false)
            })
    },

    actionFetchStockBrand: ({commit}, id) => {
        commit(mutations.STOCK_BRANDS_FETCHING, true)

        return api.getStockBrand(id).then(brand => {
            commit(mutations.STOCK_BRANDS_UPDATE, {id, data: preprocessBrand(brand)})
            return brand
        }).finally(() => {
            commit(mutations.STOCK_BRANDS_FETCHING, false)
        })
    },

    actionUpdateStockBrand({commit}, {id, data}) {
        return api
            .patchStockBrand(id, data)
            .then(data => {
                commit(mutations.STOCK_BRANDS_UPDATE, {id, data})
                return data
            })
    },

    actionCreateStockBrand({commit}, {name, english_name, category, priority, image}) {
        return api
            .createStockBrand(name, english_name, category, priority, image)
            .then(data => {
                commit(mutations.STOCK_BRANDS_ADD, data)
                return data
            })

    }
}
