import api from '@/services/distributorCategoriesApi'
import * as mutations from './mutations/types'

export default {
  /**
   * Очистить данные по категориям контрагента
   * @param commit
   */
  actionClearDistributorCategories: ({ commit }) => {
    commit(mutations.DISTRIBUTOR_CATEGORIES_SET, [])
    commit(mutations.DISTRIBUTOR_CATEGORIES_SELECTED_SET, {
      default: [],
      changed: null
    })
  },

  /**
   * Запрос всех категорий поставщика
   * @param commit
   * @param distributorId
   */
  actionFetchAllDistributorCategories: ({ commit }, distributorId) => {
    commit(mutations.DISTRIBUTOR_CATEGORIES_FETCHING, true)
    return api
      .getAllDistributorCategories(distributorId)
      .then(dataList => {
        commit(mutations.DISTRIBUTOR_CATEGORIES_SET, dataList)
        commit(mutations.DISTRIBUTOR_CATEGORIES_SELECTED_SET, {
          default: dataList.filter(item => item.enabled).map(item => item.id)
        })
      })
      .finally(() => {
        commit(mutations.DISTRIBUTOR_CATEGORIES_FETCHING, false)
      })
  },

  /**
   * Запрос выбранных категорий
   * @param commit
   * @param distributor
   */
  actionFetchSelectedDistributorCategories: ({ commit }, distributor) => {
    commit(mutations.DISTRIBUTOR_CATEGORIES_FETCHING, true)
    api
      .getSelectedDistributorCategories(distributor)
      .then(dataList => {
        commit(mutations.DISTRIBUTOR_CATEGORIES_SET, dataList)
      })
      .finally(() => {
        commit(mutations.DISTRIBUTOR_CATEGORIES_FETCHING, false)
      })
  },

  /**
   * Запрос вкл/выкл категорий поставщика
   * @param commit
   * @param toggleOn
   * @param toggleOff
   * @returns {Promise<T>}
   */
  actionSwitchDistributorCategories: ({ commit, dispatch }, { toggleOn, toggleOff }) => {
    commit(mutations.DISTRIBUTOR_CATEGORIES_UPDATING, true)
    return api.switchDistributorCategories(toggleOn, toggleOff).finally(() => {
      commit(mutations.DISTRIBUTOR_CATEGORIES_UPDATING, false)
      dispatch('app_aggregator/actionRefreshDistributorUnbindedCounters', null, {root: true})
    })
  },

  /**
   * Обновление настроек привязки категории
   * @param commit
   * @param id
   * @param bind_structure
   * @param bind_category
   * @param direction
   * @param merge_method
   */
  actionBindDistributorCategories: (
    { commit, dispatch },
    { id, bind_structure, bind_category, direction, merge_method }
  ) => {
    commit(mutations.DISTRIBUTOR_CATEGORIES_UPDATING, true)
    api
      .bindDistributorCategories(id, {
        bind_structure,
        bind_category,
        direction,
        merge_method
      })
      .then(data => {
        commit(mutations.DISTRIBUTOR_CATEGORIES_UPDATE, { id, data })
      })
      .finally(() => {
        commit(mutations.DISTRIBUTOR_CATEGORIES_UPDATING, false)
        dispatch('app_aggregator/actionRefreshDistributorUnbindedCounters', null, {root: true})
      })
  },

  /**
   * Получение кандидатов на привязку
   * @param commit
   * @param state
   * @param id
   * @returns {Q.Promise<any>}
   */
  actionFetchCategorySuggest: ({ commit, state }, id) => {
    if (!state.distributor_categories_suggest[id]) {
      commit(mutations.DISTRIBUTOR_CATEGORIES_BINDINGS_FETCHING, true)
      return Promise.all([
        api.getCategoriesStructureSuggest(id),
        api.getCategoriesTreeSuggest(id)
      ])
        .then(([categoriesSuggest, treeSuggest]) => {
          commit(mutations.DISTRIBUTOR_CATEGORIES_SUGGEST_SET, {
            id,
            category: categoriesSuggest,
            tree: treeSuggest
          })
        })
        .finally(() => {
          commit(mutations.DISTRIBUTOR_CATEGORIES_BINDINGS_FETCHING, false)
        })
    }
  },

  /**
   * Установка выбранных элементов
   * @param commit
   * @param data
   */
  actionSetDistributorCategoriesSelected: ({ commit }, data) => {
    commit(mutations.DISTRIBUTOR_CATEGORIES_SELECTED_SET, data)
  }
}
