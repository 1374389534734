import Vue from 'vue'
import Router from 'vue-router'
import StockWrapper from '@/views/stock'
import DashboardView from '@/views/DashboardView'
import settingsPage from '@/views/settingsPage';
import BrandsWrapper from '@/views/brands/Wrapper'
import * as routes from './routes'
import FilterNoImages from '@/views/images/FilterNoImages.vue'
import UploadReport from "../views/images/UploadReport.vue";
import {imagesReportModerated} from "./routes";
import reportModerated from "../views/images/reportModerated.vue";

const NotFoundView = () =>
  import(/* webpackChunkName: "notfound" */ '@/views/NotFoundView')
const Aggregator = viewName => () =>
  import(/* webpackChunkName: "aggregator" */ `@/views/aggregator/`).then(module => module[viewName])
const Catalog = viewName => () =>
  import(/* webpackChunkName: "catalog" */ `@/views/catalog/`).then(module => module[viewName])
const Images = viewName => () =>
  import(/* webpackChunkName: "images" */ `@/views/images/`).then(module => module[viewName])
const Export = viewName => () =>
  import(/* webpackChunkName: "export" */ `@/views/export/`).then(module => module[viewName])
const Logs = viewName => () =>
  import(/* webpackChunkName: "logs" */ `@/views/logs/`).then(module => module[viewName])
const Brands = viewName => () =>
  import(/* webpackChunkName: "brands" */ `@/views/brands/`).then(module => module[viewName])

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: routes.dashboard,
      component: DashboardView,
      meta: {title: 'Агрегатор'}
    },
    {
      path: '/brands/',
      name: routes.brandsIndex,
      component: Brands('MainView'),
      meta: {title: 'Бренды', titleIcon: 'mdi-babel'},
      children: [
        {
          path: ':id',
          name: routes.brandsDetail,
          component: Brands('MainView')
        }
      ]
    },
    {
      path: '/export/',
      component: Export('Wrapper'),
      meta: {title: 'Экспорт в 1с', titleIcon: 'mdi-progress-upload'},
      children: [
        {
          path: '',
          name: routes.exportIndex,
          component: Export('MainView')
        }
      ]
    },
    {
      path: '/logs/',
      component: Logs('Wrapper'),
      meta: {title: 'Логи', titleIcon: 'mdi-text'},
      children: [
        {
          path: '',
          name: routes.logsIndex,
          component: Logs('MainView')
        },
        {
          path: '1c_export',
          meta: {title: 'Ошибки экспорта в 1С', journal: 'Export'},
          name: routes.logs1CExport,
          component: Logs('Journal'),
        }
      ]
    },
    {
      path: '/images/',
      component: StockWrapper,
      meta: {title: 'Изображения', titleIcon: 'mdi-image-multiple'},
      children: [
        {
          path: '',
          components: {
            default: Images('Wrapper'),
            menu: Images('NavigationMenu'),
            aside: FilterNoImages
          },
          children: [
            {
              path: '',
              name: routes.imagesIndex,
              component: Images('MainView')
            },
            {
              path: 'search',
              name: routes.imagesSearch,
              component: Images('SearchView')
            },
            {
              path: 'stats',
              name: routes.imagesStatsUploads,
              component: Images('StatsUploadsView')
            },
            {
              path: 'upload-report',
              name: routes.imagesUploadReport,
              component: UploadReport
            },
            {
              path: 'report-moderated',
              name: routes.imagesReportModerated,
              component: reportModerated
            },
            {
              path: 'moderation/:type?',
              name: routes.imagesModeration,
              component: Images('ModerationView'),
              props: route =>
                route.params.type ? {type: route.params.type} : {}
            },
            {
              path: 'empty/:type?',
              name: routes.imagesEmpty,
              component: Images('NoImagesView'),
              props: route =>
                route.params.type ? {type: route.params.type} : {}
            },
            {
              path: 'bad/:type?',
              name: routes.imagesBad,
              component: Images('BadImagesView'),
              props: route =>
                route.params.type ? {type: route.params.type} : {}
            },
            {
              path: 'my/:type?/:status?',
              name: routes.imagesStatsMy,
              component: Images('StatsMyView'),
              props: route => {
                const props = {}
                if (route.params.type) {
                  props['type'] = route.params.type
                  if (route.params.status) {
                    props['status'] = route.params.status
                  }
                }
                return props
              }
            }
          ]
        }
      ]
    },
    {
      path: '/catalog/',
      component: StockWrapper,
      meta: {title: 'Каталог', titleIcon: 'mdi-library-books'},
      children: [
        {
          path: '',
          components: {
            default: Catalog('Wrapper'),
            menu: Catalog('NavigationMenu'),
            aside: Catalog('NavigationCatalog')
          },
          props: {default: true},
          children: [
            {
              path: '',
              name: routes.catalogIndex,
              component: Catalog('CatalogListView')
            },
            {
              path: ':catalogType',
              name: routes.catalogType,
              component: Catalog('CatalogListView')
            },
            {
              path: ':catalogType/:catalogId',
              name: routes.catalogDetail,
              component: Catalog('CatalogDetailView'),
              props: route => ({
                catalogId: Number(route.params.catalogId)
              })
            },
            {
              path: ':catalogType/product/:productId',
              name: routes.catalogProduct,
              component: Catalog('ProductDetailView'),
              props: route => ({
                productId: Number(route.params.productId)
              })
            },
            {
              path: ':catalogType/productOld/:productId',
              name: routes.catalogProduct,
              component: Catalog('ProductDetailViewOld'),
              props: route => ({
                productId: Number(route.params.productId)
              })
            }
          ]
        }
      ]
    },
    {
      path: '/aggregator/',
      component: Aggregator('Wrapper'),
      meta: {title: 'Агрегатор', titleIcon: 'mdi-bulldozer'},
      children: [
        {
          path: '',
          name: routes.aggregatorSelectDistributor,
          component: Aggregator('DistributorListView')
        },
        {
          path: 'distributor/:id/',
          component: Aggregator('StepWrapper'),
          props: route => ({
            id: Number(route.params.id)
          }),
          children: [
            {
              path: '',
              name: routes.aggregatorStepSelectCatalogs,
              component: Aggregator('SelectCatalogsView')
            },
            {
              path: 'brands',
              name: routes.aggregatorStepBindBrands,
              component: Aggregator('BindBrandsView')
            },
            {
              path: 'features',
              name: routes.aggregatorStepBindFeatures,
              component: Aggregator('BindFeaturesView')
            },
            {
              path: 'units',
              name: routes.aggregatorStepBindUnits,
              component: Aggregator('BindUnitsView')
            },
            {
              path: 'catalogs',
              name: routes.aggregatorStepBindCatalogs,
              component: Aggregator('BindCatalogsView')
            },
            {
              path: 'products',
              name: routes.aggregatorStepBindProducts,
              component: Aggregator('BindProductsView')
            }
          ]
        }
      ]
    },
    {
      path: '/settings/',
      name: routes.settingsPage,
      component: settingsPage,
      meta: {title: 'Настройки'},
    },

    {
      path: '*',
      name: routes.notfound,
      component: NotFoundView,
      meta: {title: ''}
    }
  ]
})
