import * as types from './types'

export default {
  /**
   * Задаем данные брендам поставщика
   * @param state
   * @param dataList
   */
  [types.DISTRIBUTOR_BRANDS_SET]: (state, dataList) => {
    state.distributor_brands = dataList
  },

  /**
   * Флаг получения брендов поставщика
   * @param state
   * @param value
   */
  [types.DISTRIBUTOR_BRANDS_FETCHING]: (state, value) => {
    state.distributor_brands_fetching = value
  },

  /**
   * Меняем данные бренда по id
   * @param state
   * @param id
   * @param data
   */
  [types.DISTRIBUTOR_BRANDS_UPDATE]: (state, { id, data }) => {
    state.distributor_brands = state.distributor_brands.map(oldItem =>
      id === oldItem.id ? { ...oldItem, ...data } : oldItem
    )
  },

  /**
   * Флаг обновления брендов поставщика
   * @param state
   * @param fetching
   */
  [types.DISTRIBUTOR_BRANDS_UPDATING]: (state, value) => {
    state.distributor_brands_updating = value
  },

  /**
   * Флаг получения кандидатов для привязки
   * @param state
   * @param value
   */
  [types.DISTRIBUTOR_BRANDS_BINDINGS_FETCHING]: (state, value) => {
    state.distributor_brands_bindings_fetching = value
  }
}
