import * as types from './types'

export default {
  /**
   * Установка выбранного типа каталога
   * @param state
   * @param activeCatalogType
   */
  [types.SET_ACTIVE_CATALOG_TYPE]: (state, activeCatalogType) => {
    state.active_catalog_type = activeCatalogType
  },

  /**
   * Установка флага расширенния левой панели
   * @param state
   */
  [types.SET_LEFT_PANEL_EXPANDED]: (state, value) => {
    state.left_panel_expanded = value
  },

  /**
   * Изменяет статус фильтра товаров, импортированных из внешних источников
   * @param state
   * @param to
   */
  [types.SET_FILTER_IMPORTED_GOODS]: (state, to) => {
    state.filter_imported_goods = to
  }
}
