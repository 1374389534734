<template>
  <a :href="getLinkUrl" target="_blank">
    <img
      :src="getPreviewUrl"
      style="width: 100%; max-width: 100%; height: auto"
    />
  </a>
</template>

<script>
import noPhotoImg from '@/assets/images/nophoto.png'

const mediaHost = process.env.VUE_APP_MEDIA_HOST

export default {
  name: 'VPopupImage',
  props: {
    aspectRatio: {
      default: 1
    },
    srcPreview: String,
    srcOriginal: String,
    contain: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: '800px'
    }
  },
  data: () => ({
    dialog: false
  }),
  computed: {
    getLinkUrl: function() {
      const src = this.srcOriginal || this.srcPreview || ''
      return src ? `${mediaHost}${src}` : noPhotoImg
    },
    getPreviewUrl: function() {
      const src = this.srcPreview || this.srcOriginal || ''
      return src ? `${mediaHost}${src}` : noPhotoImg
    }
  },
  methods: {
    openDialog: function() {
      if (this.srcOriginal) {
        this.dialog = true
      }
    },
    closeDialog: function() {
      this.dialog = false
    }
  }
}
</script>

<style lang="scss">
.dialog-content {
  position: relative;

  .close-button {
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;
  }
}
.preview {
  cursor: pointer;
}
</style>
