import api from '@/services/stockProductsApi'
import distributorApi from '@/services/distributorProductsApi'
import * as mutations from './mutations/types'
import {STOCK_PRODUCTS_ADD} from "./mutations/types";

export default {
    /**
     * Очистить данные по товарам
     * @param commit
     */
    actionClearStockProducts: ({commit}) => {
        commit(mutations.STOCK_PRODUCTS_SET, [])
    },

    /**
     * Очистка результатов поиска
     * @param commit
     */
    actionClearStockProductsSearch: ({commit}) => {
        commit(mutations.STOCK_PRODUCTS_SEARCH_SET, [])
    },

    /**
     * Запрос поиска
     * @param commit
     * @param q
     * @param items_per_page
     */
    actionSearchStockProducts: ({commit}, {q, items_per_page, distributor}) => {
        commit(mutations.STOCK_PRODUCTS_SEARCH_FETCHING, true)
        api
            .searchStockProducts(q, items_per_page, distributor)
            .then(dataList => {
                commit(mutations.STOCK_PRODUCTS_ADD, dataList)
                commit(
                    mutations.STOCK_PRODUCTS_SEARCH_SET,
                    dataList.map(item => item.id)
                )
            })
            .finally(() => {
                commit(mutations.STOCK_PRODUCTS_SEARCH_FETCHING, false)
            })
    },

    /**
     * Получение списка товаров
     * @param commit
     * @param params
     * @returns {*}
     */
    actionFetchStockProducts: ({commit}, params) => {
        commit(mutations.STOCK_PRODUCTS_FETCHING, true)
        return api
            .getStockProducts(params)
            .then(({count, dataList}) => {
                commit(mutations.STOCK_PRODUCTS_ADD, dataList)
                return {count, dataList}
            })
            .finally(() => {
                commit(mutations.STOCK_PRODUCTS_FETCHING, false)
            })
    },

    /**
     * Получаем товар из стора или делаем звапрос к апи
     * @param commit
     * @param getters
     * @param id
     * @returns {Promise<any>|*}
     */
    actionFetchStockProductById: ({commit, getters}, id) => {
        if (!id) return Promise.resolve(null)

        commit(mutations.STOCK_PRODUCTS_FETCHING, true)
        return api
            .getStockProductById(id)
            .then(data => {
                commit(mutations.STOCK_PRODUCTS_ADD, [data])
                return data
            })
            .finally(() => {
                commit(mutations.STOCK_PRODUCTS_FETCHING, false)
            })
    },
    /**
     * Получаем товар из стора или делаем звапрос к апи
     * @param id
     * @returns {Promise<any>|*}
     */
    actionFetchProductModeration: ({}, id) => {
        return api.getStockProductModerationById(id).then(data => data);
    },
    /**
     * Получение товара по id 1с
     * @param commit
     * @param id
     * @returns {*}
     */
    actionFetchStockProductById1C: ({commit}, id) => {
        commit(mutations.STOCK_PRODUCTS_FETCHING, true)
        return api
            .getStockProductById1C(id)
            .then(data => {
                commit(mutations.STOCK_PRODUCTS_ADD, [data])
                return data
            })
            .finally(() => {
                commit(mutations.STOCK_PRODUCTS_FETCHING, false)
            })
    },

    /**
     * Запрос кандидатов для привязки
     * Берем результат из стора или делаем запрос
     * @param commit
     * @param getters
     * @param id
     * @returns {Promise<void>|*}
     */
    actionFetchStockProductsSuggest: ({commit, getters}, id) => {
        if (getters.getStockProductsSuggest(id)) {
            return Promise.resolve()
        }
        commit(mutations.STOCK_PRODUCTS_SUGGEST_FETCHING, true)
        return distributorApi
            .getStockProductsSuggest(id)
            .then(dataList => {
                commit(mutations.STOCK_PRODUCTS_ADD, dataList)
                commit(mutations.STOCK_PRODUCTS_SUGGEST_SET, {
                    id,
                    idList: dataList.map(item => item.id)
                })
                return dataList
            })
            .finally(() => {
                commit(mutations.STOCK_PRODUCTS_SUGGEST_FETCHING, false)
            })
    },

    /**
     * Создание stock товара по id
     * @param commit
     * @param id
     * @returns {Promise<T>}
     */
    actionCreateDistributorProductInStock: ({commit}, id) => {
        commit(mutations.STOCK_PRODUCTS_CREATING, true)
        return distributorApi
            .createStockProduct(id)
            .then(data => {
                commit(mutations.STOCK_PRODUCTS_ADD, [data])
                return data
            })
            .finally(() => {
                commit(mutations.STOCK_PRODUCTS_CREATING, false)
            })
    },

    /**
     * Изменение stock товара
     * @param commit
     * @param id
     * @param data
     * @returns {Promise<T>}
     */
    actionPatchStockProduct: ({commit}, {id, data}) => {
        commit(mutations.STOCK_PRODUCTS_UPDATING, true)
        return api
            .patchStockProduct(id, data)
            .then(data => {
                commit(mutations.STOCK_PRODUCTS_ADD, [data])
                return data
            })
            .finally(() => {
                commit(mutations.STOCK_PRODUCTS_UPDATING, false)
            })
    },

    /**
     * Дубликат товара
     * @param commit
     * @param id
     * @param target_id
     * @param target_id_type
     * @returns {Q.Promise<any>}
     */
    actionDuplicateStockProduct: ({commit}, {id, target_id}) => {
        commit(mutations.STOCK_PRODUCTS_UPDATING, true)
        return api
            .duplicateStockProduct(id, target_id)
            .then(data => data)
            .finally(() => {
                commit(mutations.STOCK_PRODUCTS_UPDATING, false)
            })
    },

    /**
     * Создаёт товар в номенклатуре
     * @param commit
     * @param name
     * @param price
     * @param unit
     * @param brand
     */
    actionCreateStockProduct: (
        {commit},
        {
            name, category, structure, article, price1, unit, brand, direction, enabled = true,
            description = null, feature_values = [], created_from,
        }
    ) => {
        return api
            .createStockProduct({
                name, category, structure, article,
                price1, unit, brand, direction,
                enabled, description, feature_values, created_from,
            })
            .then(data => {
                commit(STOCK_PRODUCTS_ADD, [data])

                return data
            })
    },

    /**
     * Удаляет изображение у товара
     * @param commit
     * @param dispatch
     * @param image_id Идентификатор изображения
     * @return Promise<any>
     */
    actionDeleteStockProductImage({commit, dispatch}, image_id) {
        return api.deleteStockProductImage(image_id)
    },

    /**
     * Добавляет изображение к товару
     * @param product Идентификатор товара в агрегаторе
     * @param image Изображение
     * @return Promise<any>
     */
    actionAddStockProductImage({}, {product, image}) {
        return api.addStockProductImage(product, image)
    },

    /**
     * Задаёт коэффициенты к товару
     * @param commit
     * @param product_id
     * @param coefficients {{unit: number, coefficient: number}[]}
     * @return {*}
     */
    actionUpdateStockProductCoefficients({commit, getters, dispatch}, {product_id, coefficients = []}) {
        return api
            .setStockProductCoefficients(product_id, coefficients)
            .then(({data}) => {
                let product = getters.getStockProductById(product_id)

                if (product) {
                    product = JSON.parse(JSON.stringify(product))
                    product.units_list = data

                    commit(mutations.STOCK_PRODUCTS_ADD, [product])
                }

                return data
            })
    }
}
