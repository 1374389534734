export default {
  /**
   * Получение выбранного контрагента
   * @param active_distributor
   * @returns {*}
   */
  activeDistributor({ active_distributor }) {
    return active_distributor
  }
}
