import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  // Данные по параметрам контрагента
  distributor_features: [],
  // Флаг запроса всех параметров поставщика
  distributor_features_fetching: false,
  // Флаг обновления параметра поставщика
  distributor_features_updating: false,
  // Флаг получения кандидатов для привязки
  distributor_features_bindings_fetching: false
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
