export default {
  /**
   * Список найденных поставщиков
   * @param distributor_list_search
   * @returns {*}
   */
  distributorListSearch({ distributor_list_search }) {
    return distributor_list_search.map(item => ({
      text: item.name,
      value: item.id,
      id: item.id,
    }))
  },

  /**
   * Флаг поиска поставщиков
   * @param distributor_list_search_fetching
   * @returns {*}
   */
  distributorListSearchFetching({ distributor_list_search_fetching }) {
    return distributor_list_search_fetching
  },

  /**
   * Список поставщиков привязанных к парсерам (таблица)
   * @param distributor_list
   * @returns {*}
   */
  distributorListTable({ distributor_list }) {
    return distributor_list
  },

  /**
   * Список поставщиков привязанных к парсерам (выпадающий список)
   * @param distributor_list
   * @returns {*}
   */
  distributorListSelect({ distributor_list }) {
    return distributor_list.map(item => ({
      value: item.id,
      text: item.name
    }))
  },

  /**
   * Флаг получения списка поставщиков привязанных к парсерам
   * @param distributor_list_fetching
   * @returns {*}
   */
  distributorListFetching({ distributor_list_fetching }) {
    return distributor_list_fetching
  },

  /**
   * Получение поставщика по id
   * @param distributor_list
   * @returns {function(*): *}
   */
  getDistributorById({ distributor_list }) {
    return id => distributor_list.find(item => item.id == id)
  },

  /**
   * Флаг запроса привязки контрагента
   * @param distributor_adding
   * @returns {*}
   */
  distributorAdding({ distributor_adding }) {
    return distributor_adding
  },

  /**
   * Список id брендов поставщика
   * @param distributor_brands
   * @returns {*}
   */
  getDistributorAttachedBrands({ distributor_brands }) {
    return distributor_brands
  },

  /**
   * Список парсеров
   * @param parser_list
   * @returns {*}
   */
  parserList({ parser_list }) {
    return parser_list.map(item => ({
      text: item.name,
      value: item.module
    }))
  },

  /**
   * Флаг загрузки парсеров
   * @param parser_list_fetching
   * @returns {*}
   */
  parserListFetching({ parser_list_fetching }) {
    return parser_list_fetching
  }
}
