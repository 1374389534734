import * as mutations from './mutations/types'

export default {
  /**
   * Установка активного типа каталога
   * @param commit
   * @param activeCatlogType
   */
  actionSelectCatalogType: ({ commit }, activeCatlogType) => {
    commit(mutations.SET_ACTIVE_CATALOG_TYPE, activeCatlogType)
  },

  /**
   * Переключение флага расширенния левой панели
   * @param commit
   * @param state
   */
  actionSwitchLeftPanelExpansion: ({ commit, state }) => {
    commit(mutations.SET_LEFT_PANEL_EXPANDED, !state.left_panel_expanded)
  },

  /**
   * Изменяет состояние фильтра товаров,. импортированных из внешних источников
   * @param commit
   * @param to
   */
  actionToggleImportedGoods({ commit, state }, to= null) {
    commit(mutations.SET_FILTER_IMPORTED_GOODS, to === null ? !state.filter_imported_goods : to)
  }
}
