<template>
  <div class="wrapper">
    <v-overlay color="white" class="py-12" :value="isLoading" absolute>
      <v-progress-circular
        :size="30"
        color="indigo lighten-4"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <div class="subtitle-1 font-weight-light">
      Быстрый переход по коду 1с
    </div>
    <v-row>
      <v-col cols="5">
        <v-text-field
          v-model="code"
          @input="errorMessage = ''"
          label="Код"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="7">
        <v-select
          v-model="type"
          :items="types"
          @change="errorMessage = ''"
          label="Тип"
          hide-details
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="12">
        <v-btn
          color="primary"
          @click.stop="go"
          :disabled="disabled"
          block
          depressed
          small
        >
          Перейти
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-col>
      <v-col v-if="errorMessage" cols="12">
        <v-alert type="error" class="mb-0" dense text outlined>
          {{ errorMessage }}
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as catalogTypes from '@/common/catalogTypes'
import { routes } from '@/router'

const types = {
  product: 'product',
  category: 'category',
  tree: 'tree'
}

export default {
  data: () => ({
    code: null,
    type: null,
    types: [
      {
        text: 'Товар',
        value: types.product
      },
      {
        text: 'Категория',
        value: types.category
      },
      {
        text: 'Ветка ассортимента',
        value: types.tree
      }
    ],
    isLoading: false,
    errorMessage: ''
  }),
  computed: {
    ...mapGetters('stock_categories', ['getStockCategoryById1C']),
    ...mapGetters('stock_tree', ['getStockTreeById1C']),
    disabled: function() {
      return !this.code || !this.type || this.isLoading
    }
  },
  methods: {
    ...mapActions('stock_products', ['actionFetchStockProductById1C']),
    getItem: function() {
      switch (this.type) {
        case types.product:
          return this.actionFetchStockProductById1C(this.code)
        case types.category:
          return Promise.resolve(this.getStockCategoryById1C(this.code))
        case types.tree:
          return Promise.resolve(this.getStockTreeById1C(this.code))
      }
      return Promise.resolve(null)
    },
    getRouteParams: function(id) {
      switch (this.type) {
        case types.product:
          return {
            name: routes.catalogProduct,
            params: {
              catalogType: catalogTypes.structure,
              productId: id
            }
          }
        case types.category:
          return {
            name: routes.catalogDetail,
            params: {
              catalogType: catalogTypes.structure,
              catalogId: id
            }
          }
        case types.tree:
          return {
            name: routes.catalogDetail,
            params: { catalogType: catalogTypes.category, catalogId: id }
          }
      }
    },
    go: function() {
      this.isLoading = true
      this.getItem()
        .then(item => {
          if (item && item.id) {
            const params = this.getRouteParams(item.id)
            this.$router.push(params).catch(() => {})
            this.clear()
          } else {
            this.errorMessage = 'Элемент не найден'
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    clear: function() {
      this.code = null
      this.type = null
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  position: relative;
}
</style>
