import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  // Данные по веткам ассортимента
  stock_tree: [],
  // Флаг запроса данных
  stock_tree_fetching: false,
  // Флаг добавления
  stock_tree_adding: false,
  // Флаг обновления
  stock_tree_updating: false,
  // Активные элементы для выбранного поставщика
  stock_tree_selected: {
    default: [],
    changed: null
  },
  stock_tree_selected_fetching: false,
  stock_tree_selected_updating: false
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
