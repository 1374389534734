import * as types from './types'

export default {
  /**
   * Установка выбранного контрагента
   * @param state
   * @param activeDistributor
   */
  [types.SET_ACTIVE_DISTRIBUTOR]: (state, activeDistributor) => {
    state.active_distributor = activeDistributor
  },

  /**
   * Установка счётчиков непривязанного у выбранного в интерфейсе контрагента
   * @param state
   * @param unbinded
   */
  [types.SET_ACTIVE_DISTRIBUTOR_UNBINDED_COUNTERS]: (state, unbinded) => {
    state.active_distributor_unbind_counters = unbinded || {
      products: null,
      units: null,
      features: null,
      brands: null
    }
  },
  /**
   * Устанавливаем список фоновых задач
   * @param state
   * @param list массив объектов задач
   */
  [types.SET_BACKGROUND_TASKS]: (state, list) => {
    state.background_tasks = list
  },

  /**
   * Устанавливается флаг состояния обновления списка фоновых задач
   * @param state
   * @param is_fetching Да/Нет, по умолчанию - да.
   */
  [types.SET_BACKGROUND_TASKS_FETCHING]: (state, is_fetching = true) => {
    state.background_tasks_fetching = is_fetching
  }
}
