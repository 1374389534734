import api from '@/services/imagesApi'
import * as mutations from './mutations/types'
import {SET_NO_IMAGES_FILTER, USER_PAYMENTS_FETCHING} from "./mutations/types";

export default {
  /**
   * Загрузка изображений
   * @param commit
   * @param type
   * @param id
   * @param file
   * @returns {Q.Promise<T>}
   */
  actionUploadImage: ({commit}, {type, id, file}) => {
    commit(mutations.UPLOADING, true)
    return api.uploadImage(type, id, file).finally(() => {
      commit(mutations.UPLOADING, false)
    })
  },

  /**
   * Сброс списка изображений
   * @param commit
   */
  actionClearImagesModeration: ({commit}) => {
    commit(mutations.IMAGES_MODERATION_COUNT, 0)
    commit(mutations.IMAGES_MODERATION_SET, [])
  },

  /**
   * Получение элементов на модерацию
   * @param commit
   * @param type
   * @param options
   */
  actionFetchImagesModeration: ({commit}, {type, ...options}) => {
    commit(mutations.IMAGES_MODERATION_FETCHING, true)
    api
      .getModerate(type, options)
      .then(({count, results}) => {
        commit(mutations.IMAGES_MODERATION_COUNT, count)
        commit(mutations.IMAGES_MODERATION_SET, results)

        return results
      })
      .finally(() => {
        commit(mutations.IMAGES_MODERATION_FETCHING, false)
      })
  },

  /**
   * Очистка данных пустых фото
   * @param commit
   */
  actionClearImagesEmpty: ({commit}) => {
    commit(mutations.IMAGES_EMPTY_COUNT, 0)
    commit(mutations.IMAGES_EMPTY_SET, [])
  },

  /**
   * Получение элементов без изображений
   * @param commit
   * @param type
   * @param page
   * @param itemsPerPage
   * @param brands
   */
  actionFetchImagesEmpty: ({commit}, {type, page, itemsPerPage, ...filters} ) => {
    commit(mutations.IMAGES_EMPTY_FETCHING, true)
    api
      .getEmpty(type, {
        page,
        itemsPerPage,
        ...filters
      })
      .then(({count, results}) => {
        commit(mutations.IMAGES_EMPTY_COUNT, count)
        commit(mutations.IMAGES_EMPTY_SET, results)
      })
      .finally(() => {
        commit(mutations.IMAGES_EMPTY_FETCHING, false)
      })
  },

  /**
   * Очистка данных некачественных фото
   * @param commit
   */
  actionClearImagesBad: ({commit}) => {
    commit(mutations.IMAGES_BAD_COUNT, 0)
    commit(mutations.IMAGES_BAD_SET, [])
  },

  /**
   * Получение некачественных фото
   * @param commit
   * @param type
   * @param options
   */
  actionFetchImagesBad: ({commit}, {type, ...options}) => {
    commit(mutations.IMAGES_BAD_FETCHING, true)
    api
      .getBad(type, options)
      .then(({count, results}) => {
        commit(mutations.IMAGES_BAD_COUNT, count)
        commit(mutations.IMAGES_BAD_SET, results)
      })
      .finally(() => {
        commit(mutations.IMAGES_BAD_FETCHING, false)
      })
  },

  /**
   * Очистка статистика загрузок
   * @param commit
   */
  actionClearUploadStats: ({commit}) => {
    commit(mutations.UPLOAD_STATS, [])
  },

  /**
   * Статистика загрузок
   * @param commit
   */
  actionFetchUploadStats: ({commit}) => {
    commit(mutations.UPLOAD_STATS_FETCHING, true)
    api
      .getUploadStats()
      .then(dataList => {
        commit(mutations.UPLOAD_STATS, dataList)
      })
      .finally(() => {
        commit(mutations.UPLOAD_STATS_FETCHING, false)
      })
  },
  /**
   * Сохранение платежа на загруженные картинки
   * @param userForId
   * @param moderatedById
   * @param countModerated
   * @param statisticId
   * @param commit
   */
  actionSavePaymentStatistic: ({commit}, {userForId, moderatedById, countModerated}) => {
    api
      .savePaymentStatistic(userForId, moderatedById, countModerated)
  },

  /**
   * Очистка статистики проблемных изображений
   * @param commit
   */
  actionClearProblemsStats: ({commit}) => {
    commit(mutations.PROBLEMS_STATS, [])
  },

  /**
   * Статистика проблемных картинок
   * @param commit
   * @param fromDate
   * @param toDate
   */
  actionFetchProblemsStats: ({commit}, {fromDate, toDate}) => {
    commit(mutations.PROBLEMS_STATS_FETCHING, true)
    api
      .getProblemsStats(fromDate, toDate)
      .then(dataList => {
        commit(mutations.PROBLEMS_STATS, dataList)
      })
      .finally(() => {
        commit(mutations.PROBLEMS_STATS_FETCHING, false)
      })
  },
  /**
   * Получение статистики выплат по пользователю
   * @param commit
   * @param userId
   */
  actionFetchUserPayments: async ({commit}, {userId}) => {
    commit(mutations.USER_PAYMENTS_FETCHING, true)
    await api
      .fetchPaymentStatistic(userId)
      .then(dataList => {
        commit(mutations.USER_PAYMENTS, {data: dataList, userId})
      })
      .finally(() => {
        commit(mutations.USER_PAYMENTS_FETCHING, false)
      })
  },
  /**
   * Установка фильтров
   * @param commit
   * @param filters
   */
  actionSetNoImagesFilter: async ({commit}, filters) => {
    commit(mutations.SET_NO_IMAGES_FILTER, filters)
  },
  /**
   * Получение статистики выплат по пользователю
   * @param commit
   */
  actionIncrementApplyFilter: async ({commit}) => {
    commit(mutations.INCREMENT_APPLY_FILTER)
  }
}
