<script>
import * as routes from "../../router/routes";

export default {
  name: 'UploadReport',
  computed: {
    routes() {
      return routes
    }
  }
}
</script>

<template>
<div>
  <v-row>
    <v-col cols="2">
      <router-link :to="{name: routes.imagesReportModerated}">
        Заливка и модерация по ответственным
      </router-link>
    </v-col>
  </v-row>
</div>
</template>

<style scoped lang="scss">

</style>