import * as types from './types'

export default {
  /**
   * Установка текущего пользователя
   * @param state
   * @param data
   */
  [types.AUTH_CURRENT_USER_SET]: (state, data) => {
    state.current_user = data
  },

  /**
   * Флаг получения текущего пользователя
   * @param state
   * @param value
   */
  [types.AUTH_CURRENT_USER_FETCHING]: (state, value) => {
    state.current_user_fetching = value
  },

  /**
   * Флаг запроса авторизации
   * @param state
   * @param value
   */
  [types.AUTH_LOGIN_IN_PROGRESS]: (state, value) => {
    state.login_in_progress = value
  },

  /**
   * Флаг запроса восстановления пароля
   * @param state
   * @param value
   */
  [types.AUTH_PASSWORD_RECOVERY_IN_PROGRESS]: (state, value) => {
    state.password_recovery_in_progress = value
  },

  /**
   * Флаг проверки авторизации
   * @param state
   * @param value
   */
  [types.AUTH_CHECKED]: (state, value) => {
    state.auth_checked = value
  },

  /**
   * Список пользователей
   * @param state
   * @param dataList
   */
  [types.AUTH_USERS_SET]: (state, dataList) => {
    state.users = dataList
  },

  /**
   * Флаг получения пользователей
   * @param state
   * @param value
   */
  [types.AUTH_USERS_FETCHING]: (state, value) => {
    state.users_fetching = value
  }
}
