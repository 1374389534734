import * as types from './types'

export default {
  /**
   * Задаем список параметров
   * @param state
   * @param dataList
   */
  [types.STOCK_FEATURES_SET]: (state, dataList) => {
    state.stock_features = dataList
  },

  /**
   * Флаг получения параметров
   * @param state
   * @param value
   */
  [types.STOCK_FEATURES_FETCHING]: (state, value) => {
    state.stock_features_fetching = value
  },

  /**
   * Добавление элемента
   * @param state
   * @param dataItem
   */
  [types.STOCK_FEATURES_ADD]: (state, dataItem) => {
    state.stock_features = [...state.stock_features, dataItem]
    let parentItem = state.stock_features.find(
      item => item.id === dataItem.parent
    )

    if (parentItem) {
      if (!parentItem.children)
        parentItem.children = []

      parentItem.children = [...parentItem.children, dataItem]
    }
  },

  /**
   * Флаг создания параметра
   * @param state
   * @param value
   */
  [types.STOCK_FEATURES_ADDING]: (state, value) => {
    state.stock_features_adding = value
  }
}
