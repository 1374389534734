export default {
  /**
   * Получение выбранного контрагента
   * @param current_user
   * @returns {*}
   */
  currentUser({ current_user }) {
    return current_user
  },

  /**
   * Флаг запроса
   * @param current_user_fetching
   * @returns {*}
   */
  getCurrentUserFetching({ current_user_fetching }) {
    return current_user_fetching
  },

  /**
   * Права текущего пользователя
   * @param state
   * @param getters
   * @returns {Array}
   */
  getCurrentUserPermissions(state, getters) {
    return getters.currentUser && getters.currentUser.permissions
      ? getters.currentUser.permissions
      : []
  },

  /**
   * Проверка наличия прав
   * @param state
   * @param getters
   * @returns {function(*): boolean}
   */
  getCurrentUserHasPermission(state, getters) {
    return permission => {
      return getters.getCurrentUserPermissions.some(item => item == permission)
    }
  },

  /**
   * Флаг выполнения запроса авторизации
   * @param login_in_progress
   * @returns {*}
   */
  getLoginInProgress({ login_in_progress }) {
    return login_in_progress
  },

  /**
   * Флаг выполнения запроса восстановления пароля
   * @param password_recovery_in_progress
   * @returns {*}
   */
  getRecoveryPasswordInProgress({ password_recovery_in_progress }) {
    return password_recovery_in_progress
  },

  /**
   * Фла проверки авторизации
   * @param auth_checked
   * @returns {*}
   */
  getAuthChecked({ auth_checked }) {
    return auth_checked
  },

  /**
   * Список пользователей
   * @param users
   * @returns {*}
   */
  getUsers({ users }) {
    return users
  },

  /**
   * Флаг получения пользователей
   * @param users_fetching
   * @returns {*}
   */
  getUsersFetching({ users_fetching }) {
    return users_fetching
  }
}
