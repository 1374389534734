import _ from 'lodash'

/**
 * Строим дерево
 * @param itemsList
 * @param sort
 * @param filter
 * @returns {ReadonlyArray<any>[]}
 */
export const buildTree = (itemsList, sort = null, filter = null) => {
  const itemsById = itemsList.reduce((accumulator, item) => {
    accumulator[item.id] = { ...item }
    return accumulator
  }, {})
  let itemsTree = Object.values(itemsById)
  if (filter) {
    itemsTree = _.uniq(
      itemsTree.filter(filter).reduce((accum, item) => {
        accum.push(item)
        while (item.parent) {
          item = itemsById[item.parent]
          accum.push(item)
        }
        return accum
      }, [])
    )
  }
  if (sort) {
    itemsTree = _.sortBy(itemsTree, sort)
  }
  itemsTree.forEach(item => {
    if (item.parent) {
      const parent = itemsById[item.parent]
      if (parent) {
        if (!parent.children) {
          parent.children = []
        }
        parent.children.push(item)
        item.parentItem = parent
      } else {
        // Обнуляем родителя, если не смогли его найти, возможно он неактивен
        item.parent = null
      }
    }
  })
  return itemsTree.map(item => Object.freeze(item))
}

/**
 * Получение всех детей элемента
 * @param item
 * @returns {[]}
 */
export const getChildsRecursive = item => {
  let childs = []
  if (item.children) {
    item.children.forEach(child => {
      childs.push(child.id)
      childs = childs.concat(getChildsRecursive(child))
    })
  }
  return childs
}
