import api from '@/services/stockTreeApi'
import * as mutations from './mutations/types'

export default {
    /**
     * Очистить ветки ассортимента
     * @param commit
     */
    actionClearStockTree: ({commit}) => {
        commit(mutations.STOCK_TREE_SET, [])
    },

    /**
     * Запрос всех веток ассортимента
     * @param commit
     * @param state
     */
    actionFetchStockTree: ({commit, state}) => {
        if (!state.stock_tree.length) {
            commit(mutations.STOCK_TREE_FETCHING, true)
            Promise.allSettled([
                api.getStockTree(),
                api.getStockTreeIdsWithImportedGoods()
            ])
                .then(results => {
                    let [categories, with_imported_goods] = results.map(i => i.value)

                    categories = categories.map(c => {
                        c.with_imported_goods = with_imported_goods.indexOf(c.id) !== -1

                        if (c.with_imported_goods) {
                            let target = c

                            while (target.parent) {
                                target = categories.filter(_ => _.id === target.parent)[0]
                                target.child_with_imported_goods = true
                            }
                        }

                        return c
                    })

                    commit(mutations.STOCK_TREE_SET, categories)
                })
                .finally(() => commit(mutations.STOCK_TREE_FETCHING, false))
        }
    },

    /**
     * Получение ветки ассортимента по id
     * @param commit
     * @param id
     * @returns {Q.Promise<any>}
     */
    actionFetchStockTreeById: ({commit}, id) => {
        return api.getStockTreeById(id).then(data => {
            commit(mutations.STOCK_TREE_UPDATE, {id, data})
            return data
        })
    },

    /**
     * Создание новой категории
     * @param commit
     * @param name
     * @param parent
     * @returns {Promise<T>}
     */
    actionAddStockTree: ({commit}, {name, parent}) => {
        commit(mutations.STOCK_TREE_ADDING, true)
        return api
            .addStockTree(name, parent)
            .then(dataItem => {
                commit(mutations.STOCK_TREE_ADD, dataItem)
                return dataItem
            })
            .finally(() => {
                commit(mutations.STOCK_TREE_ADDING, false)
            })
    },

    /**
     * Обновление элемента
     * @param commit
     * @param id
     * @param data
     * @returns {Promise<T>}
     */
    actionPatchStockTree: ({commit}, {id, data}) => {
        commit(mutations.STOCK_TREE_UPDATING, true)
        return api
            .patchStockTree(id, data)
            .then(data => {
                commit(mutations.STOCK_TREE_UPDATE, {id, data})
                return data
            })
            .finally(() => {
                commit(mutations.STOCK_TREE_UPDATING, false)
            })
    },

    /**
     * Получение списка выбранных элементов
     * @param commit
     * @param distributorId
     * @returns {Q.Promise<any>}
     */
    actionFetchSelectedStockTree: ({commit}, distributorId) => {
        commit(mutations.STOCK_TREE_SELECTED_FETCHING, true)
        return api
            .getSelectedStockTree(distributorId)
            .then(dataList => {
                commit(mutations.STOCK_TREE_SELECTED_SET, {default: dataList})
                return dataList
            })
            .finally(() => {
                commit(mutations.STOCK_TREE_SELECTED_FETCHING, false)
            })
    },

    /**
     * Обновление списка выбранных элементов
     * @param commit
     * @param distributorId
     * @param toggleOn
     * @param toggleOff
     * @returns {Q.Promise<any>}
     */
    actionUpdateSelectedStockTree: (
        {commit},
        {distributorId, toggleOn, toggleOff}
    ) => {
        commit(mutations.STOCK_TREE_SELECTED_UPDATING, true)
        return api
            .updateSelectedStockTree(distributorId, toggleOn, toggleOff)
            .finally(() => {
                commit(mutations.STOCK_TREE_SELECTED_UPDATING, false)
            })
    },

    /**
     * Установка выбранных элементов
     * @param commit
     * @param data
     */
    actionSetStockTreeSelected: ({commit}, data) => {
        commit(mutations.STOCK_TREE_SELECTED_SET, data)
    }
}
