import { buildTree, getChildsRecursive } from '../../common'

export default {
  /**
   * Получение всех веток ассортимента
   * @param stock_tree
   * @param stock_tree_selected
   * @returns {*}
   */
  getStockTreeItems({ stock_tree }, getters) {
    const selectedItems =
      getters.getStockTreeSelected.changed ||
      getters.getStockTreeSelected.default
    return stock_tree.map(item => ({
      ...item,
      selected: selectedItems.includes(item.id)
    }))
  },

  /**
   * Дерево веток ассортимента
   * @param state
   * @param getters
   * @returns {ReadonlyArray<any>[]}
   */
  getStockTreeItemsTree(state, getters) {
    return buildTree(getters.getStockTreeItems, [item => !item.enabled, 'name'])
  },

  /**
   * Получение корневых категорий отфильтрованного дерева
   * @param state
   * @param getters
   * @returns {function(*=)}
   */
  getFilteredStockRootTrees(state, getters) {
    return filter => {
      return buildTree(
        getters.getStockCategories,
        [item => !item.enabled, 'name'],
        filter
      ).filter(item => !item.parent)
    }
  },


  /**
   * Получение корневых веток ассортимента
   * @param state
   * @param getters
   * @returns {*}
   */
  getStockRootTree(state, getters) {
    return getters.getStockTreeItemsTree.filter(item => !item.parent)
  },

  /**
   * Флаг получения веток ассортимента
   * @param stock_tree_fetching
   * @returns boolean
   */
  getStockTreeFetching({ stock_tree_fetching }) {
    return stock_tree_fetching
  },

  /**
   * Флаг добавления веток ассортимента
   * @param stock_tree_adding
   * @returns {*}
   */
  getStockTreeAdding({ stock_tree_adding }) {
    return stock_tree_adding
  },

  /**
   * Получение элемента по id
   * @param state
   * @param getters
   * @returns {function(*): *}
   */
  getStockTreeById(state, getters) {
    return id => getters.getStockTreeItemsTree.find(item => item.id === id)
  },

  /**
   * Получение элемента по id 1с
   * @param state
   * @param getters
   * @returns {function(*=): *}
   */
  getStockTreeById1C(state, getters) {
    return id => {
      const id1c = String(id)
      return getters.getStockTreeItemsTree.find(item => item._1c_id === id1c)
    }
  },

  /**
   * Получение элементов по списку id
   * @param state
   * @param getters
   * @returns {function(*): *}
   */
  getStockTreeByIdList(state, getters) {
    return idList => {
      return idList.length
        ? getters.getStockTreeItemsTree.filter(item => idList.includes(item.id))
        : []
    }
  },

  /**
   * Получение списка предков
   * @param state
   * @param getters
   * @returns {function(*): []}
   */
  getStockTreeAncestorsById(state, getters) {
    return id => {
      let item = getters.getStockTreeById(id)
      const ancestorsList = [item]
      let hasAncestor = !!item.parentItem
      while (hasAncestor) {
        item = item.parentItem
        ancestorsList.push(item)
        hasAncestor = !!item.parentItem
      }
      return ancestorsList
    }
  },

  /**
   * Получение списка id дочерних категорий
   * @param state
   * @param getters
   * @returns {function(*): []}
   */
  getStockTreeChildsIdRecursive(state, getters) {
    return id => {
      return getChildsRecursive(getters.getStockTreeById(id))
    }
  },

  /**
   * Флаг обновления
   * @param stock_tree_updating
   * @returns {*}
   */
  getStockTreeUpdating({ stock_tree_updating }) {
    return stock_tree_updating
  },

  /**
   * Список id выбранных элементов
   * @param stock_tree_selected
   * @returns {*}
   */
  getStockTreeSelected({ stock_tree_selected }) {
    return stock_tree_selected
  },

  /**
   * Флаг обновления списка выбраных ветвей ассортимента
   */
  getStockTreeSelectedFetching({stock_tree_selected_fetching}) {
    return stock_tree_selected_fetching
  }

}
