import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  // Данные по категориям
  stock_categories: [],
  // Флаг запроса данных
  stock_categories_fetching: false,
  // Флаг добавления
  stock_categories_adding: false,
  // Флаг обновления
  stock_categories_updating: false,
  // Активные элементы для выбранного поставщика
  stock_categories_selected: {
    default: [],
    changed: null
  },
  stock_categories_selected_fetching: false,
  stock_categories_selected_updating: false,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
