import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  // Данные по каталогу контрагента
  distributor_categories: [],
  // Кандидаты на привязку, список id-ков по id категории поставщика
  distributor_categories_suggest: {},
  // Флаг запроса данных по категориям поставщика
  distributor_categories_fetching: false,
  // Флаг обновления категорий поставщика
  distributor_categories_updating: false,
  // Флаг получения кандидатов для привязки
  distributor_categories_bindings_fetching: false,
  // Список выбранных элементов при выборе категорий поставщика
  distributor_categories_selected: {
    default: [],
    changed: null
  },
  distributor_categories_selected_fetching: false
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
