import * as types from './types'

export default {
  /**
   * Задаем данные брендам
   * @param state
   * @param dataList
   */
  [types.STOCK_BRANDS_SET]: (state, dataList) => {
    state.stock_brands = dataList
  },

  /**
   * Задаем данные по категориям брендов
   * @param state
   * @param dataList
   */
  [types.STOCK_BRANDS_CATEGORIES_SET]: (state, dataList) => {
    state.stock_brands_categories = dataList
  },

  /**
   * Флаг получения брендов
   * @param state
   * @param value
   */
  [types.STOCK_BRANDS_FETCHING]: (state, value) => {
    state.stock_brands_fetching = value
  },

  /**
   * Обновление бренда
   * @param state
   * @param id
   * @param data
   */
  [types.STOCK_BRANDS_UPDATE]: (state, { id, data }) => {
    state.stock_brands = state.stock_brands.map(item => item.id === id ? { ...item, ...data } : item)
  },

  [types.STOCK_BRANDS_ADD]: (state, brand) => {
    state.stock_brands.push(brand)
  }
}
