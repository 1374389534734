import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  active_catalog_type: null,
  left_panel_expanded: false,

  // Выборка среза каталога по тем товарам, которые были созданны вручную
  filter_imported_goods: false,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
