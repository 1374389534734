import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  // Данные по юнитам контрагента
  stock_units: [],
  // Флаг запроса всех юнитов поставщика
  stock_units_fetching: false
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
