<template>
  <v-sticky-actions :top="top">
    <div class="text-right py-4">
      <v-btn
          @click.stop="actionSwitchLeftPanelExpansion"
          color="indigo lighten-1"
          dark
          small
          depressed
      >
        {{ buttonText }}
        <v-icon v-text="buttonIcon"/>
      </v-btn>
    </div>
  </v-sticky-actions>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'expandMenu',
  props: {
    top: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('app_catalog', ['getLeftPanelExpanded']),
    // Кнопка расширения левой колонки
    buttonText() {
      return this.getLeftPanelExpanded ? 'Сжать панель' : 'Расширить панель'
    },
    buttonIcon: function () {
      return this.getLeftPanelExpanded
          ? 'mdi-arrow-left-bold-box-outline'
          : 'mdi-arrow-right-bold-box-outline'
    },
  },
  methods: {
    ...mapActions('app_catalog', ['actionSwitchLeftPanelExpansion']),
  },
}
</script>
