<template>
  <v-img
    :aspect-ratio="aspectRatio"
    :src="srcImage"
    :contain="contain"
    @error="onError"
    @click="$emit('click', $event)"
  >
    <template #placeholder>
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular
          indeterminate
          color="grey lighten-1"
        ></v-progress-circular>
      </v-row>
    </template>
  </v-img>
</template>

<script>
import noPhotoImg from '@/assets/images/nophoto.png'

const mediaHost = process.env.VUE_APP_MEDIA_HOST

export default {
  name: 'VCatalogImg',
  props: {
    aspectRatio: {
      default: undefined
    },
    src: {
      type: String,
      default: ''
    },
    contain: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    failure: false
  }),
  computed: {
    srcImage: function() {
      return !this.failure && this.src ? `${mediaHost}${this.src}` : noPhotoImg
    }
  },
  methods: {
    onError: function() {
      this.failure = true
    }
  }
}
</script>
