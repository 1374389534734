<template>
  <div>
    <v-treeview
        :items="items"
        v-model="selection"
        class="py-3"
        :search="search"
        selectable
        return-object
        dense
    >
    </v-treeview>
  </div>

</template>
<script>

export default {
  name: 'SelectTreeView',
  props: {
    items: Array,
    search: String,
  },
  emits: {
    'update:modelValue': null
  },
  data() {
    return {
      selection: []
    }
  },
  computed: {
    _items() {
      const replaceChildren = (obj, parent) => {
        const clone = Object.assign({}, obj)
        delete clone.children
        if (parent) clone.parent = parent
        return clone
      }

      const addItems = (arr, parent) => {
        const items = arr.reduce((acc, x) => {

          acc.push(replaceChildren(x, parent))

          if (x.children) {
            acc.push(addItems(x.children, x.id))
          }
          return acc
        }, [])

        return items.flat()
      }

      return addItems(this.items).reduce((acc, x) => {
        acc[x.id] = x
        return acc
      }, {})
    },
    _selection() {
      const proxy = {}
      const addParents = (x, all) => {
        const parentId = this._items[x.id].parent
        if (parentId) {
          if (all) addParents(this._items[parentId])
          proxy[parentId] = this._items[parentId]
        }
      }
      this.selection.forEach(x => {
        addParents(x, false)
        proxy[x.id] = x
      })
      return Object.values(proxy)
    }
  },
  watch: {
    selection() {
      this.$emit('update:modelValue', this._selection)
    }
  }

}

</script>
<style scoped lang="scss">

</style>