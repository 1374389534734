<template>
  <div class="notify-list">
    <NotifyItem
      v-for="item in items"
      :key="item.id"
      :type="item.type"
      @close="onClose(item.id)"
      class="mb-2"
    >
      {{ item.text }}
    </NotifyItem>
  </div>
</template>

<script>
import NotifyItem from './NotifyItem'

export default {
  name: 'NotifyList',
  components: { NotifyItem },
  props: {
    items: Array,
    onClose: Function
  }
}
</script>

<style lang="scss">
.notify-list {
  position: fixed;
  right: 0;
  top: 0;
  padding: 1rem;
  z-index: 1000;
  width: 100%;
  max-width: 500px;
}
</style>
