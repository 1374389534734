<script>

import {mapActions} from "vuex";

export default {
  name: 'reportModerated',
  data() {
    return {
      report: [],
      headers: [
        {
          align: 'start',
          value: 'uploaded_at',
          text: 'Дата заливки',
        },
        {value: 'uploaded_id', text: 'Ответственный (кто залил)'},
        {value: 'moderated_id', text: 'Модератор'},
        {value: 'product_id', text: 'Код товара'},
        {value: 'structure_id', text: 'Товарная группа'},
        {value: 'status', text: 'Статус'},
      ],
      isOpenDateRange: false,
      uploadedUsers: [],
      moderatedUsers: [],
      filters: {
        uploaded_at: null,
        uploaded_by: null,
        moderated_by: null,

      }
    }
  },
  mounted() {
    this.getReports()
  },
  computed: {
    filteredReport() {
      let uploaded_at_start = null;
      let uploaded_at_end = null;
      if (this.filters.uploaded_at?.length === 2) {
        uploaded_at_start = new Date(`${this.filters.uploaded_at[0]}T00:00:00`).getTime();
        uploaded_at_end = new Date(`${this.filters.uploaded_at[1]}T23:59:59`).getTime();
      }
      return this.report.filter(item => {
        let isFiltered = true;
        if (this.filters.uploaded_at?.length === 2) {
          const itemDate = new Date(item.uploaded_at).getTime();
          isFiltered = isFiltered && (itemDate > uploaded_at_start && itemDate <= uploaded_at_end);
        }
        if (this.filters.uploaded_by) {
          isFiltered = isFiltered && (item.uploaded_id === this.filters.uploaded_by);
        }
        if (this.filters.moderated_by) {
          isFiltered = isFiltered && (item.moderated_id === this.filters.moderated_by);
        }
        return isFiltered
      })
    },
    dateRangeText: {
      get() {
        if (this.filters.uploaded_at) {
          return this.filters.uploaded_at.join(' ~ ')
        }
        return null;
      },
      set(value) {
        this.filters.uploaded_at = value;
      }
    }
  },
  methods: {
    async getReports() {
      const imagesApi = require('../../services/imagesApi').default;
      const response = await imagesApi.getReportModerated();
      this.report = response.reports;
      this.uploadedUsers = response.uploaded_users;
      this.moderatedUsers = response.moderated_users;
    },
    getUserName(userId, userList) {
      const foundUser = userList.find(user => user.id === userId);
      if (!foundUser) return null;
      if (foundUser.last_name || foundUser.first_name) {
        return `${foundUser.last_name} ${foundUser.first_name}`
      }
      return foundUser.username
    }
  }
}
</script>

<template>
  <v-row>
    <v-col cols="12">
      <v-card v-if="report.length" class="pa-4">
        <h4>Фильтры</h4>
        <v-row>
          <v-col cols="3">
            <v-menu
                ref="menu"
                v-model="isOpenDateRange"
                :close-on-content-click="false"
                :return-value.sync="filters.uploaded_at"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateRangeText"
                    label="Время заливки (с - по)"
                    prepend-icon="mdi-calendar"
                    :clearable="true"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="filters.uploaded_at"
                  range
              >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="isOpenDateRange = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(filters.uploaded_at)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>


          </v-col>
          <v-col cols="3">
            <v-select
                v-model="filters.uploaded_by"
                :items="uploadedUsers"
                item-value="id"
                :item-text="item => getUserName(item.id, uploadedUsers)"
                clearable
                label="Ответственный (кто залил)"
            >
              <template #item="{item}">
                {{ getUserName(item.id, uploadedUsers) }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="3">
            <v-select
                v-model="filters.moderated_by"
                :items="moderatedUsers"
                clearable
                :item-text="item => getUserName(item.id, moderatedUsers)"
                item-value="id"
                label="Модератор"
            >
              <template #item="{item}">
                {{ getUserName(item.id, moderatedUsers) }}
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="filteredReport"
            :items-per-page="50"
        >
          <template #item.uploaded_at="{ item }">
            {{ new Date(item.uploaded_at).toLocaleString() }}
          </template>
          <template #item.uploaded_id="{ item }">
            {{ getUserName(item.uploaded_id, uploadedUsers) }}
          </template>
          <template #item.moderated_id="{ item }">
            {{ getUserName(item.moderated_id, moderatedUsers) }}
          </template>
          <template #item.status="{ item }">
            <v-icon v-if="item.status === 'accepted'" color="green">mdi-checkbox-marked</v-icon>
            <v-icon v-else-if="item.status === 'rejected'" color="red">mdi-close</v-icon>
            <v-icon v-else>mdi-help</v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>

</template>

<style scoped lang="scss">

</style>