<template>
  <div v-if="isShow" class="aside-content">
    <div class="brand-filter">
      <v-list
          v-if="stock_brands.length && distributorListSearch.length && getStockRootCategories.length && getStockRootTree.length">
        <v-list-group
            v-model="isOpenBrandFilter"
            no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="'Фильтр по брендам'"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-text-field
              label="Фильтр по брендам"
              v-model="filterBrandString"
              placeholder="Фильтр по брендам"
              solo
          ></v-text-field>
          <v-virtual-scroll
              height="300"
              :items="filteredBrands"
              item-height="30"
          >
            <template #default="{item: brand}">
              <v-list-item
                  :key="brand.name"
              >
                <v-list-item-content>
                  <div class="brad-filter__item">
                    <v-checkbox
                        class="ma-0 pa-0"
                        :value="brand.id"
                        v-model="selectedBrand"
                        :label="brand.name"
                        hide-details
                    ></v-checkbox>
                  </div>
                </v-list-item-content>
              </v-list-item>

            </template>
          </v-virtual-scroll>

        </v-list-group>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="'Фильтр по ветви ассортимента'"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-text-field
              label="Фильтр по ветви ассортимента"
              v-model="filterCategoryString"
              placeholder="Фильтр по ветви ассортимента"
              solo
          ></v-text-field>
          <SelectTreeView
              :items="getStockRootTree"
              :search="filterCategoryString"
              @update:modelValue="newValue => selectedCategories = newValue"
          ></SelectTreeView>
        </v-list-group>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="'Фильтр по папке номенклатуры'"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-text-field
              label="Фильтр по папке номенклатуры"
              v-model="filterStructureString"
              placeholder="Фильтр по папке номенклатуры"
              solo
          ></v-text-field>
          <SelectTreeView
              :items="getStockRootCategories.filter(structure => !!structure.name.length)"
              :search="filterStructureString"
              @update:modelValue="newValue => selectedStructure = newValue"
          ></SelectTreeView>
        </v-list-group>
        <v-list-group
            v-model="isOpenDistributorFilter"
            no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="'Фильтр по поставщикам'"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-text-field
              label="Фильтр по поставщикам"
              v-model="filterDistributorString"
              placeholder="Фильтр по поставщикам"
              solo
          ></v-text-field>
          <v-virtual-scroll
              height="300"
              :items="filteredDistributors"
              item-height="50"
          >
            <template #default="{item: brand}">
              <v-list-item
                  :key="brand.text"
              >
                <v-list-item-content class="ma-0 pa-0">
                  <div class="distributor-filter__item">
                    <v-checkbox
                        class="ma-0 pa-0"
                        :value="brand.id"
                        v-model="selectedDistributor"
                        :label="brand.text"
                        hide-details
                    ></v-checkbox>
                  </div>
                </v-list-item-content>
              </v-list-item>

            </template>
          </v-virtual-scroll>

        </v-list-group>

        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="'Фильтр по направлениям'"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-text-field
              label="Фильтр по направлениям"
              v-model="filterDirectionString"
              placeholder="Фильтр по направлениям"
              solo
          ></v-text-field>
          <SelectTreeView
              :items="getStockDirectionsTreeRoot"
              :search="filterDirectionString"
              @update:modelValue="newValue => selectedDirection = newValue"
          ></SelectTreeView>
        </v-list-group>
      </v-list>
    </div>
    <v-btn @click="actionIncrementApplyFilter">Применить</v-btn>
    <expand-menu :top="true"></expand-menu>
  </div>
</template>

<script>
import {imagesEmpty} from "@/router/routes";
import {mapActions, mapGetters, mapState} from "vuex";
import ExpandMenu from "@/components/ExpandMenu.vue";
import SelectTreeView from "@/components/SelectTreeView.vue";

export default {
  name: 'FilterNoImages',
  components: {SelectTreeView, ExpandMenu},
  mounted() {
    if (
        !this.stock_brands_fetching &&
        this.getStockBrandsTree.length === 0
    ) {
      this.actionFetchStockBrands()
    }

    if (
        !this.getStockDirectionsTreeRoot.length
    ) {
      this.actionFetchStockDirections();
    }

    this.actionFetchDistributorListSearch('')

  },
  data() {
    return {
      isOpenBrandFilter: false,
      isOpenDistributorFilter: false,
      filterBrandString: '',
      filterDirectionString: '',
      filterDistributorString: '',
      filterCategoryString: '',
      filterStructureString: '',
      selectedBrand: [],
      selectedDirection: [],
      selectedDistributor: [],
      selectedCategories: [],
      selectedStructure: [],
    }
  },
  computed: {
    ...mapState('stock_brands', ['stock_brands_fetching', 'stock_brands']),
    ...mapGetters('stock_tree', [
      'getStockRootTree'
    ]),
    ...mapGetters('stock_categories', [
      'getStockRootCategories'
    ]),
    ...mapGetters('stock_directions', [
      'getStockDirectionsTreeRoot',
    ]),
    ...mapGetters('app_catalog', ['getLeftPanelExpanded']),
    ...mapGetters('images', ['getNoImagesFilter']),
    ...mapGetters('distributor', ['distributorListSearch']),


    ...mapGetters('stock_brands', [
      'getStockBrandsTree',
      'getStockBrandsTreeByIdList',
      'getStockBrandById'
    ]),
    filteredBrands() {
      return this.stock_brands.filter(brand => {
        return brand.name.toLowerCase().includes(this.filterBrandString.toLowerCase())
      })
    },
    filteredDirections() {
      console.log(this.getStockDirectionsTree)
      return this.getStockDirectionsTree.filter(direction => {
        return direction.node_id.toLowerCase().includes(this.filterDirectionString.toLowerCase())
      })
    },
    filteredDistributors() {
      return this.distributorListSearch.filter(distributor => {
        return distributor.text
            .toLowerCase()
            .includes(this.filterDistributorString.toLowerCase())
      })
    },
    isShow() {
      return this.$route.name === imagesEmpty && (this.$route.params?.type === 'product' || this.$route.params?.type === undefined)
    },
  },
  methods: {
    ...mapActions('app_catalog', ['actionSwitchLeftPanelExpansion']),

    ...mapActions('stock_brands', [
      'actionFetchStockBrands',
    ]),
    ...mapActions('images', [
      'actionSetNoImagesFilter',
      'actionIncrementApplyFilter',
    ]),
    ...mapActions('distributor', [
      'actionFetchDistributorListSearch',
    ]),
    ...mapActions('stock_directions', [
      'actionFetchStockDirections'
    ])
  },
  watch: {
    selectedBrand() {
      const filters = {
        ...this.getNoImagesFilter,
        brands: this.selectedBrand.length ? this.selectedBrand.join(',') : null
      }
      this.actionSetNoImagesFilter(filters)
    },
    selectedCategories() {
      let categoriesIds = [];
      if (this.selectedCategories.length) {
        categoriesIds = this.selectedCategories.map(category => category.id)
      }
      const filters = {
        ...this.getNoImagesFilter,
        categories: categoriesIds.length ? categoriesIds.join(',') : null
      }
      this.actionSetNoImagesFilter(filters)
    },
    selectedStructure() {
      let structureIds = [];
      if (this.selectedStructure.length) {
        structureIds = this.selectedStructure.map(structure => structure.id)
      }
      const filters = {
        ...this.getNoImagesFilter,
        structures: structureIds.length ? structureIds.join(',') : null
      }
      this.actionSetNoImagesFilter(filters)
    },
    selectedDistributor() {
      const filters = {
        ...this.getNoImagesFilter,
        distributors: this.selectedDistributor.length ? this.selectedDistributor.join(',') : null
      }
      this.actionSetNoImagesFilter(filters)
    },
    selectedDirection() {
      let directionIds = [];
      if (this.selectedDirection.length) {
        directionIds = this.selectedDirection.map(structure => structure.id)
      }
      const filters = {
        ...this.getNoImagesFilter,
        directions: directionIds.length ? directionIds.join(',') : null
      }
      this.actionSetNoImagesFilter(filters)
    }
  }
}
</script>

<style scoped lang="scss">
.aside-content {
  position: relative;

  .catalog {
    font-size: 0.85rem;
  }
}
</style>
