import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import {INCREMENT_APPLY_FILTER} from "@/store/modules/images/mutations/types";

const state = {
  uploading: false,

  images_moderation: [],
  images_moderation_count: 0,
  images_moderation_fetching: false,

  images_empty: [],
  images_empty_count: 0,
  images_empty_fetching: false,

  images_bad: [],
  images_bad_count: 0,
  images_bad_fetching: false,

  upload_stats: [],
  upload_stats_fetching: false,

  problems_stats: [],
  problems_stats_fetching: false,

  user_payments: {},
  user_payments_fetching: false,

  no_images_filter: {},
  increment_apply_filter: 0,

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
