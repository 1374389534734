import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  // Объект для хранения товаров из стока
  stock_products: {},
  // Флаг получения товаров
  stock_products_fetching: false,
  // Результаты поиска, список id товаров
  stock_products_search: [],
  // Флаг поиска товаров
  stock_products_search_fetching: false,
  // Кандидаты для привязки. id товара контрагента => список id товаров стока
  stock_products_suggest: {},
  // Флаг получения кандидатов привязки
  stock_products_suggest_fetching: false,
  // Флаг создания товара
  stock_products_creating: false
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
