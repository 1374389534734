<template>
  <v-container>
    <v-card flat max-width="700" class="mx-auto">
      <h1>Инструкции</h1>
    </v-card>

    <v-card flat max-width="700" class="mx-auto">
      <v-card-title>
        Расширение для добавления фото товаров
      </v-card-title>
      <v-card-text>
        <span>Инструкция по установке расширения: </span>
        <a :href="instructionUrl" download="Инструкция к картинкам.zip">скачать</a>
        <br>
        <span>Ссылка на само расширение: </span>
        <a :href="extensionUrl" download="Инструкция к картинкам.zip">скачать</a>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script setup>
const host = process.env.VUE_APP_API_HOST;
const instructionUrl = `${host}/content/browser_extension/Инструкция к картинкам.zip`;
const extensionUrl = `${host}/content/browser_extension/download_agregator_images.zip`;
</script>
<style scoped lang="scss">
</style>