export const STOCK_PRODUCTS_SET = 'STOCK_PRODUCTS_SET'
export const STOCK_PRODUCTS_ADD = 'STOCK_PRODUCTS_ADD'
export const STOCK_PRODUCTS_FETCHING = 'STOCK_PRODUCTS_FETCHING'

export const STOCK_PRODUCTS_SEARCH_SET = 'STOCK_PRODUCTS_SEARCH_SET'
export const STOCK_PRODUCTS_SEARCH_FETCHING = 'STOCK_PRODUCTS_SEARCH_FETCHING'

export const STOCK_PRODUCTS_SUGGEST_SET = 'STOCK_PRODUCTS_SUGGEST_SET'
export const STOCK_PRODUCTS_SUGGEST_FETCHING = 'STOCK_PRODUCTS_SUGGEST_FETCHING'

export const STOCK_PRODUCTS_CREATING = 'STOCK_PRODUCTS_CREATING'
export const STOCK_PRODUCTS_UPDATING = 'STOCK_PRODUCTS_UPDATING'
