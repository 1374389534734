import api from './api'

export default {
  /**
   * Список юнитов поставщика
   * @param distributor
   * @returns {Promise<any>}
   */
  getUnits(distributor) {
    return api
      .get(`/parser/units/`, { params: { distributor } })
      .then(({ data }) => data)
  },

  /**
   * Привязка юнита
   * @param id
   * @param bind
   * @returns {Promise<any>}
   */
  bindUnit(id, bind) {
    return api
      .patch(
        `/parser/units/${id}/`,
        { bind },
        {},
        { successMessage: 'Настройки юнита успешно сохранены' }
      )
      .then(({ data }) => data)
  }
}
