import _ from 'lodash'
import api from '@/services/distributorApi'
import * as mutations from './mutations/types'

export default {
  /**
   * Поиск контрагентов
   * @param commit
   */
  actionFetchDistributorListSearch: _.debounce(({ commit }, q) => {
    commit(mutations.DISTRIBUTOR_LIST_SEARCH_FETCHING, true)
    api
      .searchDistributorList(q)
      .then(dataList => {
        commit(mutations.DISTRIBUTOR_LIST_SEARCH_SET, { dataList })
      })
      .finally(() => {
        commit(mutations.DISTRIBUTOR_LIST_SEARCH_FETCHING, false)
      })
  }, 500),

  /**
   * Получение привязанных контрагентов
   * @param commit
   */
  actionFetchDistributorList: ({ commit }) => {
    commit(mutations.DISTRIBUTOR_LIST_FETCHING, true)
    api
      .getDistributorList()
      .then(dataList => {
        commit(mutations.DISTRIBUTOR_LIST_SET, { dataList })
      })
      .finally(() => {
        commit(mutations.DISTRIBUTOR_LIST_FETCHING, false)
      })
  },

  /**
   * Получение списка парсеров
   * @param commit
   */
  actionFetchParserList: ({ commit }) => {
    commit(mutations.PARSER_LIST_FETCHING, true)
    api
      .getParserList()
      .then(dataList => {
        commit(mutations.PARSER_LIST_SET, { dataList })
      })
      .finally(() => {
        commit(mutations.PARSER_LIST_FETCHING, false)
      })
  },

  /**
   * Добавление контрагента
   * @param commit
   * @param distributor_id
   * @param parser_id
   * @param managers
   * @returns {Q.Promise<any>}
   */
  actionAddDistributor: (
    { commit },
    { distributor_id, parser_id, managers }
  ) => {
    commit(mutations.DISTRIBUTOR_ADDING, true)
    return api
      .addDistributor({ distributor_id, parser_id, managers })
      .then(dataItem => {
        commit(mutations.DISTRIBUTOR_LIST_ADD, { dataItem })
      })
      .finally(() => {
        commit(mutations.DISTRIBUTOR_ADDING, false)
      })
  },

  /**
   * Обновление контрагента
   * @param commit
   * @param id
   * @param enabled
   * @returns {Promise<T | never>}
   */
  actionSwitchDistributor: ({ commit }, { id, enabled }) => {
    commit(mutations.DISTRIBUTOR_LIST_UPDATE, {
      id,
      data: { loading: true }
    })
    return api
      .switchDistributor(id, enabled)
      .then(data => {
        commit(mutations.DISTRIBUTOR_LIST_UPDATE, { id, data })
      })
      .finally(() => {
        commit(mutations.DISTRIBUTOR_LIST_UPDATE, {
          id,
          data: { loading: false }
        })
      })
  },

  /**
   * Обновление менеджеров поставщика
   * @param commit
   * @param id
   * @param managers
   * @returns {Q.Promise<any>}
   */
  actionUpdateDistributorManagers: ({ commit }, { id, managers }) => {
    commit(mutations.DISTRIBUTOR_LIST_UPDATE, {
      id,
      data: { loading: true }
    })
    return api
      .updateDistributorManagers(id, managers)
      .then(data => {
        commit(mutations.DISTRIBUTOR_LIST_UPDATE, { id, data })
      })
      .finally(() => {
        commit(mutations.DISTRIBUTOR_LIST_UPDATE, {
          id,
          data: { loading: false }
        })
      })
  },

  /**
   * Получение списка брендов поставщика
   * @param commit
   * @param id
   */
  actionFetchDistributorAttachedBrands: ({ commit }, id) => {
    api.getAttachedBrands(id).then(idList => {
      commit(mutations.DISTRIBUTOR_BRANDS, idList)
    })
  }
}
