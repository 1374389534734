import { buildTree, getChildsRecursive } from '../../common'

export default {
  /**
   * Получение всех категорий
   * @param stock_categories
   * @param getters
   * @returns {*}
   */
  getStockCategories({ stock_categories }, getters) {
    const selectedItems =
      getters.getStockCategoriesSelected.changed ||
      getters.getStockCategoriesSelected.default
    return stock_categories.map(item => ({
      ...item,
      selected: selectedItems.includes(item.id)
    }))
  },

  /**
   * Дерево категорий
   * @param state
   * @param getters
   * @returns {ReadonlyArray<any>[]}
   */
  getStockCategoriesTree(state, getters) {
    return buildTree(getters.getStockCategories, [
      item => !item.enabled,
      'name'
    ])
  },

  /**
   * Получение корневых категорий
   * @param state
   * @param getters
   * @returns {*}
   */
  getStockRootCategories(state, getters) {
    return getters.getStockCategoriesTree.filter(item => !item.parent)
  },

  /**
   * Получение корневых категорий отфильтрованного дерева
   * @param state
   * @param getters
   * @returns {function(*=)}
   */
  getFilteredStockRootCategories(state, getters) {
    return filter => {
      return buildTree(
        getters.getStockCategories,
        [item => !item.enabled, 'name'],
        filter
      ).filter(item => !item.parent)
    }
  },

  /**
   * Флаг получения категорий
   * @param stock_categories_fetching
   * @returns boolean
   */
  getStockCategoriesFetching({ stock_categories_fetching }) {
    return stock_categories_fetching
  },

  /**
   * Флаг добавления категории
   * @param stock_categories_adding
   * @returns {*}
   */
  getStockCategoriesAdding({ stock_categories_adding }) {
    return stock_categories_adding
  },

  /**
   * Получение элемента по id
   * @param state
   * @param getters
   * @returns {function(*): *}
   */
  getStockCategoryById(state, getters) {
    return id => getters.getStockCategoriesTree.find(item => item.id === id)
  },

  /**
   * Получение элемента по id 1с
   * @param state
   * @param getters
   * @returns {function(*=): *}
   */
  getStockCategoryById1C(state, getters) {
    return id => {
      const id1c = String(id)
      return getters.getStockCategoriesTree.find(item => item._1c_id === id1c)
    }
  },

  /**
   * Получение элементов по списку id
   * @param state
   * @param getters
   * @returns {function(*): *}
   */
  getStockCategoriesByIdList(state, getters) {
    return idList =>
      idList.length
        ? getters.getStockCategoriesTree.filter(item =>
            idList.includes(item.id)
          )
        : []
  },

  /**
   * Получение списка предков
   * @param state
   * @param getters
   * @returns {function(*): []}
   */
  getStockCategoryAncestorsById(state, getters) {
    return id => {
      let item = getters.getStockCategoryById(id)
      const ancestorsList = [item]
      let hasAncestor = !!item.parentItem
      while (hasAncestor) {
        item = item.parentItem
        ancestorsList.push(item)
        hasAncestor = !!item.parentItem
      }
      return ancestorsList
    }
  },

  /**
   * Получение списка id дочерних категорий
   * @param state
   * @param getters
   * @returns {function(*): []}
   */
  getStockCategoryChildsIdRecursive(state, getters) {
    return id => {
      return getChildsRecursive(getters.getStockCategoryById(id))
    }
  },

  /**
   * Флаг обновления
   * @param stock_categories_updating
   * @returns {*}
   */
  getStockCategoriesUpdating({ stock_categories_updating }) {
    return stock_categories_updating
  },

  /**
   * Список id выбранных элементов
   * @param stock_categories_selected
   * @returns {*}
   */
  getStockCategoriesSelected({ stock_categories_selected }) {
    return stock_categories_selected
  },

  /**
   * Флаг обновления выбранных для работы категорий
   * @param stock_categories_selected_fetching
   * @returns {*}
   */
  getStockCategoriesSelectedFetching({stock_categories_selected_fetching}) {
    return stock_categories_selected_fetching
  }
}
