export const STOCK_CATEGORIES_SET = 'STOCK_CATEGORIES_SET'
export const STOCK_CATEGORIES_FETCHING = 'STOCK_CATEGORIES_FETCHING'
export const STOCK_CATEGORIES_ADD = 'STOCK_CATEGORIES_ADD'
export const STOCK_CATEGORIES_ADDING = 'STOCK_CATEGORIES_ADDING'
export const STOCK_CATEGORIES_UPDATE = 'STOCK_CATEGORIES_UPDATE'
export const STOCK_CATEGORIES_UPDATING = 'STOCK_CATEGORIES_UPDATING'
export const STOCK_CATEGORIES_SELECTED_SET = 'STOCK_CATEGORIES_SELECTED_SET'
export const STOCK_CATEGORIES_SELECTED_FETCHING =
  'STOCK_CATEGORIES_SELECTED_FETCHING'
export const STOCK_CATEGORIES_SELECTED_UPDATING =
  'STOCK_CATEGORIES_SELECTED_UPDATING'
