import api from './api'
import common from './common'

export default {
  /**
   * Получение списка веток ассортимента
   * @returns {Promise<any>}
   */
  getStockTree() {
    return api.get(`/stock/categories/`).then(({ data }) => data)
  },

  /**
   * Получение массива идентифиваторов категорий, в которых находятся товары, импортированные
   * из внешних сайтов
   */
  getStockTreeIdsWithImportedGoods() {
    return api.get('/stock/categories/with_imported_goods/').then(({ data }) => data)
  },

  /**
   * Получение ветки ассортимента по id
   * @param id
   * @returns {*}
   */
  getStockTreeById(id) {
    return api.get(`/stock/categories/${id}/`).then(({ data }) => data)
  },

  /**
   * Создание ветки ассортимента
   * @param name
   * @param parent
   * @returns {Promise<T>}
   */
  addStockTree(name, parent) {
    return api
      .post(`/stock/categories/`, { name, parent, enabled: true })
      .then(({ data }) => data)
  },

  /**
   * Обновление ветки ассортимента
   * @param id
   * @param data
   * @returns {Promise<T>}
   */
  patchStockTree(id, data) {
    return api.patch(`/stock/categories/${id}/`, data).then(({ data }) => data)
  },

  /**
   * Загрузка фото
   * @param id
   * @param file
   * @returns {Promise<any>}
   */
  uploadStockTreePhoto(id, file) {
    return common
      .uploadImage(`/stock/categories/${id}/`, file)
      .then(({ data }) => data)
  },

  /**
   * Получить список выбранных элементов для поставщика
   * @param distributor_id
   * @returns {Promise<Array>}
   */
  getSelectedStockTree(distributor_id) {
    return api
      .get(`/parser/distributors/${distributor_id}/filtering_categories/`)
      .then(({ data }) => data)
  },

  /**
   * Обновление списка выбранных элементов для постащика
   * @param distributor_id
   * @param toggle_on
   * @param toggle_off
   * @returns {Promise<Array>}
   */
  updateSelectedStockTree(distributor_id, toggle_on, toggle_off) {
    return api
        .post(`/parser/distributors/${distributor_id}/mass_toggle_filtering_categories/categories/`, {
          toggle_on,
          toggle_off
        })
        .then(({data}) => data)
  }
}
