import api from './api'

export default {
    /**
     * Получение всех stock параметров
     * @returns {Promise<any>}
     */
    getStockFeatures() {
        return api.get(`/stock/features/`).then(({data}) => data)
    },

    /**
     * Получение значений stock параметра
     * @param id
     * @returns {Promise<any>}
     */
    getStockFeatureValues(id) {
        return api.get(`/stock/features/${id}/values_of/`).then(({data}) => data)
    },

    /**
     * Создание свойства характеристики
     * @param data
     * @returns {*}
     */
    createStockFeature(data) {
        return api.post('/stock/features/', data).then(({data}) => data)
    },

    /**
     * Создаёт значение свойство
     * @param feature Идентификатор свойства
     * @param value Значение
     * @returns {{id: number, _1c_id: string, value: any, feature: number}}
     */
    createStockFeatureValue({feature, value}) {
        return api
            .post('/stock/feature-values/', {feature, value})
            .then(({data}) => data)
    }
}
