import _ from 'lodash'

export default {
  /**
   * Получение всех юнитов контрагента
   * @param stock_units
   * @returns {*}
   */
  getStockUnits({ stock_units }) {
    return _.sortBy(stock_units, ['name'])
  },

  /**
   * Флаг получения юнитов поставщика
   * @param stock_units_fetching
   * @returns {*}
   */
  getStockUnitsFetching({ stock_units_fetching }) {
    return stock_units_fetching
  },

  /**
   * Получение юнита контрагента по id
   * @param stock_units
   * @returns {function(*): *}
   */
  getStockUnitById({ stock_units }) {
    return id => stock_units.find(item => item.id == id)
  }
}
