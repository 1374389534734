import * as types from './types'

export default {
  /**
   * Задаем данные по направлениям
   * @param state
   * @param dataList
   */
  [types.STOCK_DIRECTIONS_SET]: (state, dataList) => {
    state.stock_directions = dataList
  },

  /**
   * Флаг получения списка направлений
   * @param state
   * @param value
   */
  [types.STOCK_DIRECTIONS_FETCHING]: (state, value) => {
    state.stock_directions_fetching = value
  }
}
