import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  // Данные по брендам
  stock_features: [],
  // Флаг запроса всех брендов
  stock_features_fetching: false,
  // Флаг создания параметра
  stock_features_adding: false
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
