import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import common from './modules/common'
import app_aggregator from './modules/app-aggregator'
import app_catalog from './modules/app-catalog'
import images from './modules/images'
import distributor from './modules/distributor'
import distributor_categories from './modules/distributor-categories'
import distributor_brands from './modules/distributor-brands'
import distributor_features from './modules/distributor-features'
import distributor_products from './modules/distributor-products'
import distributor_units from './modules/distributor-units'
import stock_brands from './modules/stock-brands'
import stock_features from './modules/stock-features'
import stock_categories from './modules/stock-categories'
import stock_tree from './modules/stock-tree'
import stock_directions from './modules/stock-directions'
import stock_products from './modules/stock-products'
import stock_units from './modules/stock-units'
import {routes} from "@/router";
import permissions from "@/common/permissions";
import {settingsPage} from "@/router/routes";

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  state: {
    dashboardItems: [
      {
        key: routes.catalogIndex,
        title: 'Каталог',
        icon: 'mdi-library-books',
        link: routes.catalogIndex,
        permission: permissions.appCatalog
      },
      {
        key: routes.brandsIndex,
        title: 'Бренды',
        icon: 'mdi-babel',
        link: routes.brandsIndex,
        permission: [
          permissions.apiStockBrands,
          permissions.apiStockBrandsCategories
        ]
      },
      {
        key: routes.imagesIndex,
        title: 'Изображения',
        icon: 'mdi-image-multiple',
        link: routes.imagesIndex,
        permission: permissions.appImages
      },
      {
        key: routes.aggregatorSelectDistributor,
        title: 'Агрегатор',
        icon: 'mdi-bulldozer',
        link: routes.aggregatorSelectDistributor,
        permission: permissions.appAggregator
      },
      {
        key: routes.exportIndex,
        title: 'Экспорт в 1с',
        icon: 'mdi-progress-upload',
        link: routes.exportIndex,
        permission: permissions.appAggregator
      },
      {
        key: routes.logsIndex,
        title: 'Логи',
        icon: 'mdi-text',
        link: routes.logsIndex,
        permission: permissions.appAggregator
      },
      {
        key: routes.settingsPage,
        title: 'Настройка',
        icon: 'mdi-cogs',
        link: routes.settingsPage,
        permission: permissions.appImages
      }
    ]
  },
  getters: {
    getMainMenuItems(state, _, __, rootGetters) {
      return state.dashboardItems.filter(item => {
          if (item.permission) {
            let permissions = []

            if (typeof item.permission === 'string') {
              permissions = [item.permission]
            } else {
              permissions = item.permission
            }

            for (let perm of permissions) {
              if (!rootGetters['auth/getCurrentUserHasPermission'](perm)) {
                return false
              }
            }
          }

          return true
        }
      )
    }
  },
  modules: {
    auth,
    common,
    app_aggregator,
    app_catalog,
    images,
    distributor,
    distributor_categories,
    distributor_brands,
    distributor_features,
    distributor_products,
    distributor_units,
    stock_brands,
    stock_features,
    stock_categories,
    stock_tree,
    stock_directions,
    stock_products,
    stock_units
  },
  strict: debug
})
