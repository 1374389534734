import api from './api'

export default {
  /**
   * Получение списка направлений
   * @returns {Promise<any>}
   */
  getStockDirections() {
    return api.get(`/1c/directions/`).then(({ data }) => data)
  }
}
