export default {
  /**
   * Список ошибок при запросах к апи
   * @param messages
   * @returns {*}
   */
  errorMessages({ messages }) {
    return messages
  }
}
