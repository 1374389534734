import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  // Выбранный дистрибьютор
  active_distributor: null,
  // Счётчики непривязанного для текущего дистрибьютора
  active_distributor_unbind_counters: {
    brands: null,
    features: null,
    units: null,
    products: null
  },
  // Список фоновых задач
  background_tasks: null,
  // Признак обновления списка фоновых задач
  background_tasks_fetching: false,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
