import { buildTree, getChildsRecursive } from '../../common'
import * as catalogMergeMethods from '@/common/catalogMergeMethods'

export default {
  /**
   * Список категорий поставщика
   * @param distributor_categories
   * @param getters
   * @returns {*}
   */
  getDistributorCategories({ distributor_categories }, getters) {
    const selectedItems =
      getters.getDistributorCategoriesSelected.changed ||
      getters.getDistributorCategoriesSelected.default

    return distributor_categories.map(
      item => ({...item, selected: selectedItems.includes(item.id)})
    )
  },

  /**
   * Дерево категорий
   * @param state
   * @param getters
   * @returns {ReadonlyArray<any>[]}
   */
  getDistributorCategoriesTree(state, getters) {
    return buildTree(getters.getDistributorCategories, ['name'])
  },

  /**
   * Получение корневых категорий контрагента
   * @param state
   * @param getters
   * @returns {*}
   */
  getDistributorRootCategories(state, getters) {
    return getters.getDistributorCategoriesTree.filter(item => !item.parent)
  },

  /**
   * Фильтрованное дерево категорий
   * @param state
   * @param getters
   * @returns {function(*=): ReadonlyArray<any>[]}
   */
  getFilteredDistributorRootCategories(state, getters) {
    return filter => {
      return buildTree(getters.getDistributorCategories, ['name'], filter).filter(item => !item.parent)
    }
  },

  /**
   * Флаг получения категорий поставщика
   * @param distributor_categories_fetching
   * @returns boolean
   */
  getDistributorCategoriesFetching({ distributor_categories_fetching }) {
    return distributor_categories_fetching
  },

  /**
   * Флаг обновления категорий поставщика
   * @param distributor_categories_fetching
   * @returns {*}
   */
  getDistributorCategoriesUpdating({ distributor_categories_updating }) {
    return distributor_categories_updating
  },

  /**
   * Получение кандидатов для привязки
   * @param distributor_categories_suggest
   * @returns {function(*): (*|null)}
   */
  getDistributorCategorySuggest({ distributor_categories_suggest }) {
    return id => {
      return distributor_categories_suggest[id] || null
    }
  },

  /**
   * Флаг получения кандидатов на привязку
   * @param distributor_categories_bindings_fetching
   * @returns {*}
   */
  getDistributorCategoriesBindingsFetching({
    distributor_categories_bindings_fetching
  }) {
    return distributor_categories_bindings_fetching
  },

  /**
   * Получение списка id дочерних категорий
   * @param state
   * @param getters
   * @returns {function(*): []}
   */
  getDistributorCategoryChildsIdRecursive(state, getters) {
    return id => {
      return getChildsRecursive(
        getters.getDistributorCategoriesTree.find(item => item.id === id)
      )
    }
  },

  /**
   * Получение категории по id
   * @param state
   * @param getters
   * @returns {function(*): *}
   */
  getDistributorCategoryById(state, getters) {
    return id => {
      return (
        getters.getDistributorCategoriesTree.find(item => item.id === id) ||
        null
      )
    }
  },

  /**
   * Получаю наследодателя
   * @returns {function(*): *}
   */
  getDistributorCategoryTestator() {
    const ancestorMergeMethods = [
      catalogMergeMethods.recursive,
      catalogMergeMethods.as_single
    ]
    return value => {
      let item = value
      let ancestors = [item]
      while (item) {
        item = item.parentItem || null
        if (item) {
          ancestors.push(item)
        }
      }
      for (let ancestor of ancestors) {
        if (ancestorMergeMethods.includes(ancestor.merge_method)) {
          return ancestor
        } else if (ancestor.merge_method === catalogMergeMethods.ignore) {
          return null
        }
      }
      return null
    }
  },

  /**
   * Список id выбранных элементов
   * @param distributor_categories_selected
   * @returns {*}
   */
  getDistributorCategoriesSelected({ distributor_categories_selected }) {
    return distributor_categories_selected
  }
}
