import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  users: [],
  users_fetching: false,
  current_user: null,
  current_user_fetching: false,
  auth_checked: false,
  login_in_progress: false,
  password_recovery_in_progress: false
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
