import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import Vue from 'vue'
import App from '@/components/app/App'
import store from '@/store'
import router from '@/router'
import vuetify from '@/plugins/vuetify'
import '@/components/register-globals'
import moment from "moment";

const sentryEnabled = process.env.VUE_APP_SENTRY_ENABLE !== '0'
const sentryLink = process.env.VUE_APP_SENTRY_LINK

if (sentryEnabled && sentryLink) {
  Sentry.init({
    dsn: sentryLink,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })]
  })
}

Vue.config.productionTip = false

Vue.filter('datetime', datetime => moment(datetime).format('YYYY.MM.DD HH:mm'))

const vue = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
})

vue.$mount('#app')
