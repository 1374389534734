export const UPLOADING = 'UPLOADING'

export const IMAGES_MODERATION_SET = 'IMAGES_MODERATION_SET'
export const IMAGES_MODERATION_COUNT = 'IMAGES_MODERATION_COUNT'
export const IMAGES_MODERATION_FETCHING = 'IMAGES_MODERATION_FETCHING'

export const IMAGES_EMPTY_SET = 'IMAGES_EMPTY'
export const IMAGES_EMPTY_COUNT = 'IMAGES_EMPTY_COUNT'
export const IMAGES_EMPTY_FETCHING = 'IMAGES_EMPTY_FETCHING'

export const IMAGES_BAD_SET = 'IMAGES_BAD'
export const IMAGES_BAD_COUNT = 'IMAGES_BAD_COUNT'
export const IMAGES_BAD_FETCHING = 'IMAGES_BAD_FETCHING'

export const UPLOAD_STATS = 'UPLOAD_STATS'
export const UPLOAD_STATS_FETCHING = 'UPLOAD_STATS_FETCHING'

export const PROBLEMS_STATS = 'PROBLEMS_STATS'
export const PROBLEMS_STATS_FETCHING = 'PROBLEMS_STATS_FETCHING'

export const USER_PAYMENTS = 'USER_PAYMENTS'
export const USER_PAYMENTS_FETCHING = 'USER_PAYMENTS_FETCHING'

export const SET_NO_IMAGES_FILTER = 'SET_NO_IMAGES_FILTER'
export const INCREMENT_APPLY_FILTER = 'INCREMENT_APPLY_FILTER'
