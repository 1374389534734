import * as types from './types'

export default {
  /**
   * Задаем данные по каталогу поставщика
   * @param state
   * @param dataList
   */
  [types.DISTRIBUTOR_CATEGORIES_SET]: (state, dataList) => {
    state.distributor_categories = dataList
  },

  /**
   * Флаг получения категорий поставщика
   * @param state
   * @param value
   */
  [types.DISTRIBUTOR_CATEGORIES_FETCHING]: (state, value) => {
    state.distributor_categories_fetching = value
  },

  /**
   * Обновление категории поставщика
   * @param state
   * @param id
   * @param data
   */
  [types.DISTRIBUTOR_CATEGORIES_UPDATE]: (state, { id, data }) => {
    state.distributor_categories = state.distributor_categories.map(item =>
      item.id === id ? { ...item, ...data } : item
    )
  },

  /**
   * Флаг обновления категорий поставщика
   * @param state
   * @param value
   */
  [types.DISTRIBUTOR_CATEGORIES_UPDATING]: (state, value) => {
    state.distributor_categories_updating = value
  },

  /**
   * Флаг получения кандидатов для привязки
   * @param state
   * @param value
   */
  [types.DISTRIBUTOR_CATEGORIES_BINDINGS_FETCHING]: (state, value) => {
    state.distributor_categories_bindings_fetching = value
  },

  /**
   * Задаем кандидатов на привязку
   * @param state
   * @param id
   * @param tree
   * @param category
   */
  [types.DISTRIBUTOR_CATEGORIES_SUGGEST_SET]: (
    state,
    { id, tree, category }
  ) => {
    state.distributor_categories_suggest = {
      ...state.distributor_categories_suggest,
      [id]: { tree, category }
    }
  },

  /**
   * Задаем выбранные значения
   * @param state
   * @param dataList
   */
  [types.DISTRIBUTOR_CATEGORIES_SELECTED_SET]: (state, data) => {
    state.distributor_categories_selected = {
      ...state.distributor_categories_selected,
      ...data
    }
  }
}
