<template>
  <v-container>
    <v-row class="flex-nowrap">
      <v-col class="left-side" :class="{ expanded: getLeftPanelExpanded }">
        <router-view name="menu" />
        <FastGoForm />
        <router-view name="aside" />
      </v-col>
      <v-col class="right-side">
        <span id="top-scroll-anchor"></span>
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import FastGoForm from './FastGoForm'

export default {
  components: { FastGoForm },
  computed: {
    ...mapGetters('app_catalog', ['getLeftPanelExpanded'])
  }
}
</script>

<style scoped lang="scss">
.left-side {
  max-width: 280px;
  width: 280px;
  flex: 0 0 280px;
  background-color: white;
  position: relative;

  &.expanded {
    max-width: 480px;
    width: 480px;
    flex: 0 0 480px;
  }
  &.compressed {
    max-width: 50px;
    width: 50px;
    flex: 0 0 50px;
  }
}
.right-side {
  position: relative;
  overflow: hidden;
  background-color: white;
}
</style>
