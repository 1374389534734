import HttpClient from '@/utils/HttpClient'
import store from '@/store'

const host = process.env.VUE_APP_API_HOST

const api = new HttpClient({
  xsrfHeaderName: 'X-CSRFTOKEN',
  xsrfCookieName: 'csrftoken',
  withCredentials: true,
  baseURL: `${host}/api`,
  timeout: 50000,
  headers: {
    'Content-Type': 'application/json'
  }
})

api.onSuccess((data, options) => {
  if (options.successMessage) {
    store.dispatch('actionAddInfoMessage', options.successMessage, {
      root: true
    })
  }
})
api.onError(message => {
  if (message) {
    store.dispatch('actionAddErrorMessage', message, { root: true })
  }
})

export default api
