import api from './api'

export default {
  /**
   * Получение списка параметров
   * @param distributor
   * @returns {Promise<any>}
   */
  getDistributorFeatures(distributor) {
    return api
      .get(`/parser/features/`, { params: { distributor } })
      .then(({ data }) =>
        data.map(item => {
          return { ...item, loading: false }
        })
      )
  },

  /**
   * Вкл/Выкл параметра поставщика
   * @param id
   * @param enabled
   * @returns {Promise<any>}
   */
  switchDistributorFeature(id, enabled) {
    return api
      .patch(`/parser/features/${id}/`, { enabled })
      .then(({ data }) => data)
  },

  /**
   * Привязка параметров
   * @param id
   * @param bind
   * @returns {Promise<any>}
   */
  bindDistributorFeature(id, bind) {
    return api
      .patch(
        `/parser/features/${id}/`,
        { bind },
        {},
        { successMessage: 'Привязка параметра успешно изменена' }
      )
      .then(({ data }) => data)
  },

  /**
   * Получение списка кандидатов для привязки
   * @param id
   * @returns {Promise<any>}
   */
  getDistributorFeaturesSuggest(id) {
    return api
      .get(`/parser/features/${id}/suggest/`)
      .then(response => (response && response.data.map(item => item.id)) || [])
  },

  /**
   * Получение всех значений свойства
   * @param id
   * @returns {*}
   */
  getFeatureValues(id) {
    return api.get(`/parser/features/${id}/values_of/`).then(({ data }) => data)
  },

  /**
   * Привязка значений параметров
   * @param params
   * @returns {Promise<any>}
   */
  bindDistributorFeatureValue(params) {
    return api
      .patch(`/parser/feature_values/${params.id}/`, { bind: params.bind })
      .then(({ data }) => data)
  },

  /**
   * Обновление конкретного свойства по идентификатору
   * @param id
   * @return {Promise<any>}
   */
  getFeatureById(id) {
    return api
      .get(`/parser/features/${id}/`)
      .then(({data}) => data)
  },

}
