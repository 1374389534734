import axios from 'axios'

/**
 * Получение сообщения об ошибке
 * @param data
 * @returns {string}
 */
const getErrorMessage = data => {
  if (typeof data == 'object' && data.hasOwnProperty('detail'))
    return data.detail

  return typeof data === 'string'
    ? data.substring(0, 140)
    : Object.values(data).join('<br/>')
}

export default class HttpClient {
  successHandler = null
  errorHandler = null

  /**
   * @param options
   */
  constructor(options) {
    this.instance = axios.create(options)
  }

  /**
   * Обработчик успешного запроса
   * @param handler
   */
  onSuccess(handler) {
    this.successHandler = handler
  }

  /**
   * Обработчик ошибок
   * @param handler
   */
  onError(handler) {
    this.errorHandler = handler
  }

  /**
   * Выполнение запросов
   *
   * Опция (bool)muteErrorHandler, если указанна как true, то стандартный обработчик ошибок (отображение уведомлений,
   * отвключается)
   *
   * @param method
   * @param args
   * @param options
   * @returns {*}
   */
  request(method, args, options = {}) {
    return this.instance[method]
      .apply(this.instance, args)
      .then(response => {
        if (this.successHandler && response.data) {
          this.successHandler(response.data, options)
        }
        return response
      })
      .catch(e => {
        if (this.errorHandler && !options.muteErrorHandler) {
          if (e.response && e.response.data) {
            if (typeof e.response.data === 'object' && e.response.data.hasOwnProperty('errors')) {
              this.errorHandler(e.response.data)
            } else {
              this.errorHandler(getErrorMessage(e.response.data));
            }
          } else {
            this.errorHandler(`${e.name}: ${e.message}`)
          }
        }
      })
  }

  /** PROXY **/

  get(url, config, options) {
    return this.request('get', [url, config], options)
  }

  delete(url, config, options) {
    return this.request('delete', [url, config], options)
  }

  head(url, config, options) {
    return this.request('head', [url, config], options)
  }

  options(url, config, options) {
    return this.request('options', [url, config], options)
  }

  post(url, data, config, options) {
    return this.request('post', [url, data, config], options)
  }

  put(url, data, config, options) {
    return this.request('put', [url, data, config], options)
  }

  patch(url, data, config, options) {
    return this.request('patch', [url, data, config], options)
  }
}
