import * as types from './types'

export default {
  /**
   * Добавление сообщения об ошибке
   * @param state
   * @param message
   */
  [types.ADD_MESSAGE]: (state, message) => {
    state.messages = [...state.messages, message]
  },

  /**
   * Удаление сообщения об ошибке
   * @param state
   * @param id
   */
  [types.REMOVE_MESSAGE]: (state, id) => {
    state.messages = state.messages.filter(item => item.id !== id)
  },

  [types.SET_ERROR_DIALOG]: (state, {detail, errors})  => {
    state.detailed_error.title = detail
    state.detailed_error.errors = errors
    state.detailed_error.show = true
  },

  [types.CLOSE_ERROR_DIALOG]: (state) => {
    state.detailed_error.show = false
  }
}
