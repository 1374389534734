import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  messages: [],
  detailed_error: {
    title: null,
    errors: [],
    show: false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
