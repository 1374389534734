import * as mutations from './mutations/types'

const messageId = () => `f${(~~(Math.random() * 1e8)).toString(16)}`

export default {
  /**
   * Добавление сообщения об ошибке
   */
  actionAddErrorMessage: {
    root: true,
    handler: ({ commit }, message) => {
      if (typeof message == 'object' && message.hasOwnProperty('errors')) {
        commit(mutations.SET_ERROR_DIALOG, message)
      } else {
        commit(mutations.ADD_MESSAGE, {
          id: messageId(),
          text: message,
          type: 'error'
        });
      }
    }
  },

  /**
   * Добавление уведомления
   */
  actionAddInfoMessage: {
    root: true,
    handler: ({ commit }, message) => {
      commit(mutations.ADD_MESSAGE, {
        id: messageId(),
        text: message,
        type: 'info'
      })
    }
  },

  /**
   * Удаление сообщения
   * @param commit
   * @param id
   */
  actionRemoveErrorMessage: ({ commit }, id) => {
    commit(mutations.REMOVE_MESSAGE, id)
  },
}
