import api from './api'

export default {
  /**
   * Поиск поставщиков в 1с
   * @param name
   * @returns {Promise<any>}
   */
  searchDistributorList(name) {
    return api
      .get('/1c/distributors/', { params: { name } })
      .then(({ data }) => data)
  },

  /**
   * Получение списка поставщиков привязанных к парсерам
   * @returns {Promise<any>}
   */
  getDistributorList() {
    return api
      .get('/parser/distributors/')
      .then(({ data }) =>
        data.map(item => ({ ...item, loading: false, processing: false }))
      )
  },

  /**
   * Получение количества непривязанного по поставщику
   * @param distributor_id
   * @returns {*}
   */
  getDistributorUnbindedCounters({ distributor_id }) {
    return api
      .get(`/parser/distributors/${distributor_id}/get_unbinded_counters/`)
      .then(({ data }) => data)
  },

  /**
   * Привязка поставщика к парсеру
   * @param distributor_id
   * @param parser_id
   * @param managers
   * @returns {*}
   */
  addDistributor({ distributor_id, parser_id, managers }) {
    return api
      .post('/parser/distributors/', {
        _1c_id: distributor_id,
        parser: parser_id,
        managers
      })
      .then(({ data }) => data)
  },

  /**
   * Получение списка парсеров
   * @returns {Promise<any>}
   */
  getParserList() {
    return api.get('/parser/modules/').then(({ data }) => data)
  },

  /**
   * Вкл/Выкл поставщика
   * @param id
   * @param enabled
   * @returns {Promise<any>}
   */
  switchDistributor(id, enabled) {
    return api
      .patch(`/parser/distributors/${id}/`, { enabled })
      .then(({ data }) => data)
  },

  /**
   * Обновление менеджеров поставщика
   * @param id
   * @param managers
   * @returns {*}
   */
  updateDistributorManagers(id, managers) {
    return api
      .patch(`/parser/distributors/${id}/`, { managers })
      .then(({ data }) => data)
  },

  /**
   * Бренды поставщика в 1с
   * @param id
   * @returns {Promise<any>}
   */
  getAttachedBrands(id) {
    return api
      .get(`/parser/distributors/${id}/list_attached_brands/`)
      .then(({ data }) => data.map(item => item.id))
  }
}
