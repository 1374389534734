import api from './api'

export default {
  uploadImage(url, file) {
    const data = new FormData()
    data.append('image', file)

    return api.post(url, data, {}, { successMessage: 'Изображение загружено' })
  }
}
