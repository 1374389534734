<template>
  <div
    v-scroll="calculatePosition"
    v-resize="calculateWidth"
    ref="wrapper"
    class="sticky-actions-wrapper"
    :style="wrapperStyleData"
  >
    <div
      :class="['flying-container', { flying: isFixed }]"
      :style="containerStyleData"
    >
      <div
        ref="content"
        :class="{ 'sticky-actions-content': isFixed, 'elevation-6': isFixed }"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VStickyActions',
  props: {
    bottom: {
      type: Boolean,
      default: true
    },
    top: {
      type: Boolean,
      default: false
    },
    margin: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    timerId: null,
    offsetHeight: 0,
    clientHeight: 0,
    wrapperPosition: 0,
    wrapperWidth: 0,
    wrapperHeight: 0
  }),
  mounted() {
    this.timerId = setInterval(() => {
      this.calculatePosition()
      this.calculateWidth()
      this.calculateHeight()
    }, 100)
  },
  destroyed() {
    clearInterval(this.timerId)
  },
  computed: {
    wrapperStyleData: function() {
      return {
        height: `${this.wrapperHeight}px`
      }
    },
    getMargin: function() {
      if (this.margin) {
        return this.margin
      } else {
        return this.top ? 64 : 35
      }
    },
    isFixed: function() {
      if (!this.clientHeight) {
        return false
      }
      if (this.top) {
        return this.wrapperPosition - this.getMargin <= 0
      } else {
        return this.wrapperPosition + this.getMargin > this.clientHeight
      }
    },
    containerStyleData: function() {
      const data = {}
      if (this.isFixed) {
        data['width'] = `calc(${this.wrapperWidth}px + (2*1rem))`
        if (this.top) {
          data['top'] = `${this.getMargin}px`
        } else {
          data['bottom'] = `${this.getMargin}px`
        }
      }
      return data
    }
  },
  methods: {
    calculatePosition() {
      const el = document.documentElement
      this.clientHeight = el.clientHeight
      this.offsetHeight = el.offsetHeight
      this.wrapperPosition = this.top
        ? this.$refs.wrapper.getBoundingClientRect().top
        : this.$refs.wrapper.getBoundingClientRect().bottom
      this.calculateWidth()
    },
    calculateWidth() {
      this.wrapperWidth = this.$refs.wrapper.offsetWidth
    },
    calculateHeight() {
      this.wrapperHeight = this.$refs.content.offsetHeight
    }
  }
}
</script>

<style scoped lang="scss">
.sticky-actions-wrapper {
  position: relative;

  .flying-container {
    &.flying {
      background-color: #ffffff;
      position: fixed;
      margin: 0 -1rem;
      z-index: 1;
    }
  }
}
.sticky-actions-content {
  padding: 0 1rem;
}
</style>
