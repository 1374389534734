import api from '@/services/stockCategoriesApi'
import * as mutations from './mutations/types'

export default {
    /**
     * Очистить категории
     * @param commit
     */
    actionClearStockCategories: ({commit}) => {
        commit(mutations.STOCK_CATEGORIES_SET, [])
        commit(mutations.STOCK_CATEGORIES_SELECTED_SET, {
            default: [],
            changed: null
        })
    },

    /**
     * Запрос всех категорий
     * @param commit
     * @param state
     * @param force
     * @returns {Q.Promise<any>}
     */
    actionFetchStockCategories: ({commit, state}, force) => {
        if (force || !state.stock_categories.length) {
            commit(mutations.STOCK_CATEGORIES_FETCHING, true)

            return Promise.allSettled([
                api.getStockCategories(),
                api.getStockCategoriesIdsWithImportedGoods()
            ])
                .then(results => {
                    let [categories, with_imported_goods] = results.map(i => i.value)

                    categories = categories.map(c => {
                        c.with_imported_goods = with_imported_goods.indexOf(c.id) !== -1

                        if (c.with_imported_goods) {
                            let target = c

                            while (target.parent) {
                                target = categories.filter(_ => _.id === target.parent)[0]
                                target.child_with_imported_goods = true
                            }
                        }

                        return c
                    })

                    commit(mutations.STOCK_CATEGORIES_SET, categories)
                })
                .finally(() => commit(mutations.STOCK_CATEGORIES_FETCHING, false))
        }
    },

    /**
     * Получение категории по id
     * @param commit
     * @param id
     * @returns {Q.Promise<any>}
     */
    actionFetchStockCategoryById: ({commit}, id) => {
        return api.getStockCategoryById(id).then(data => {
            commit(mutations.STOCK_CATEGORIES_UPDATE, {id, data})
            return data
        })
    },

    /**
     * Создание новой категории
     * @param commit
     * @param name
     * @param parent
     * @returns {Promise<T>}
     */
    actionAddStockCategory: ({commit}, {name, parent}) => {
        commit(mutations.STOCK_CATEGORIES_ADDING, true)
        return api
            .addStockCategory(name, parent)
            .then(dataItem => {
                commit(mutations.STOCK_CATEGORIES_ADD, dataItem)
                return dataItem
            })
            .finally(() => {
                commit(mutations.STOCK_CATEGORIES_ADDING, false)
            })
    },

    /**
     * Обновление элемента
     * @param commit
     * @param id
     * @param data
     * @returns {Promise<T>}
     */
    actionPatchStockCategory: ({commit}, {id, data}) => {
        commit(mutations.STOCK_CATEGORIES_UPDATING, true)
        return api
            .patchStockCategory(id, data)
            .then(data => {
                commit(mutations.STOCK_CATEGORIES_UPDATE, {id, data})
                return data
            })
            .finally(() => {
                commit(mutations.STOCK_CATEGORIES_UPDATING, false)
            })
    },

    /**
     * Объединение категории (id) с другой категорией (target_id)
     * @param commit
     * @param id
     * @param target_id
     * @returns {Promise<any>}
     */
    actionDuplicateStockCategory: ({commit}, {id, target_id}) => {
        commit(mutations.STOCK_CATEGORIES_UPDATING, true)
        return api
            .duplicateStockCategory(id, target_id)
            .then(data => data)
            .finally(() => {
                commit(mutations.STOCK_CATEGORIES_UPDATING, false)
            })
    },

    /**
     * Получение списка выбранных элементов
     * @param commit
     * @param distributorId
     * @returns {Q.Promise<any>}
     */
    actionFetchSelectedStockCategories: ({commit}, distributorId) => {
        commit(mutations.STOCK_CATEGORIES_SELECTED_FETCHING, true)
        return api
            .getSelectedStockCategories(distributorId)
            .then(dataList => {
                commit(mutations.STOCK_CATEGORIES_SELECTED_SET, {default: dataList})
                return dataList
            })
            .finally(() => {
                commit(mutations.STOCK_CATEGORIES_SELECTED_FETCHING, false)
            })
    },

    /**
     * Обновление списка выбранных элементов
     * @param commit
     * @param distributorId
     * @param toggleOn
     * @param toggleOff
     * @returns {Q.Promise<any>}
     */
    actionUpdateSelectedStockCategories: (
        {commit},
        {distributorId, toggleOn, toggleOff}
    ) => {
        commit(mutations.STOCK_CATEGORIES_SELECTED_UPDATING, true)
        return api
            .updateSelectedStockCategories(distributorId, toggleOn, toggleOff)
            .finally(() => {
                commit(mutations.STOCK_CATEGORIES_SELECTED_UPDATING, false)
            })
    },

    /**
     * Установка выбранных элементов
     * @param commit
     * @param data
     */
    actionSetStockCategoriesSelected: ({commit}, data) => {
        commit(mutations.STOCK_CATEGORIES_SELECTED_SET, data)
    }
}
