import * as types from './types'

export default {
  /**
   * Задаем список товаров поставщика
   * @param state
   * @param dataList
   */
  [types.DISTRIBUTOR_PRODUCTS_SET]: (state, { items, count }) => {
    state.distributor_products = items
    state.distributor_products_count = count
  },

  /**
   * Задаем количество товара
   * @param state
   * @param countBinded
   * @param countUnbinded
   */
  [types.DISTRIBUTOR_PRODUCTS_STATS_SET]: (
    state,
    { total, binded, unbinded, disabled }
  ) => {
    state.distributor_products_stats_binded = binded
    state.distributor_products_stats_unbinded = unbinded
    state.distributor_products_stats_disabled = disabled
    state.distributor_products_stats_total = total
  },

  /**
   * Флаг получения товаров поставщика
   * @param state
   * @param value
   */
  [types.DISTRIBUTOR_PRODUCTS_FETCHING]: (state, value) => {
    state.distributor_products_fetching = value
  },

  /**
   * Обновляем товар
   * @param state
   * @param id
   * @param data
   */
  [types.DISTRIBUTOR_PRODUCTS_UPDATE]: (state, { id, data }) => {
    state.distributor_products = state.distributor_products.map(item =>
      item.id === id ? { ...item, ...data } : item
    )
  },

  /**
   * Флаг обновления товаров поставщика
   * @param state
   * @param fetching
   */
  [types.DISTRIBUTOR_PRODUCTS_UPDATING]: (state, value) => {
    state.distributor_products_updating = value
  }
}
