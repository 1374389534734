import api from '@/services/stockFeaturesApi'
import * as mutations from './mutations/types'

export default {
    /**
     * Очистить данные по параметрам
     * @param commit
     */
    actionClearStockFeatures: ({commit}) => {
        commit(mutations.STOCK_FEATURES_SET, [])
    },

    /**
     * Запрос всех параметров
     * @param commit
     */
    actionFetchStockFeatures: ({commit}) => {
        commit(mutations.STOCK_FEATURES_FETCHING, true)
        return api
            .getStockFeatures()
            .then(dataList => {
                commit(mutations.STOCK_FEATURES_SET, dataList)
            })
            .finally(() => {
                commit(mutations.STOCK_FEATURES_FETCHING, false)
            })
    },

    /**
     * Создание имени параметра
     * @param commit
     * @param dispatch
     * @param name
     * @param parent
     * @param enabled
     * @returns {*}
     */
    actionCreateStockFeature({commit, dispatch}, {name, parent = null, enabled = true}) {
        commit(mutations.STOCK_FEATURES_FETCHING)

        return api
            .createStockFeature({name, parent, enabled})
            .then(item => {
                commit(mutations.STOCK_FEATURES_ADD, item)
                commit(mutations.STOCK_FEATURES_FETCHING, false)
                return item
            })
    },

    /**
     * Создаёт значение для свойства
     * @param commit
     * @param dispatch
     * @param feature
     * @param value
     * @returns {{id: number, _1c_id: string, value: *, feature: number}}
     */
    actionCreateStockFeatureValue({commit, dispatch}, {feature, value}) {
        return api.createStockFeatureValue({feature, value})
    },
}
